import Base from '../../../Core/Base.js';
import DateHelper from '../../../Core/helper/DateHelper.js';
/**
 * @module Scheduler/view/mixin/Describable
 */
const
    arrayify = format => (!format || Array.isArray(format)) ? format : [format],
    pickFormat = (formats, index, defaultFormat) => (formats && formats[index] !== true) ? formats[index] : defaultFormat;
/**
 * Mixin that provides a consistent method for describing the ranges of time presented by a view. This is currently
 * consumed only by the Calendar widget for describing its child views. This mixin is defined here to facilitate using
 * a Scheduler as a child view of a Calendar.
 *
 * @mixin
 */
export default Target => class Describable extends (Target || Base) {
    static $name = 'Describable';
    static configurable = {
        /**
         * A {@link Core.helper.DateHelper} format string to use to create date output for view descriptions.
         * @prp {String}
         * @default
         */
        dateFormat : 'MMMM d, YYYY',
        /**
         * A string used to separate start and end dates in the {@link #config-descriptionFormat}.
         * @prp {String}
         * @default
         */
        dateSeparator : ' - ',
        /**
         * The date format used by the default {@link #config-descriptionRenderer} for rendering the view's description.
         * If this value is `null`, the {@link #config-dateFormat} (and potentially {@link #config-dateSeparator}) will
         * be used.
         *
         * For views that can span a range of dates, this can be a 2-item array with the following interpretation:
         *
         * - `descriptionFormat[0]` is either a date format string or `true` (to use {@link #config-dateFormat}). The
         *   result of formatting the `startDate` with this format specification is used when the formatting both the
         *   `startDate` and `endDate` with this specification produces the same result. For example, a week view
         *   displays only the month and year components of the date, so this will be used unless the end of the week
         *   crosses into the next month.
         *
         * - `descriptionFormat[1]` is used with {@link Core.helper.DateHelper#function-formatRange-static} when the
         *  `startDate` and `endDate` format differently using `descriptionFormat[0]` (as described above). This one
         *  format string produces a result for both dates. If this value is `true`, the {@link #config-dateFormat} and
         *  {@link #config-dateSeparator} are combined to produce the range format.
         *
         * @prp {String|String[]|Boolean[]}
         * @default
         */
        descriptionFormat : null,
        /**
         * A function that provides the textual description for this view. If provided, this function overrides the
         * {@link #config-descriptionFormat}.
         *
         * ```javascript
         *  descriptionRenderer() {
         *      const
         *          eventsInView = this.eventStore.records.filter(
         *              eventRec => DateHelper.intersectSpans(
         *                  this.startDate, this.endDate,
         *                  eventRec.startDate, eventRec.endDate)).length,
         *          sd = DateHelper.format(this.startDate, 'DD/MM/YYY'),
         *          ed = DateHelper.format(this.endDate, 'DD/MM/YYY');
         *
         *     return `${sd} - ${ed}, ${eventsInView} event${eventsInView === 1 ? '' : 's'}`;
         * }
         * ```
         * @config {Function} descriptionRenderer
         * @param {Core.widget.Widget} view The active view in case the function is in another scope.
         * @returns {String} Description string
         */
        descriptionRenderer : null
    };
    /**
     * Returns the date or ranges of included dates as an array. If there is only one significant date, the array will
     * have only one element. Otherwise, a range of dates is returned as a two-element array with `[0]` being the
     * `startDate` and `[1]` the `lastDate`.
     * @member {Date[]}
     * @internal
     */
    get dateBounds() {
        return [this.date];
    }
    /**
     * The textual description generated by the {@link #config-descriptionRenderer} if present, or by the
     * view's date (or date *range* if it has a range) and the {@link #config-descriptionFormat}.
     * @property {String}
     * @readonly
     */
    get description() {
        const
            me = this,
            { descriptionRenderer } = me;
        return descriptionRenderer ? me.callback(descriptionRenderer, me, [me]) : me.formattedDescription;
    }
    get formattedDescription() {
        const
            me = this,
            { dateBounds, dateFormat } = me,
            descriptionFormat = me.descriptionFormat ?? arrayify(me.defaultDescriptionFormat),
            format0 = pickFormat(descriptionFormat, 0, dateFormat),
            end = dateBounds.length > 1 && descriptionFormat?.length > 1 && (
                DateHelper.format(dateBounds[0], format0) !== DateHelper.format(dateBounds[1], format0)
            );
        // Format the startDate and endDate using the first format
        let ret = DateHelper.format(dateBounds[0], format0);
        if (end) {
            // The endDate renders a different description, and we have a range format.
            ret = DateHelper.formatRange(dateBounds,
                pickFormat(descriptionFormat, 1, `S${dateFormat}${me.dateSeparator}E${dateFormat}`));
        }
        return ret;
    }
    changeDescriptionFormat(format) {
        return arrayify(format);
    }
    get widgetClass() {}  // no b-describable class
};
