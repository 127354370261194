import DateHelper from '../../../Core/helper/DateHelper.js';
import DomHelper from '../../../Core/helper/DomHelper.js';
import EventHelper from '../../../Core/helper/EventHelper.js';
import DatePicker from '../../../Core/widget/DatePicker.js';
import '../../../Core/widget/Menu.js';
import Widget from '../../../Core/widget/Widget.js';
/**
 * @module SchedulerPro/widget/calendareditor/CalendarEditorDatePicker
 */
/**
 * A special date picker visualizing a calendar effect on displayed dates.
 * The widget is used by {@link SchedulerPro/widget/CalendarEditor the calendar editor}.
 *
 * It basically highlights dates affected by some intervals
 * by adding color badges representing the intervals.
 *
 * @extends Core/widget/DatePicker
 * @classtype calendareditordatepicker
 * @internal
 * @widget
 */
export default class CalendarEditorDatePicker extends DatePicker {
    static $name = 'CalendarEditorDatePicker';
    static type = 'calendareditordatepicker';
    static get delayable() {
        return {
            refresh : {
                type  : 'buffer',
                delay : 200
            }
        };
    }
    // region Configs
    static configurable = {
        menu : {
            type     : 'menu',
            defaults : {
                localeClass : this
            },
            items : {
                addException : {
                    text   : 'L{addException}',
                    onItem : 'up.triggerAddExceptionItem'
                },
                addWeek : {
                    text   : 'L{addWeek}',
                    onItem : 'up.triggerAddWeekItem'
                }
            }
        },
        store           : null,
        multiSelect     : 'range',
        nonWorkingDays  : [],
        cellRenderer    : 'datePickerCellRenderer',
        menuHideTimeout : 3000
    };
    updateStore(store) {
        this.detachListeners('store');
        store?.ion({
            name                : 'store',
            change              : 'onCalendarStoreChange',
            calendarChange      : 'onCalendarStoreChange',
            pushToCalendar      : 'onCalendarStoreChange',
            calendarBumpVersion : 'onCalendarStoreChange',
            thisObj             : this
        });
    }
    changeMenu(menu) {
        if (!menu?.isMenu) {
            menu = Widget.create(Widget.mergeConfigs({
                autoShow : false,
                owner    : this
            }, menu), 'menu');
        }
        return menu;
    }
    updateMenu(menu) {
        menu?.ion({
            show    : 'onMenuShow',
            destroy : 'onMenuDestroy',
            thisObj : this,
            once    : true
        });
    }
    // endregion Configs
    // region Construct/destruct
    afterConstruct() {
        super.afterConstruct(...arguments);
        this.ion({
            selectionChange : this.internalOnSelectionChange
        });
    }
    doDestroy() {
        super.doDestroy();
        this._menu?.destroy();
        this.elementDetacher?.();
        this.menuElementDetacher?.();
    }
    // endregion Construct/destruct
    // region Listeners
    onInternalPaint(...args) {
        super.onInternalPaint(...args);
        const me = this;
        me.elementDetacher?.();
        me.elementDetacher = EventHelper.on({
            element     : me.element,
            contextmenu : me.internalOnElementContextMenu,
            thisObj     : me
        });
    }
    onCalendarStoreChange() {
        this.refresh();
    }
    onMenuShow({ source }) {
        const me = this;
        me.menuElementDetacher = EventHelper.on({
            element    : source.element,
            mouseenter : me.onMenuElementMouseEnter,
            mouseleave : me.onMenuElementMouseLeave,
            thisObj    : me
        });
    }
    onMenuDestroy() {
        this.menuElementDetacher?.();
    }
    onMenuElementMouseEnter(event) {
        this.menu.clearTimeout('hide');
    }
    onMenuElementMouseLeave(event) {
        const
            { relatedTarget } = event,
            leavingToChild    = relatedTarget && this.menu.owns(relatedTarget);
        if (!leavingToChild) {
            this.menu.setTimeout('hide', 3000);
        }
    }
    internalOnSelectionChange({ selection }) {
        // trigger menu once some date(s) is selected
        if (selection[0]) {
            this.showMenu();
        }
    }
    internalOnElementContextMenu(event) {
        event.preventDefault();
        this.showMenu();
    }
    // endregion Listeners
    // region Methods
    getSelectionTimeSpan() {
        const
            { selection } = this,
            startDate     = selection[0] ?? this.activeDate,
            endDate       = selection[0] ? DateHelper.add(selection[selection.length - 1], 1, 'day')
                : DateHelper.getStartOfNextDay(startDate, true);
        return { startDate, endDate };
    }
    triggerAddExceptionItem() {
        this.trigger('addExceptionItem');
    }
    triggerAddWeekItem() {
        this.trigger('addWeekItem');
    }
    async showMenu() {
        const { menu } = this;
        menu.clearTimeout('hide');
        menu.activeDate = this.activeDate;
        await menu.showBy({
            target : this.getCell(this.activeDate)
        });
        menu.setTimeout('hide', this.menuHideTimeout);
        return menu;
    }
    getDateIntervals(date) {
        const
            { store }   = this,
            intervals   = store?.calendar?.getDayIntervals(date),
            intervalSet = new Set(
                intervals?.flatMap(([_startDate, _endDate, rawInterval]) => store.getRecordByRawInterval(
                    rawInterval.mainInterval || rawInterval
                ) || [])
            );
        return Array.from(intervalSet);
    }
    datePickerCellRenderer({ cell, date }) {
        if (this.isConfiguring) {
            return;
        }
        const
            { store }    = this,
            dayIntervals = this.getDateIntervals(date),
            isNonWorking = store?.calendar?.isDayHoliday(date);
        cell.classList.toggle('b-working-day', !isNonWorking);
        cell.classList.toggle('b-nonworking-day', isNonWorking);
        DomHelper.append(cell, {
            class    : 'b-interval-badges',
            children : dayIntervals.map(i => ({
                class : `b-interval-badge ${i.cls}`,
                style : {
                    '--interval-badge-color' : i.color
                }
            }))
        });
    }
    // endregion Methods
}
CalendarEditorDatePicker.initClass();
CalendarEditorDatePicker._$name = 'CalendarEditorDatePicker';