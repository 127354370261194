var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { MixinAny } from "../../../../ChronoGraph/class/BetterMixin.js";
import { calculate } from "../../../../ChronoGraph/replica/Entity.js";
import { Direction, ProjectConstraintResolution } from "../../../scheduling/Types.js";
import { ConstrainedByParentScheduleMixin } from "./ConstrainedByParentScheduleMixin.js";
import { ConstrainedLateScheduleMixin } from "./ConstrainedLateScheduleMixin.js";
import { InactiveEventScheduleMixin } from "./InactiveEventScheduleMixin.js";
/**
 * This is a gantt-specific event schedule mixin.
 */
export class GanttEventScheduleMixin extends MixinAny([
    ConstrainedByParentScheduleMixin,
    ConstrainedLateScheduleMixin,
    InactiveEventScheduleMixin
], (base) => {
    class GanttEventScheduleMixin extends base {
        // need to have at least one "entity" decorator in the class, otherwise the entity infrastructure won't be setup
        *calculateStartDateConstraintIntervals() {
            const result = yield* super.calculateStartDateConstraintIntervals();
            if (this.direction === Direction.Forward) {
                const projectConstraintResolution = yield this.event.$.projectConstraintResolution;
                const direction = yield this.event.$.effectiveDirection;
                // If the event is configured to ignore the project border constraint
                if (direction.direction === Direction.Forward && projectConstraintResolution === ProjectConstraintResolution.Ignore) {
                    // skip the project constraint for it so we could calculate the event start/end dates w/o that constraint effect
                    return result.filter(interval => !interval.isProjectConstraintInterval);
                }
            }
            return result;
        }
        *calculateEndDateConstraintIntervals() {
            const result = yield* super.calculateEndDateConstraintIntervals();
            if (this.direction === Direction.Backward) {
                const projectConstraintResolution = yield this.event.$.projectConstraintResolution;
                const direction = yield this.event.$.effectiveDirection;
                // If the event is configured to ignore the project border constraint
                if (direction.direction === Direction.Backward && projectConstraintResolution === ProjectConstraintResolution.Ignore) {
                    // skip the project constraint for it so we could calculate the event start/end dates w/o that constraint effect
                    return result.filter(interval => !interval.isProjectConstraintInterval);
                }
            }
            return result;
        }
    }
    __decorate([
        calculate('startDateConstraintIntervals')
    ], GanttEventScheduleMixin.prototype, "calculateStartDateConstraintIntervals", null);
    return GanttEventScheduleMixin;
}) {
}
