import GridFeatureManager from '../../Grid/feature/GridFeatureManager.js';
import SchedulerDependencies from '../../Scheduler/feature/Dependencies.js';
import TransactionalFeature from '../../Scheduler/feature/mixin/TransactionalFeature.js';
/**
 * @module SchedulerPro/feature/Dependencies
 */
/**
 * This feature implements support for project transactions and is used by default in Scheduler Pro. For general
 * dependencies documentation see {@link Scheduler.feature.Dependencies}.
 *
 * This feature is **enabled** by default.
 *
 * @extends Scheduler/feature/Dependencies
 * @classtype dependencies
 * @feature
 *
 * @typings Scheduler.feature.Dependencies -> Scheduler.feature.SchedulerDependencies
 */
export default class Dependencies extends SchedulerDependencies.mixin(TransactionalFeature) {
    static $name = 'Dependencies';
    onRequestDragCreate(event) {
        const result = super.onRequestDragCreate(event);
        if (result !== false) {
            this.startFeatureTransaction().then();
        }
        return result;
    }
    async doAfterDependencyDrop(data) {
        // dependency property is present only if dependency creation went fine
        const { dependency } = data;
        if (dependency) {
            await this.finishFeatureTransaction();
        }
        else {
            this.rejectFeatureTransaction();
        }
        await super.doAfterDependencyDrop(data);
    }
    abort() {
        this.rejectFeatureTransaction();
        super.abort();
    }
}
Dependencies._$name = 'Dependencies'; GridFeatureManager.registerFeature(Dependencies, true, 'SchedulerPro');
