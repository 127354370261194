import Base from '../../../Core/Base.js';
import ObjectHelper from '../../../Core/helper/ObjectHelper.js';
/**
 * @module Scheduler/view/mixin/SchedulerState
 */
const copyProperties = [
    'startDate',
    'endDate',
    'eventLayout',
    'barMargin',
    'mode',
    'tickSize',
    'eventColor',
    'eventStyle',
    'fillTicks'
];
/**
 * A Mixin for Scheduler that handles state. It serializes the following scheduler properties, in addition to what
 * is already stored by its superclass {@link Grid/view/mixin/GridState}:
 *
 * * startDate
 * * endDate
 * * eventLayout
 * * barMargin
 * * mode
 * * tickSize
 * * zoomLevel
 * * eventColor
 * * eventStyle
 * * fillTicks
 *
 * See {@link Grid.view.mixin.GridState} and {@link Core.mixin.State} for more information on state.
 *
 * @mixin
 */
export default Target => class SchedulerState extends (Target || Base) {
    static $name = 'SchedulerState';
    static configurable = {
        statefulEvents : ['dateRangeChange']
    };
    /**
     * Gets or sets scheduler's state. Check out {@link Scheduler.view.mixin.SchedulerState} mixin
     * and {@link Grid.view.mixin.GridState} for more details.
     * @member {Object} state
     * @property {Date} state.startDate
     * @property {Date} state.endDate
     * @property {String} state.eventLayout
     * @property {String} state.eventStyle
     * @property {String} state.eventColor
     * @property {Number} state.barMargin
     * @property {Number} state.tickSize
     * @property {Boolean} state.fillTicks
     * @property {Number} state.zoomLevel
     * @property {'horizontal'|'vertical'} state.mode
     * @property {Object[]} state.columns
     * @property {Boolean} state.readOnly
     * @property {Number} state.rowHeight
     * @property {Object} state.scroll
     * @property {Number} state.scroll.scrollLeft
     * @property {Number} state.scroll.scrollTop
     * @property {Array} state.selectedRecords
     * @property {String} state.selectedCell
     * @property {String[]} state.treeGroupLevels
     * @property {String} state.style
     * @property {Object} state.subGrids
     * @property {Object} state.store
     * @property {Sorter[]} state.store.sorters
     * @property {Grouper[]} state.store.groupers
     * @property {CollectionFilterConfig[]} state.store.filters
     * @property {String[]} state.store.collapsedGroups
     * @category State
     */
    /**
     * Get scheduler's current state for serialization. State includes rowHeight, headerHeight, readOnly, selectedCell,
     * selectedRecordId, column states and store state etc.
     * @returns {Object} State object to be serialized
     * @private
     */
    getState() {
        return ObjectHelper.copyProperties(super.getState(), this, copyProperties);
    }
    /**
     * Apply previously stored state.
     * @param {Object} state
     * @private
     */
    applyState(state) {
        state = Object.assign({}, state);
        this.suspendRefresh();
        if (state?.eventLayout === 'layoutFn') {
            delete state.eventLayout;
        }
        // Zoom level will set tick size, no need to update model additionally
        if (state?.zoomLevelOptions?.width) {
            delete state.tickSize;
        }
        super.applyState(state);
        this.resumeRefresh(true, false);
    }
    // This does not need a className on Widgets.
    // Each *Class* which doesn't need 'b-' + constructor.name.toLowerCase() automatically adding
    // to the Widget it's mixed in to should implement this.
    get widgetClass() {}
};
