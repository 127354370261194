import FieldFilterPicker, { isFilterableField, isSupportedDurationField } from '../../Core/widget/FieldFilterPicker.js';
import ObjectHelper from '../../Core/helper/ObjectHelper.js';
import '../../Core/widget/Combo.js';
import '../../Core/widget/Checkbox.js';
import '../../Core/widget/NumberField.js';
import '../../Core/widget/TextField.js';
import '../../Core/widget/DateField.js';
/**
 * @module Grid/widget/GridFieldFilterPicker
 */
/**
 * Subclass of {@link Core.widget.FieldFilterPicker} allowing configuration using an
 * existing {@link Grid.view.Grid}.
 *
 * See also {@link Grid.widget.GridFieldFilterPickerGroup}.
 *
 * @extends Core/widget/FieldFilterPicker
 * @classtype gridfieldfilterpicker
 * @demo Grid/fieldfilters
 * @widget
 */
export default class GridFieldFilterPicker extends FieldFilterPicker {
    //region Config
    static get $name() {
        return 'GridFieldFilterPicker';
    }
    // Factoryable type name
    static get type() {
        return 'gridfieldfilterpicker';
    }
    /**
     * @hideconfigs store
     */
    static configurable = {
        /**
         * {@link Grid.view.Grid} from which to read the available field list. In order to
         * appear as a selectable property for a filter, a column must have a `field` property.
         * If the column has a `text` property, that will be shown as the displayed text in the
         * selector; otherwise, the `field` property will be shown as-is.
         *
         * The grid's {@link Core.data.Store}'s {@link Core.data.Store#property-modelClass} will be
         * examined to find field data types.
         *
         * You can limit available fields to a subset of the grid's columns using the
         * {@link #config-allowedFieldNames} configuration property.
         *
         * @config {Grid.view.Grid}
         */
        grid : null,
        /**
         * Optional array of field names that are allowed as selectable properties for filters.
         * This is a subset of the field names found in the {@link #config-grid}'s columns. When supplied, only
         * the named fields will be shown in the property selector combo.
         *
         * Note that field names are case-sensitive and should match the data field name in the store
         * model.
         *
         * @config {String[]}
         */
        allowedFieldNames : null
    };
    //endregion
    afterConstruct() {
        const
            me = this;
        if (!me.grid) {
            throw new Error(`${me.constructor.$name} requires 'grid' to be configured.`);
        }
        me.fields = me.fields ?? {};  // Force `fields` changer if fields is left null, to merge w/ grid fields
        super.afterConstruct();
    }
    updateGrid(newGrid) {
        if (!newGrid.store?.modelClass) {
            throw new Error(`Grid does not have a store with a modelClass defined.`);
        }
        if (!newGrid.columns) {
            throw new Error(`Grid does not have a column store.`);
        }
    }
    /**
     * Gets the filterable fields backing any of the configured `grid`'s columns, for those columns for which
     * it is possible to do so.
     * @private
     * @returns {Object} Filterable fields dictionary of the form { [fieldName]: { title, type } }
     */
    static getColumnFields(columnStore, modelClass, allowedFieldNames) {
        return Object.fromEntries(
            columnStore?.bottomColumns.reduce(
                (outFields, { field: fieldName, text, filterType, filterable }) => {
                    // Transform each column's `field` and `text` to [fieldName, { title, type }] by looking up the
                    // field type, excluding any fields not found in the model. Prefer column.filterType if available.
                    // Columns with `filterable` but no `filterType` get assigned `auto`.
                    if (!allowedFieldNames || allowedFieldNames.includes(fieldName)) {
                        const
                            field     = fieldName && modelClass.getFieldDefinition(fieldName),
                            fieldType = isFilterableField(field) ? field.type : undefined;
                        if (fieldType || filterType || filterable) {
                            outFields.push([
                                fieldName,
                                {
                                    title : text || fieldName,
                                    type  : filterType ??
                                        (isSupportedDurationField(field) ? 'duration' : fieldType) ??
                                        'auto'
                                }
                            ]);
                        }
                    }
                    return outFields;
                }, []) ?? []);
    }
    changeFields(newFields) {
        const mergedFields = ObjectHelper.merge(
            {},
            GridFieldFilterPicker.getColumnFields(this.grid.columns, this.grid.store?.modelClass),
            newFields
        );
        return this.allowedFieldNames
            ? Object.fromEntries(this.allowedFieldNames.map(fieldName => [fieldName, mergedFields[fieldName]]))
            : mergedFields;
    }
}
GridFieldFilterPicker.initClass();
GridFieldFilterPicker._$name = 'GridFieldFilterPicker';