import { CalendarCache } from "./CalendarCache.js";
import { CalendarCacheInterval } from "./CalendarCacheInterval.js";
import { IntervalCache } from "./IntervalCache.js";
import DateHelper from "../../Core/helper/DateHelper.js";
import TimeZoneHelper from "../../Core/helper/TimeZoneHelper.js";
import { CalendarIntervalType } from "../scheduling/Types.js";
import { MAX_DATE, MIN_DATE } from "../util/Constants.js";
import later from '../vendor/later/later.js';
const everySingleDaySchedule = later.schedule(later.parse.text('every 1 day'));
export class CalendarCacheSingle extends CalendarCache {
    constructor(config) {
        super(config);
        this.staticIntervalsCached = false;
        if (!this.unspecifiedTimeInterval)
            throw new Error("Required attribute `unspecifiedTimeInterval` is missing");
        this.intervalUnspecifiedTimeIntervals = new Map();
        this.weekUnspecifiedTimeIntervalsByKey = new Map();
        this.intervalCache = new IntervalCache({
            emptyInterval: new CalendarCacheInterval({
                intervals: [this.unspecifiedTimeInterval],
                calendar: this.calendar
            }),
            combineIntervalsFn: (interval1, interval2) => {
                return interval1.combineWith(interval2);
            }
        });
    }
    fillCache(startDate, endDate) {
        if (!this.staticIntervalsCached) {
            this.cacheStaticIntervals();
            this.staticIntervalsCached = true;
        }
        if (this.parentCache)
            this.includeWrappingRangeFrom(this.parentCache, startDate, endDate);
        const timeZone = this.calendar.ignoreTimeZone ? null : this.calendar.project?.timeZone;
        if (startDate > endDate)
            throw new Error("Invalid cache fill interval");
        for (const interval of this.intervalStore) {
            if (interval.isRecurrent()) {
                this.cacheRecurrentInterval(startDate, endDate, timeZone, interval);
            }
        }
    }
    cacheRecurrentInterval(startDate, endDate, timeZone, interval) {
        const intervalStartDate = interval.startDate;
        const intervalEndDate = interval.endDate;
        // skip overrides not intersecting the requested time frame
        if (intervalStartDate && intervalStartDate >= endDate || intervalEndDate && intervalEndDate <= startDate)
            return;
        const availabilityStartSchedule = interval.getAvailabilityStartSchedule();
        const availabilityEndSchedule = interval.getAvailabilityEndSchedule();
        const startSchedule = interval.getStartDateSchedule() || (availabilityStartSchedule && everySingleDaySchedule);
        const endSchedule = interval.getEndDateSchedule();
        // indicates the recurring end should be calculated as the recurring start day end
        const useEndOfDay = !endSchedule || endSchedule === 'EOD';
        let wrappingStartDate = startDate;
        let wrappingEndDate = endDate;
        // collect the last start date before the frame starts
        let starts = startSchedule.prev(2, startDate);
        let ends;
        if (starts) {
            wrappingStartDate = starts[0];
            // if 0th entry matches startDate use the next earlier date
            if (wrappingStartDate.getTime() === startDate.getTime() && starts[1]) {
                wrappingStartDate = starts[1];
            }
        }
        // collect the first end date after the frame ends
        if (useEndOfDay) {
            const tmpDate = startSchedule.next(1, endDate);
            if (tmpDate) {
                wrappingEndDate = DateHelper.getStartOfNextDay(tmpDate, true);
            }
        }
        else {
            ends = endSchedule.next(2, endDate);
            if (ends) {
                wrappingEndDate = ends[0];
                // if 0th entry matches endDate use the next later date
                if (wrappingEndDate.getTime() === endDate.getTime() && ends[1]) {
                    wrappingEndDate = ends[1];
                }
            }
        }
        let startDates = startSchedule.next(Infinity, wrappingStartDate, new Date(wrappingEndDate.getTime() - 1000));
        // schedule is empty for the interval of interest, do nothing
        if (!startDates?.length)
            return;
        // at this point `startDates` is a non-empty array
        let endDates = useEndOfDay
            ? startDates.map(date => DateHelper.getStartOfNextDay(date, true))
            : endSchedule.next(Infinity, wrappingStartDate, wrappingEndDate);
        if (!endDates?.length)
            return;
        // If end date matches start date means the same recurrence rule is used for both (like "at 00:00")
        // Get rid of the first matching end date to shift ranges
        if (startDates[0].getTime() === endDates[0].getTime()) {
            endDates.splice(0, 1);
        }
        // If the interval is limited with a start date
        while (intervalStartDate && startDates[0] < intervalStartDate) {
            if (endDates[0] > intervalStartDate) {
                startDates[0] = intervalStartDate;
            }
            else {
                startDates.shift();
                endDates.shift();
            }
        }
        // If the interval is limited with an end date
        while (intervalEndDate && endDates[endDates.length - 1] > intervalEndDate) {
            if (startDates[endDates.length - 1] < intervalEndDate) {
                endDates[endDates.length - 1] = intervalEndDate;
            }
            else {
                startDates.pop();
                endDates.pop();
            }
        }
        if (endDates.length > startDates.length) {
            // safe to ignore "extra" end dates
            endDates.length = startDates.length;
        }
        else if (endDates.length < startDates.length) {
            // monkey patch
            startDates.length = endDates.length;
            // throw new Error("Recurrent interval inconsistency: " + interval + ", caching startDate: " + startDate + ", caching endDate: " + endDate)
        }
        // if the interval has availability provided
        if (availabilityStartSchedule) {
            const tmpStartDates = [], tmpEndDates = [], baseInterval = this.getUnspecifiedTimeIntervalForException(interval, { isWorking: false });
            for (let index = 0, length = startDates.length; index < length; index++) {
                const startDate = startDates[index], endDate = endDates[index];
                // add a base interval masking other intervals
                this.intervalCache.addInterval(startDate, endDate, existingCacheInterval => existingCacheInterval.includeInterval(baseInterval));
                // get availability starts in the current startDate - endDate range
                let starts = availabilityStartSchedule.next(Infinity, startDate, endDate);
                if (!starts)
                    return;
                // get availability ends in the current startDate - endDate range
                let ends = availabilityEndSchedule.next(Infinity, new Date(startDate.getTime() + 1000), endDate);
                if (!ends)
                    return;
                if (ends.length > starts.length) {
                    ends.length = starts.length;
                }
                else if (ends.length < starts.length) {
                    starts.length = ends.length;
                }
                // If the range was defined in the inverse way
                // like start="17:00", end="08:00"
                // Then add a leading interval:
                // start="00:00", end="08:00"
                // and a trailing one:
                // start="17:00", end="00:00"
                if (ends[0] < starts[0]) {
                    starts.unshift(startDate);
                    ends.push(endDate);
                }
                tmpStartDates.push(...starts);
                tmpEndDates.push(...ends);
            }
            startDates = tmpStartDates;
            endDates = tmpEndDates;
        }
        for (let index = 0, length = startDates.length; index < length; index++) {
            let recStartDate = startDates[index];
            let recEndDate = endDates[index];
            // Adjust calendar intervals when changing time zone
            if (timeZone != null) {
                recStartDate = TimeZoneHelper.toTimeZone(recStartDate, timeZone);
                recEndDate = TimeZoneHelper.toTimeZone(recEndDate, timeZone);
            }
            this.intervalCache.addInterval(recStartDate, recEndDate, existingCacheInterval => existingCacheInterval.includeInterval(interval));
        }
    }
    getUnspecifiedTimeIntervalForException(interval, data = {}) {
        let result = this.intervalUnspecifiedTimeIntervals.get(interval);
        if (!result) {
            result = this.unspecifiedTimeInterval.copy();
            this.intervalUnspecifiedTimeIntervals.set(interval, result);
            Object.assign(result, {
                mainInterval: interval,
                calendar: this.unspecifiedTimeInterval.calendar,
                name: interval.name,
                cls: interval.cls,
                startDate: interval.startDate,
                endDate: interval.endDate,
                prioritySortValue: interval.getPrioritySortValue() - 1,
                ...data
            });
        }
        return result;
    }
    getUnspecifiedTimeIntervalForWeek(week) {
        let result = this.intervalUnspecifiedTimeIntervals.get(week);
        if (!result) {
            // First try matching some of existing week screens
            const searchKey = week.compositeIntervalCode;
            result = this.weekUnspecifiedTimeIntervalsByKey.get(searchKey);
            // make a new one if no matching exiting
            if (!result) {
                result = this.unspecifiedTimeInterval.copy();
                this.intervalUnspecifiedTimeIntervals.set(week, result);
                this.weekUnspecifiedTimeIntervalsByKey.set(searchKey, result);
                Object.assign(result, {
                    mainInterval: week,
                    calendar: this.unspecifiedTimeInterval.calendar,
                    name: week.name,
                    cls: week.cls,
                    startDate: week.startDate,
                    endDate: week.endDate,
                    prioritySortValue: week.getPrioritySortValue() - 1
                });
            }
        }
        return result;
    }
    clear() {
        this.staticIntervalsCached = false;
        super.clear();
    }
    cacheStaticIntervals() {
        const timeZone = this.calendar.project?.timeZone;
        const intervalCache = this.intervalCache;
        for (let interval of this.intervalStore) {
            const isStatic = interval.isStatic();
            const isWeek = interval.type === CalendarIntervalType.Week;
            if (isStatic || isWeek) {
                // Each week has an UnspecifiedTimeIntervalModel instance to screen parent calendars
                if (isWeek) {
                    interval = this.getUnspecifiedTimeIntervalForWeek(interval);
                }
                let { startDate, endDate } = interval;
                // Adjust calendar intervals when changing time zone
                if (timeZone != null) {
                    startDate = TimeZoneHelper.toTimeZone(startDate, timeZone);
                    endDate = TimeZoneHelper.toTimeZone(endDate, timeZone);
                }
                intervalCache.addInterval(
                startDate || MIN_DATE, endDate || MAX_DATE, existingCacheInterval => existingCacheInterval.includeInterval(interval));
            }
        }
    }
}
