import { Mixin } from "../../../../ChronoGraph/class/Mixin.js";
import { ConstrainedLateScheduleMixin } from "./ConstrainedLateScheduleMixin.js";
export class InactiveEventScheduleMixin extends Mixin([ConstrainedLateScheduleMixin], (base) => {
    const superProto = base.prototype;
    class InactiveEventScheduleMixin extends base {
        *shouldRollupChildStartDate(childEvent) {
            // Do not take into account inactive children dates when calculating
            // their parent start end dates (unless the parent is also inactive)
            return !(yield childEvent.$.inactive) || (yield this.event.$.inactive);
        }
        *shouldRollupChildEndDate(childEvent) {
            // Do not take into account inactive children dates when calculating
            // their parent start end dates (unless the parent is also inactive)
            return !(yield childEvent.$.inactive) || (yield this.event.$.inactive);
        }
    }
    return InactiveEventScheduleMixin;
}) {
}
