import UpdateAction from './UpdateAction.js';
import InsertChildAction from './InsertChildAction.js';
import RemoveChildAction from './RemoveChildAction.js';
import AddAction from './AddAction.js';
import InsertAction from './InsertAction.js';
import RemoveAction from './RemoveAction.js';
import RemoveAllAction from './RemoveAllAction.js';
export const makeModelUpdateAction = (model, newData, oldData, isInitialUserAction) => {
    return new UpdateAction({
        model,
        newData,
        oldData,
        isInitialUserAction
    });
};
export const makeModelInsertChildAction = (parentModel, insertIndex, childModels, context, orderedBeforeNode, stores) => {
    return new InsertChildAction({
        parentModel,
        childModels,
        insertIndex,
        orderedBeforeNode,
        context,
        stores
    });
};
export const makeModelRemoveChildAction = (parentModel, childModels, context, stores) => {
    return new RemoveChildAction({
        parentModel,
        childModels,
        context,
        stores
    });
};
export const makeStoreModelAddAction = (store, modelList, silent) => {
    return new AddAction({
        store,
        modelList,
        silent
    });
};
export const makeStoreModelInsertAction = (store, insertIndex, modelList, context, silent) => {
    return new InsertAction({
        store,
        insertIndex,
        modelList,
        context,
        silent
    });
};
export const makeStoreModelRemoveAction = (store, modelList, context, silent) => {
    return new RemoveAction({
        store,
        modelList,
        context,
        silent
    });
};
export const makeStoreRemoveAllAction = (store, allRecords, silent) => {
    return new RemoveAllAction({
        store,
        allRecords,
        silent
    });
};
export const invertAction = action => {
    return action.invert();
};
