/**
 * @module Core/data/stm/StateTrackingManager
 */
import Base from '../../Base.js';
import IdHelper from '../../helper/IdHelper.js';
import ObjectHelper from '../../helper/ObjectHelper.js';
import Events from '../../mixin/Events.js';
import RevisionException from './RevisionException.js';
import StateBase from './state/StateBase.js';
import DisabledState from './state/DisabledState.js';
import ReadyState from './state/ReadyState.js';
import RecordingState from './state/RecordingState.js';
import RestoringState from './state/RestoringState.js';
import AutoReadyState from './state/AutoReadyState.js';
import AutoRecordingState from './state/AutoRecordingState.js';
import CheckoutState from './state/CheckoutState.js';
import RevisionRecordingState from './state/RevisionRecordingState.js';
import TemporaryRevisionRecordingState from './state/TemporaryRevisionRecordingState.js';
import Registry from './state/Registry.js';
import {
    ACTION_QUEUE_PROP,
    STATE_PROP,
    STORES_PROP,
    QUEUE_PROP,
    POS_PROP,
    TRANSACTION_PROP,
    TRANSACTION_TIMER_PROP,
    AUTO_RECORD_PROP,
    IS_APPLYING_STASH,
    REVISION_NAMES_PROP,
    REVISION_QUEUE_PROP,
    REVISION_INDEX_PROP
} from './Props.js';
import {
    makeModelInsertChildAction,
    makeModelRemoveChildAction,
    makeModelUpdateAction,
    makeStoreModelAddAction,
    makeStoreModelInsertAction,
    makeStoreModelRemoveAction,
    makeStoreRemoveAllAction
} from './action/AllActions.js';
import Transaction, { TRANSACTION_TYPES } from './Transaction.js';
const stateTransition = (stm, event, ...args) => {
    const
        oldState = stm.state,
        newState = event.call(stm[STATE_PROP], stm, ...args);
    if (typeof newState === 'string') {
        stm[STATE_PROP] = Registry.resolveStmState(newState);
    }
    else if (newState instanceof StateBase) {
        stm[STATE_PROP] = newState;
    }
    else if (Array.isArray(newState)) {
        const [state, next] = newState;
        if (typeof state === 'string') {
            stm[STATE_PROP] = Registry.resolveStmState(state);
        }
        else if (state instanceof StateBase) {
            stm[STATE_PROP] = state;
        }
        else if (state && typeof state === 'object') {
            stm = Object.assign(stm, state);
            stm[STATE_PROP] = Registry.resolveStmState(stm[STATE_PROP]);
        }
        if (typeof next === 'function') {
            stateTransition(stm, next, ...args);
        }
    }
    else if (newState && typeof newState === 'object') {
        stm = Object.assign(stm, newState);
        stm[STATE_PROP] = Registry.resolveStmState(stm[STATE_PROP]);
    }
    if (oldState !== ReadyState && oldState !== AutoReadyState && (newState !== ReadyState && newState !== AutoReadyState)) {
        stm.trigger('ready');
    }
};
/**
 * Tracks the state of every store registered via {@link #function-addStore}. It is {@link #config-disabled} by default
 * so remember to call {@link #function-enable} when your stores are registered and initial dataset is loaded.
 * Use {@link #function-undo} / {@link #function-redo} method calls to restore state to a particular
 * point in time
 *
 * ```javascript
 * stm = new StateTrackingManager({
 *     autoRecord : true,
 *     listeners  : {
 *        'recordingstop' : () => {
 *            // your custom code to update undo/redo GUI controls
 *            updateUndoRedoControls();
 *        },
 *        'restoringstop' : ({ stm }) => {
 *            // your custom code to update undo/redo GUI controls
 *            updateUndoRedoControls();
 *        },
 *        'disabled' : () => {
 *            // in Gantt, Scheduler and other scheduling products,
 *            // also need to update the undo/redo controls on `disabled`
 *            // event, due to implementation details
 *            updateUndoRedoControls();
 *        }
 *    },
 *    getTransactionTitle : (transaction) => {
 *        // your custom code to analyze the transaction and return custom transaction title
 *        const lastAction = transaction.queue[transaction.queue.length - 1];
 *
 *        if (lastAction instanceof AddAction) {
 *            let title = 'Add new record';
 *        }
 *
 *        return title;
 *    }
 * });
 *
 * stm.addStore(userStore);
 * stm.addStore(companyStore);
 * stm.addStore(otherStore);
 *
 * stm.enable();
 * ```
 *
 * **Note:** STM currently does not support undoing server side added and saved records.
 * Therefore it's recommended to {@link #function-resetQueue reset the queue}
 * each time a tracked store(s) loads from or saves its changes to the server.
 * If Crud Manager is used it can be done like this:
 *
 * ```javascript
 * crudManager.on({
 *     requestDone() {
 *         stm.resetQueue();
 *     }
 * });
 * ```
 *
 * and in case individual stores are used:
 *
 * ```javascript
 * ajaxStore.on({
 *     afterRequest({ exception }) {
 *         if (!exception) {
 *             stm.resetQueue();
 *         }
 *     }
 * });
 * ```
 *
 * @mixes Core/mixin/Events
 * @extends Core/Base
 */
export default class StateTrackingManager extends Events(Base) {
    static $name = 'StateTrackingManager';
    static get defaultConfig() {
        return {
            /**
             * Default manager disabled state
             *
             * @config {Boolean}
             * @default
             */
            disabled : true,
            /**
             * Whether to start transaction recording automatically in case the Manager is enabled.
             *
             * In the auto recording mode, the manager waits for the first change in any store being managed and starts a transaction, i.e.
             * records any changes in its monitored stores. The transaction lasts for {@link #config-autoRecordTransactionStopTimeout} and
             * afterwards creates one undo/redo step, including all changes in the stores during that period of time.
             *
             * In non auto recording mode you have to call {@link #function-startTransaction} / {@link #function-stopTransaction} to start and end
             * a transaction.
             *
             * @config {Boolean}
             * @default
             */
            autoRecord : false,
            /**
             * The transaction duration (in ms) for the auto recording mode {@link #config-autoRecord}
             *
             * @config {Number}
             * @default
             */
            autoRecordTransactionStopTimeout : 100,
            /**
             * By the end of a transaction, with this config set to `true`, all model update actions will be merged to
             * one action per model. Only the initial start value and the last change will be kept.
             *
             * If non auto recording mode, you can call {@link #function-mergeTransactionUpdateActions}.
             *
             * @config {Boolean}
             * @default
             */
            autoRecordMergeUpdateActions : true,
            mergeAddUpdateActions : false,
            /**
             * Store model update action factory
             *
             * @config {Function}
             * @default
             * @private
             */
            makeModelUpdateAction,
            /**
             * Store insert child model action factory.
             *
             * @config {Function}
             * @default
             * @private
             */
            makeModelInsertChildAction,
            /**
             * Store remove child model action factory.
             *
             * @config {Function}
             * @default
             * @private
             */
            makeModelRemoveChildAction,
            /**
             * Store add model action factory.
             *
             * @config {Function}
             * @default
             * @private
             */
            makeStoreModelAddAction,
            /**
             * Store insert model action factory.
             *
             * @config {Function}
             * @default
             * @private
             */
            makeStoreModelInsertAction,
            /**
             * Store remove model action factory.
             *
             * @config {Function}
             * @default
             * @private
             */
            makeStoreModelRemoveAction,
            /**
             * Store remove all models action factory.
             *
             * @config {Function}
             * @default
             * @private
             */
            makeStoreRemoveAllAction,
            /**
             * Function to create a transaction title if none is provided.
             * The function receives a transaction and should return a title.
             *
             * @config {Function}
             * @param {Core.data.stm.Transaction} transaction
             * @returns {String}
             * @default
             */
            getTransactionTitle : null,
            //#region Revision configs
            revisionsEnabled : false,
            asyncUndoRedo : false,
            revisionLocalPrefix : 'local-',
            /**
             * Specifies length of the transaction queue when cleanup will be triggered
             * @prp {Number}
             * @default
             * @category Revisions
             * @internal
             */
            revisionQueueMaxLength : 20,
            /**
             * Specifies minimum length of last transactions
             * @prp {Number}
             * @default
             * @category Revisions
             * @internal
             */
            revisionQueueCommittedMinLength : 1
            //#endregion
        };
    }
    construct(...args) {
        Object.assign(this, {
            [STATE_PROP]             : ReadyState,
            [STORES_PROP]            : [],
            [QUEUE_PROP]             : [],
            [POS_PROP]               : 0,
            [TRANSACTION_PROP]       : null,
            [TRANSACTION_TIMER_PROP] : null,
            [AUTO_RECORD_PROP]       : false,
            [IS_APPLYING_STASH]      : false,
            stashedTransactions      : {}
        });
        super.construct(...args);
    }
    /**
     * Gets current state of the manager
     *
     * @property {Core.data.stm.state.StateBase}
     */
    get state() {
        return this[STATE_PROP];
    }
    /**
     * Gets current undo/redo queue position
     *
     * @property {Number}
     */
    get position() {
        return this[POS_PROP];
    }
    /**
     * Gets current undo/redo queue length
     *
     * @property {Number}
     */
    get length() {
        return this[QUEUE_PROP].length;
    }
    /**
     * Gets all the stores registered in STM
     *
     * @property {Core.data.Store[]}
     */
    get stores() {
        return Array.from(this[STORES_PROP]);
    }
    /**
     * Checks if a store has been added to the manager
     *
     * @param  {Core.data.Store} store
     * @returns {Boolean}
     */
    hasStore(store) {
        return this[STORES_PROP].includes(store);
    }
    /**
     * Adds a store instance to the manager
     * ```javascript
     * const stm = new StateTrackingManager({ ... })
     * const store = new Store({ ... });
     * stm.addStore(store);
     * ```
     *
     * @param {Core.data.Store} store
     */
    addStore(store) {
        if (!this.hasStore(store)) {
            this[STORES_PROP].push(store);
            store.stm = this;
            store.forEach(model => model.stm = this);
            // The above forEach iterates all models in the store except the root model, then, for tree structure store,
            // it needs to assign stm prop manually to the root, in order to avoid bug like this one: https://github.com/bryntum/support/issues/7581
            if (store.isTree) {
                store.rootNode.stm = this;
            }
        }
    }
    /**
     * Removes a store from the manager
     *
     * @param {Core.data.Store} store
     */
    removeStore(store) {
        if (this.hasStore(store)) {
            this[STORES_PROP] = this[STORES_PROP].filter(s => s !== store);
            store.stm = null;
            store.forEach(model => model.stm = null);
        }
    }
    /**
     * Calls `fn` for each store registered in STM.
     *
     * @param {Function} fn (store, id) => ...
     */
    forEachStore(fn) {
        this[STORES_PROP].forEach(s => fn(s, s.id));
    }
    //#region Disabled state
    /**
     * Get/set manager disabled state
     *
     * @property {Boolean}
     */
    get disabled() {
        return this.state === DisabledState;
    }
    set disabled(val) {
        const me = this;
        if (me.disabled !== val) {
            if (val) {
                stateTransition(me, me.state.onDisable, me);
            }
            else {
                stateTransition(me, me.state.onEnable, me);
            }
            me.trigger('stmDisabled', { disabled : val });
            /**
             * Fired when the disabled state of the STM changes
             *
             * @event disabled
             * @param {Core.data.stm.StateTrackingManager} source
             * @param {Boolean} disabled The current disabled state of the STM
             */
            me.trigger('disabled', { disabled : val });
        }
    }
    get enabled() {
        return !this.disabled;
    }
    /**
     * Enables manager
     */
    enable() {
        this.disabled = false;
    }
    /**
     * Disables manager
     */
    disable() {
        this.disabled = true;
    }
    //#endregion
    /**
     * Checks manager ready state
     * @readonly
     * @property {Boolean}
     */
    get isReady() {
        return this.state === ReadyState || this.state === AutoReadyState;
    }
    waitForReadiness() {
        return this.await('ready', false);
    }
    /**
     * Checks manager recording state
     * @readonly
     * @property {Boolean}
     */
    get isRecording() {
        return this.state === RecordingState || this.state === AutoRecordingState;
    }
    /**
     * Checks if STM is restoring a stash
     * @readonly
     * @property {Boolean}
     * @internal
     */
    get isApplyingStash() {
        return this[IS_APPLYING_STASH];
    }
    get shouldRecordAction() {
        return this.enabled && (!this.isRestoringState || this.isApplyingStash) && !this.isCheckingOut;
    }
    /**
     * Gets/sets manager auto record option
     *
     * @property {Boolean}
     */
    get autoRecord() {
        return this[AUTO_RECORD_PROP];
    }
    set autoRecord(value) {
        const me = this;
        if (me.autoRecord != value) {
            if (value) {
                stateTransition(me, me.state.onAutoRecordOn, me);
            }
            else {
                stateTransition(me, me.state.onAutoRecordOff, me);
            }
        }
    }
    /**
     * Starts undo/redo recording transaction.
     *
     * @param {String} [title]
     */
    startTransaction(title = null) {
        stateTransition(this, this.state.onStartTransaction, title);
    }
    /**
     * Stops undo/redo recording transaction
     *
     * @param {String} [title]
     */
    stopTransaction(title = null) {
        const me = this;
        if (me.autoRecord && me.autoRecordMergeUpdateActions) {
            me.mergeTransactionUpdateActions();
        }
        if (me.mergeAddUpdateActions) {
            me.mergeTransactionAddUpdateActions();
        }
        stateTransition(me, me.state.onStopTransaction, title);
    }
    /**
     * Stops undo/redo recording transaction after {@link #config-autoRecordTransactionStopTimeout} delay.
     *
     * @private
     */
    stopTransactionDelayed() {
        stateTransition(this, this.state.onStopTransactionDelayed);
    }
    /**
     * Rejects currently recorded transaction.
     */
    rejectTransaction() {
        stateTransition(this, this.state.onRejectTransaction);
    }
    /**
     * Gets currently recording STM transaction.
     * @readonly
     * @property {Core.data.stm.Transaction}
     */
    get transaction() {
        return this[TRANSACTION_PROP];
    }
    /**
     * Gets titles of all recorded undo/redo transactions
     * @readonly
     * @property {String[]}
     */
    get queue() {
        return this[QUEUE_PROP].map((t) => t.title);
    }
    get rawQueue() {
        return this[QUEUE_PROP];
    }
    get isRestoringState() {
        return this.state === RestoringState;
    }
    /**
     * Gets manager restoring state.
     * @readonly
     * @property {Boolean}
     */
    get isRestoring() {
        return this.state === RestoringState || this.isApplyingStash || this.isNavigatingRevisions;
    }
    /**
     * Checks if the manager can undo.
     *
     * @property {Boolean}
     */
    get canUndo() {
        return this.state.canUndo(this);
    }
    /**
     * Checks if the manager can redo.
     *
     * @property {Boolean}
     */
    get canRedo() {
        return this.state.canRedo(this);
    }
    /**
     * Undoes current undo/redo transaction.
     * @param {Number} [steps=1]
     * @returns {Promise} A promise which is resolved when undo action has been performed
     */
    async undo(steps = 1) {
        if (!this.isReady) {
            await this.waitForReadiness();
        }
        stateTransition(this, this.state.onUndo, steps);
    }
    /**
     * Undoes all transactions.
     * @returns {Promise} A promise which is resolved when undo actions has been performed
     */
    async undoAll() {
        if (!this.isReady) {
            await this.waitForReadiness();
        }
        this.undo(this.length);
    }
    /**
     * Redoes current undo/redo transaction.
     *
     * @param {Number} [steps=1]
     * @returns {Promise} A promise which is resolved when redo action has been performed
     */
    async redo(steps = 1) {
        if (!this.isReady) {
            await this.waitForReadiness();
        }
        stateTransition(this, this.state.onRedo, steps);
    }
    /**
     * Redoes all transactions.
     * @returns {Promise} A promise which is resolved when redo actions has been performed
     */
    async redoAll() {
        if (!this.isReady) {
            await this.waitForReadiness();
        }
        this.redo(this.length);
    }
    /**
     * Resets undo/redo queue.
     */
    resetQueue(/* private */options = { undo : true, redo : true, revision : true }) {
        stateTransition(this, this.state.onResetQueue, options);
    }
    /**
     * Resets undo queue.
     */
    resetUndoQueue() {
        this.resetQueue({ undo : true });
    }
    /**
     * Resets redo queue.
     */
    resetRedoQueue() {
        this.resetQueue({ redo : true });
    }
    resetRevisionQueue() {
        this.resetQueue({ revision : true });
    }
    //#region Events and handlers
    notifyStoresAboutStateRecordingStart(transaction) {
        this.forEachStore((store) => store.onStmRecordingStart?.(this, transaction));
        /**
         * Fired upon state recording operation starts.
         *
         * @event recordingStart
         * @param {Core.data.stm.StateTrackingManager} stm
         * @param {Core.data.stm.Transaction} transaction
         */
        this.trigger('recordingStart', { stm : this, transaction });
    }
    notifyStoresAboutStateRecordingStop(transaction, reason) {
        const me = this;
        me.forEachStore((store) => store.onStmRecordingStop?.(me, transaction, reason));
        /**
         * Fired upon state recording operation stops.
         *
         * @event recordingStop
         * @param {Core.data.stm.StateTrackingManager} stm
         * @param {Core.data.stm.Transaction} transaction
         * @param {Object} reason Transaction stop reason
         * @param {Boolean} reason.stop Transaction recording has been stopped in a normal way.
         * @param {Boolean} reason.disabled Transaction recording has been stopped due to STM has been disabled.
         * @param {Boolean} reason.rejected Transaction recording has been stopped due to transaction has been rejected.
         */
        me.trigger('recordingStop', { stm : me, transaction, reason });
        if (me.revisionsEnabled && reason.stop) {
            me.increaseRevision(Transaction.from([transaction]));
        }
    }
    notifyStoresAboutStateRestoringStart() {
        this.forEachStore((store) => store.onStmRestoringStart?.(this));
        /**
         * Fired upon state restoration operation starts.
         *
         * @event restoringStart
         * @param {Core.data.stm.StateTrackingManager} stm
         */
        this.trigger('restoringStart', { stm : this });
    }
    /**
     * @param {'undo'|'redo'} cause The cause of the restore, if applicable
     * @param {Core.data.stm.Transaction[]} transactions Interacted
     * @internal
     */
    notifyStoresAboutStateRestoringStop({ cause, transactions }) {
        const me = this;
        me.forEachStore((store) => store.onStmRestoringStop?.(me));
        /**
         * Fired upon state restoration operation stops.
         *
         * @event restoringStop
         * @param {Core.data.stm.StateTrackingManager} stm
         */
        me.trigger('restoringStop', { stm : me, cause, transactions });
        if (me.revisionsEnabled) {
            if (me.asyncUndoRedo) {
                const callback = () => {
                    const transaction = Transaction.from(transactions);
                    me.increaseRevision(cause === 'undo' ? transaction.invert() : transaction);
                };
                me.trigger('increaseRevisionAsync', { callback });
            }
            else {
                const transaction = Transaction.from(transactions);
                me.increaseRevision(cause === 'undo' ? transaction.invert() : transaction);
            }
        }
    }
    notifyStoresAboutQueueReset(options) {
        this.forEachStore((store) => store.onStmQueueReset?.(this, options));
        /**
         * Fired upon state undo/redo queue reset.
         *
         * @event queueReset
         * @param {Core.data.stm.StateTrackingManager} stm
         */
        this.trigger('queueReset', { stm : this, options });
    }
    notifyStoresAboutCheckoutStart() {
        /**
         * Fires when checkout starts.
         *
         * @event checkoutStart
         * @param {Core.data.stm.StateTrackingManager} stm
         * @internal
         */
        this.trigger('checkoutStart', { stm : this });
    }
    notifyStoresAboutRevRecordingStart() {
        /**
         * Fires when recording remote revision starts.
         *
         * @event revisionRecordingStart
         * @param {Core.data.stm.StateTrackingManager} stm
         * @internal
         */
        this.trigger('revisionRecordingStart', { stm : this });
    }
    notifyStoresAboutTempRevRecordingStart() {
        /**
         * Fires when recording temporary revision starts.
         *
         * @event temporaryRevisionRecordingStart
         * @param {Core.data.stm.StateTrackingManager} stm
         * @internal
         */
        this.trigger('temporaryRevisionRecordingStart', { stm : this });
    }
    notifyStoresAboutRevRecordingStop(transaction) {
        /**
         * Fires when recording remote revision stops.
         *
         * @event revisionRecordingStop
         * @param {Core.data.stm.StateTrackingManager} stm
         * @param {String} revision Recorded revision id
         * @internal
         */
        this.trigger('revisionRecordingStop', { stm : this, revision : transaction.title });
    }
    notifyStoresAboutTempRevRecordingStop(transaction) {
        /**
         * Fires when recording temporary revision stops. This revision is ignored because we already have a transaction
         * with all the actions. It is only used to extract a conflict resolution.
         *
         * @event temporaryRevisionRecordingStop
         * @param {Core.data.stm.StateTrackingManager} stm
         * @param {String} revision Recorded revision id
         * @internal
         */
        this.trigger('temporaryRevisionRecordingStop', { stm : this, revision : transaction.title });
    }
    notifyStoresAboutCheckoutToHead({ revision }) {
        /**
         * Fires when STM is checked out to HEAD and moves to Ready/AutoReady state.
         *
         * @event checkoutToHead
         * @param {Core.data.stm.StateTrackingManager} stm
         * @param {String} revision Last revision id
         * @internal
         */
        this.trigger('checkoutToHead', { stm : this, revision });
    }
    /**
     * Method to call from model STM mixin upon model update
     *
     * @param {Core.data.Model} model
     * @param {Object} newData
     * @param {Object} oldData
     *
     * @private
     */
    onModelUpdate(model, newData, oldData, isInitialUserAction) {
        stateTransition(this, this.state.onModelUpdate, model, newData, oldData, isInitialUserAction);
    }
    /**
     * Method to call from model STM mixin upon tree model child insertion
     *
     * @param {Core.data.Model} parentModel Parent model
     * @param {Number} index Insertion index
     * @param {Core.data.Model[]} childModels Array of models inserted
     * @param {Map} context Map with inserted models as keys and objects with previous parent,
     *                      and index at previous parent.
     * @param {Core.data.Model} [orderedBeforeNode] Reference node in ordered tree
     * @private
     */
    onModelInsertChild(parentModel, index, childModels, context, orderedBeforeNode) {
        stateTransition(this, this.state.onModelInsertChild, parentModel, index, childModels, context, orderedBeforeNode);
    }
    /**
     * Method to call from model STM mixin upon tree model child removal
     *
     * @param {Core.data.Model} parentModel
     * @param {Core.data.Model[]} childModels
     * @param {Map} context
     *
     * @private
     */
    onModelRemoveChild(parentModel, childModels, context) {
        stateTransition(this, this.state.onModelRemoveChild, parentModel, childModels, context);
    }
    /**
     * Method to call from store STM mixin upon store models adding
     *
     * @param {Core.data.Store} store
     * @param {Core.data.Model[]} models
     * @param {Boolean} silent
     *
     * @private
     */
    onStoreModelAdd(store, models, silent) {
        stateTransition(this, this.state.onStoreModelAdd, store, models, silent);
    }
    /**
     * Method to call from store STM mixin upon store models insertion
     *
     * @param {Core.data.Store} store
     * @param {Number} index
     * @param {Core.data.Model[]} models
     * @param {Map} context
     * @param {Boolean} silent
     *
     * @private
     */
    onStoreModelInsert(store, index, models, context, silent) {
        stateTransition(this, this.state.onStoreModelInsert, store, index, models, context, silent);
    }
    /**
     * Method to call from store STM mixin upon store models removal
     *
     * @param {Core.data.Store} store
     * @param {Core.data.Model[]} models
     * @param {Object} context
     * @param {Boolean} silent
     *
     * @private
     */
    onStoreModelRemove(store, models, context, silent) {
        stateTransition(this, this.state.onStoreModelRemove, store, models, context, silent);
    }
    /**
     * Method to call from store STM mixin upon store clear
     *
     * @param {Core.data.Store} store
     * @param {Core.data.Model[]} allRecords
     * @param {Boolean} silent
     *
     * @private
     */
    onStoreRemoveAll(store, allRecords, silent) {
        stateTransition(this, this.state.onStoreRemoveAll, store, allRecords, silent);
    }
    // UI key event handling
    onUndoKeyPress(event) {
        const me = this;
        if (me.enabled) {
            if (event.shiftKey) {
                if (me.canRedo) {
                    event.preventDefault();
                    me.redo();
                }
            }
            else if (me.canUndo) {
                event.preventDefault();
                me.undo();
            }
        }
    }
    //#endregion
    stash() {
        const me = this;
        if (me.transaction) {
            const id = IdHelper.generateId('_stashedTransactionGeneratedId_');
            me.stashedTransactions[id] = me.transaction;
            me.rejectTransaction();
            return id;
        }
    }
    applyStash(id) {
        const
            me          = this,
            transaction = me.stashedTransactions[id];
        me[IS_APPLYING_STASH] = true;
        if (transaction) {
            me.startTransaction(transaction.title);
            transaction.redo();
            delete me.stashedTransactions[id];
        }
        me[IS_APPLYING_STASH] = false;
    }
    /**
     * Merges all update actions into one per model, keeping the oldest and the newest values.
     */
    mergeTransactionUpdateActions(transaction = this.transaction) {
        transaction.mergeUpdateModelActions();
    }
    mergeTransactionAddUpdateActions(transaction = this.transaction) {
        transaction.mergeAddUpdateModelActions();
    }
    //#region Revisions
    canCheckoutTo(revision) {
        return (this.isReady || this.state === CheckoutState) &&
            this.state.canCheckoutTo(this, revision);
    }
    get currentRevision() {
        if (this.revisionsEnabled) {
            return this[REVISION_NAMES_PROP][this[REVISION_INDEX_PROP]];
        }
    }
    get lastRevision() {
        if (this.revisionsEnabled) {
            return this[REVISION_NAMES_PROP][this[REVISION_NAMES_PROP].length - 1];
        }
    }
    get currentRevisionTransaction() {
        if (this.revisionsEnabled) {
            return this[REVISION_QUEUE_PROP][this[REVISION_INDEX_PROP] - 1];
        }
    }
    get lastCommittedRevision() {
        const revisionQueue = this[REVISION_QUEUE_PROP];
        let revision;
        for (let i = revisionQueue.length - 1; i >= 0; i--) {
            if (revisionQueue[i].committed) {
                revision = revisionQueue[i];
                break;
            }
        }
        return revision;
    }
    get lastCommittedRevisionId() {
        return this.lastCommittedRevision?.title ?? this[REVISION_NAMES_PROP][0];
    }
    get localRevisions() {
        return this[REVISION_QUEUE_PROP].slice(this[REVISION_QUEUE_PROP].findIndex(r => !r.committed));
    }
    get isCheckingOut() {
        return this.state === CheckoutState;
    }
    get isRecordingRevision() {
        return this.state === RevisionRecordingState;
    }
    get isRecordingTemporaryRevision() {
        return this.state === TemporaryRevisionRecordingState;
    }
    get isNavigatingRevisions() {
        return this.state === CheckoutState || this.state === RevisionRecordingState || this.state === TemporaryRevisionRecordingState;
    }
    /**
     * This method sets revision number of the initial data set. It should be called once after loading the data to set
     * a starting point to sync revisions with the backend.
     * @param {String} revision
     * @param {Boolean} [enableAutoRecord]
     * @internal
     */
    initRevision(revision, enableAutoRecord = true) {
        const me = this;
        if (me.revisionsEnabled) {
            me.autoRecord = enableAutoRecord;
            // We keep list of revision ids in one array and list of transactions in another. Base revision does not have a
            // corresponding transaction, any other does. Transactions array is one-off from revisions array. It allows us
            // to produce nice slices of transactions that we want to undo or redo.
            me[REVISION_NAMES_PROP] = [revision];
            me[REVISION_QUEUE_PROP] = [];
            me[REVISION_INDEX_PROP] = 0;
        }
    }
    /**
     * This method takes transaction and creates local revision
     * @param {Core.data.stm.Transaction} transaction
     * @param {Object} [changes] Can be provided to a conflict resolving revision
     * @internal
     */
    increaseRevision(transaction, changes) {
        const me = this;
        let userInput;
        if (!me.revisionsEnabled) {
            return;
        }
        // We cannot push new transaction if we're not on the latest revision, because that would create branch
        if (me.isNavigatingRevisions && !transaction.type) {
            throw new RevisionException('Cannot add new revision when in the checkout state');
        }
        if (!transaction[ACTION_QUEUE_PROP].length && !transaction.type) {
            return;
        }
        const localRevisionId = IdHelper.generateId(me.revisionLocalPrefix);
        transaction.title = localRevisionId;
        // Project model can effectively cancel the input in which case we do not need to store such transaction
        if (me.trigger('beforeRevisionAdd', { localRevisionId }) === false) {
            return;
        }
        // If there is a temporary revision which is a conflict resolution for a current revision, we need to replace it
        const temporaryTransaction = transaction.conflictResolutionFor && me[REVISION_QUEUE_PROP].find(
            t => t.type === TRANSACTION_TYPES.TEMPORARY && t.conflictResolutionFor === transaction.conflictResolutionFor
        );
        if (temporaryTransaction) {
            me[REVISION_NAMES_PROP].splice(me[REVISION_NAMES_PROP].indexOf(temporaryTransaction.title), 1, localRevisionId);
            me[REVISION_QUEUE_PROP].splice(me[REVISION_QUEUE_PROP].indexOf(temporaryTransaction), 1, transaction);
        }
        else {
            me[REVISION_NAMES_PROP].push(localRevisionId);
            me[REVISION_QUEUE_PROP].push(transaction);
        }
        if (!transaction.conflictResolutionFor) {
            me[REVISION_INDEX_PROP] = me[REVISION_NAMES_PROP].length - 1;
        }
        // Do not notify about temporary transactions
        if (transaction.type === TRANSACTION_TYPES.TEMPORARY) {
            return;
        }
        if (transaction.filterUserInput) {
            // When working with task editor there will be a lot of transactions
            // with different input generation. We need all those actions made by
            // the user, so we assign same input generation to them
            if (!transaction.conflictResolutionFor) {
                transaction.normalizeUserInputGeneration();
            }
            userInput = transaction.getUserInput();
        }
        // We do not expect queue of uncommitted revisions to grow too long. But even if it does, this method
        // call is fairly cheap
        if (!changes && me[REVISION_QUEUE_PROP].length > me.revisionQueueMaxLength && !transaction.type) {
            me.cleanUpRevisions();
        }
        const eventParams = { localRevisionId, userInput };
        if (transaction.conflictResolutionFor) {
            eventParams.conflictResolutionFor = transaction.conflictResolutionFor;
            eventParams.revisionChanges = changes;
        }
        /**
         * Triggered when revision is added to the revision queue.
         * @event revisionAdd
         * @param {String} localRevisionId Local revision identifier
         * @param {String} [conflictResolutionFor] If this revision is a conflict resolution revision, this will be the
         * set to the title of the revision it resolves.5
         * @param {Map|undefined} userInput Map, where keys are records and values are direct changes. Used by revisions
         * feature to separate project inputs from project changes. Which is required to not trigger recalculations
         * when they are not needed.
         * @internal
         */
        me.trigger('revisionAdd', eventParams);
    }
    /**
     * This method creates special data revision which has no input state, only a data correction for a case when
     * applying remote revisions lead to new changes. This revision will be sent, it should be persisted on the backend,
     * and any client applying it should not see any changes after.
     * @internal
     */
    createDataCorrectionTransaction() {
        const transaction = new Transaction({ type : TRANSACTION_TYPES.DATA_CORRECTION });
        this.increaseRevision(transaction);
    }
    /**
     * This method creates special conflict resolution revision which contains only conflict resolution actions. It is
     * linked to the revision with a conflict via `conflictResolutionFor` property. Such revision cannot be rolled back
     * locally because it will introduce the same conflict again.
     * @internal
     */
    createConflictResolutionRevision(conflictResolutionFor, localRevisionId, changes) {
        const { transaction } = this;
        changes = ObjectHelper.clone(changes);
        // It is possible we have a conflict resolution revision referencing a local revision id. In which case we
        // need to update local id in a `conflictResolutionFor` field to a new revision id.
        if (localRevisionId) {
            const revision = this[REVISION_QUEUE_PROP].find(t => t.conflictResolutionFor === localRevisionId);
            if (revision) {
                revision.conflictResolutionFor = conflictResolutionFor;
            }
        }
        this.increaseRevision(Transaction.from([transaction], {
            conflictResolutionFor : conflictResolutionFor ?? transaction.title,
            type                  : TRANSACTION_TYPES.CONFLICT_RESOLUTION
        }), changes);
    }
    createTemporaryConflictResolutionRevision(conflictResolutionFor, changes) {
        const { transaction } = this;
        this.increaseRevision(Transaction.from([transaction], {
            conflictResolutionFor : conflictResolutionFor ?? transaction.title,
            type                  : TRANSACTION_TYPES.TEMPORARY
        }), changes);
    }
    checkoutTo(revision) {
        if (!this.canCheckoutTo(revision)) {
            return false;
        }
        stateTransition(this, this.state.onCheckoutTo, revision);
    }
    checkoutToLastCommittedRevision() {
        this.checkoutTo(this.lastCommittedRevisionId);
    }
    checkoutToHead() {
        this.checkoutTo(this[REVISION_NAMES_PROP][this[REVISION_NAMES_PROP].length - 1]);
        stateTransition(this, this.state.onCheckoutToHead);
    }
    checkoutToNext() {
        if (this[REVISION_INDEX_PROP] + 1 < this[REVISION_NAMES_PROP].length) {
            return this.checkoutTo(this[REVISION_NAMES_PROP][this[REVISION_INDEX_PROP] + 1]);
        }
        else {
            return false;
        }
    }
    startRevision(revisionId) {
        stateTransition(this, this.state.onStartRevision, revisionId);
    }
    stopRevision() {
        stateTransition(this, this.state.onStopRevision);
    }
    startTemporaryRevision(revisionId, sourceTransaction) {
        stateTransition(this, this.state.onStartTemporaryRevision, revisionId, sourceTransaction);
    }
    stopTemporaryRevision() {
        stateTransition(this, this.state.onStopRevision);
    }
    /**
     * Commits revision, can assign a new id for the local revision which is already applied, but was
     * finally committed on the backend.
     * @param {String} oldId
     * @param {String/Number} newId
     * @internal
     */
    commitRevision(oldId, newId) {
        const
            revisions     = this[REVISION_NAMES_PROP],
            revisionQueue = this[REVISION_QUEUE_PROP],
            transaction   = revisionQueue.find(t => t.title === oldId);
        for (let i = revisionQueue.indexOf(transaction) - 1; i >= 0; i--) {
            // Conflict resolution revision may appear in the middle of the committed queue. Which is expected because
            // we want to keep conflict resolution close to a conflict.
            if (revisionQueue[i].committed === false && revisionQueue[i].type !== TRANSACTION_TYPES.TEMPORARY && !revisionQueue[i].conflictResolutionFor) {
                throw new RevisionException(`Cannot commit revision because it is preceded by an uncommitted rev id ${revisionQueue[i].title}`, revisionQueue);
            }
        }
        if (newId) {
            if (revisions.includes(newId)) {
                throw new RevisionException(`Proposed revision id (${newId}) already exists`);
            }
            transaction.title = revisions[revisions.indexOf(transaction.title)] = newId;
            revisionQueue.forEach(t => {
                if (t.type === TRANSACTION_TYPES.TEMPORARY && t.conflictResolutionFor === oldId) {
                    t.conflictResolutionFor = newId;
                }
            });
        }
        transaction.committed = true;
    }
    /**
     * Clears committed revisions, we only need to use last one.
     * @internal
     */
    cleanUpRevisions(minimumCommittedLength = this.revisionQueueCommittedMinLength) {
        const
            revisions     = this[REVISION_NAMES_PROP],
            revisionQueue = this[REVISION_QUEUE_PROP],
            revsToDelete  = [];
        for (let i = 0; i < revisionQueue.length; i++) {
            const rev = revisionQueue[i];
            if (rev.committed) {
                revsToDelete.push(rev);
            }
            else {
                break;
            }
        }
        // Keep last N revisions
        revsToDelete.splice(
            // Start removing at position N from the end
            Math.max(0, revsToDelete.length - 1 - minimumCommittedLength),
            // Remove max N or array.length elements
            Math.min(revsToDelete.length, minimumCommittedLength)
        );
        revisionQueue.splice(0, revsToDelete.length);
        revisions.splice(1, revsToDelete.length);
    }
    /**
     * Used to distinguish user input from values calculated by the project. If any actions in the revision are marked
     * as user input then checkout logic will only use user input. If nothing is marked, then revision is not treated
     * specially.
     * @internal
     */
    markCurrentTransactionContentUserInput() {
        if (this.isRecording || this.isRecordingRevision || this.isRecordingTemporaryRevision) {
            this.transaction.markCurrentTransactionContentUserInput();
        }
    }
    markCurrentTransactionContentCalculated() {
        if (this.isRecording || this.isRecordingRevision || this.isRecordingTemporaryRevision) {
            this.transaction.markCurrentTransactionContentCalculated();
        }
    }
    //#endregion
}
StateTrackingManager._$name = 'StateTrackingManager';