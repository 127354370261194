import InstancePlugin from '../../../Core/mixin/InstancePlugin.js';
import ObjectHelper from '../../helper/ObjectHelper.js';
import Promissory from '../../helper/util/Promissory.js';
/**
 * @module Core/data/plugin/StoreLazyLoadPlugin
 */
/**
 * Plugin for Store that handles lazy loading.
 * @plugin
 * @internal
 */
export default class StoreLazyLoadPlugin extends InstancePlugin {
    static $name = 'StoreLazyLoadPlugin';
    static pluginConfig = {
        override : ['updateAutoLoad'],
        assign   : ['load', 'isLoading', 'lazyGetAt'],
        after    : ['afterLoadData'],
        before   : ['setStoreData']
    };
    static configurable = {
        // These can be overridden in AjaxStore.
        // Matches the default values of responseTotalProperty and responseDataProperty
        totalCountProperty : 'total',
        dataProperty       : 'data',
        chunkSize          : 100
    };
    static get properties() {
        return {
            loadQueue : {}
        };
    }
    /**
     * In a lazyLoad store, the function provided here is called when a record that has not yet been loaded is
     * requested. When implementing this, it is expected that what is returned is an object with a `data` property
     * containing the number of records specified in the `count` param starting from the specified `startIndex`. It is
     * also recommended to include a `total` property which reflects the total amount of records available to load. If
     * the `total` property is omitted, certain features and functions are disabled:
     *
     * * The component (Grid for example) is not aware of the total number of records, which will make the scrollbar's
     *   thumb change size and position when new records are loaded.
     * * The store don't know when to stop requesting new records. The `total` property will be set to the index of the
     *   last record loaded after requestData returns with fewer records than requested.
     *
     * Base implementation does nothing, either use AjaxStore which implements it, or create your own subclass with an
     * implementation.
     *
     * ````javascript
     * class MyStore extends Store {
     *    async requestData(params){
     *       const response = await fetch('https://api.bryntum.com/data/?' + new URLSearchParams(params));
     *       return await response.json();
     *    }
     * }
     * ````
     * @function requestData
     * @param {Object} options
     * @param {Number} options.startIndex
     * @param {Number} options.count
     * @returns {Promise}
     * @on-owner
     */
    // Checks if there is any unresolved loading promises
    get isLoading() {
        return this.client._isLoading ||
            Object.values(this.loadQueue).some(value => ObjectHelper.isPromise(value));
    }
    get storage() {
        return this.client.storage;
    }
    set totalCount(count) {
        this._totalCount = count;
    }
    get totalCount() {
        return this._totalCount;
    }
    // Overridden in TreeStoreLazyLoadPlugin
    setEstimatedTotalCount(count) {
        this.totalCount = count;
    }
    afterConstruct() {
        const
            me         = this,
            { client } = me;
        if (client.syncDataOnLoad) {
            client.syncDataOnLoad = false;
        }
        me.storeListenersDetacher = client.ion({
            beforeAdd         : 'internalOnBeforeAdd',
            beforeRemove      : 'internalOnBeforeAdd',
            change            : 'internalOnChange',
            commit            : 'internalOnCommit',
            endApplyChangeset : 'internalOnCommit',
            thisObj           : me
        });
        // Only sets the default requestData function if there isn't one already
        if (!client.requestData) {
            client.requestData = me.requestData;
        }
        if (client.autoLoad) {
            me.load();
        }
    }
    updateAutoLoad(autoLoad) {
        if (autoLoad && !this.client.isAjaxStore) {
            this.load();
        }
    }
    //region Internal listeners
    internalOnBeforeAdd() {
        // If we are loading, we are not allowed to add
        if (this.isLoading) {
            throw new Error('Removing or adding is not allowed when a lazy loaded Store is loading');
        }
    }
    // If we have any uncommited changes, we are not allowed to load more data
    internalOnChange() {
        if (!this.$loadLock && (this.client.added.count || this.client.removed.count)) {
            this.$loadLock = new Promissory();
        }
    }
    // Enable the loading of data upon commit
    internalOnCommit() {
        if (!this.client.changes && this.$loadLock) {
            this.$loadLock.resolve();
            this.$loadLock = null;
        }
    }
    //endregion
    // region Store overrides
    afterLoadData() {
        // When loading/setting a completely new dataset, the underlying collection need to have to full length of all
        // records available to buffer. Need to do it here because the collection need to have correct length before
        // the refresh and change events are triggered
        if (this.totalCount) {
            this.storage.values.length = this.totalCount;
        }
    }
    setStoreData(data) {
        // Reset queue if we had data but is setting empty dataset
        if (this.client.records.filter(r => r).length && !data?.length) {
            this.totalCount = null;
            this.loadQueue = {};
        }
    }
    // endregion
    // Silently clears all data and load information
    clearLoaded() {
        const { client } = this;
        client.suspendEvents();
        this.totalCount = null;
        client.clear(true);
        this.loadQueue = {};
        client.resumeEvents();
    }
    // Documented in Store.js
    async requestData({ startIndex, count }) {}
    // Assigned to store
    lazyGetAt(index) {
        return this.getAt(index);
    }
    /**
     * Overrides the Store's `getAt` function. If the record at the provided `index` is already loaded, that record will
     * be returned instantly. If not, a request for a range of records containing that index will be made and a promise
     * that resolves to the requested record when the load completes.
     * @param index
     * @internal
     * @returns {Promise}
     */
    async getAt(index) {
        const
            me = this,
            {
                loadQueue,
                totalCount
            }         = me;
        if (index < 0 || (totalCount != null && index > totalCount)) {
            return null;
        }
        const record = me.getFromStorage(index);
        // Return record immediately if its already loaded
        if (record) {
            return record;
        }
        // Wait for any uncommited changes
        await me.$loadLock?.promise;
        // If waiting for current record to load, wait for that promise
        if (loadQueue[index]) {
            await loadQueue[index];
        }
        // Otherwise, initiate a lazy load and wait for that
        else {
            await me.doLazyLoad(me.calculateRange(index));
        }
        return me.getFromStorage(index);
    }
    // Overridden in TreeStoreLazyLoadPlugin
    getFromStorage(index) {
        return this.storage.getAt(index);
    }
    // Given an index, this will return a startIndex and a count to request externally
    calculateRange(index) {
        const
            {
                totalCount,
                loadQueue,
                chunkSize
            }             = this,
            minStart      = Math.max(0, index - chunkSize),
            maxEnd        = (totalCount ? Math.min(index + chunkSize, totalCount) : index + chunkSize) - 1;
        let startIndex    = index,
            endIndex      = index;
        // Find index where chunk should begin
        while (startIndex > minStart && !loadQueue[startIndex - 1] && !this.getFromStorage(startIndex - 1)) {
            startIndex -= 1;
        }
        // Find index where chunk should end
        while (endIndex < maxEnd && !loadQueue[endIndex + 1] && !this.getFromStorage(endIndex + 1)) {
            endIndex += 1;
        }
        return {
            startIndex,
            count : endIndex - startIndex + 1
        };
    }
    // Takes a startIndex and endIndex and requests the records in that range. Saves the promises in a queue. Also
    // checks whether the range overlaps with any already requested (but not resolved) indexes.
    async doLazyLoad({ startIndex, count }) {
        this.triggerLazyLoadStart(arguments[0]);
        const
            me            = this,
            { loadQueue } = me,
            endIndex      = startIndex + count - 1,
            promise        = me.client.requestData(arguments[0]);
        // Save all promises in a queue
        for (let i = startIndex; i <= endIndex; i++) {
            loadQueue[i] = promise;
        }
        const response = await promise;
        if (!response) {
            return;
        }
        const {
            [me.totalCountProperty] : totalCount,
            [me.dataProperty]       : data
        } = response;
        // Clear the queue
        for (let i = startIndex; i <= endIndex; i++) {
            loadQueue[i] = null;
        }
        // Got a total count from the server
        if (totalCount != null) {
            // Saves count to be set in afterLoadData
            me.totalCount = totalCount;
        }
        // No total count from the server
        else if (me.totalCount == null) {
            // When we get less records than requested, we assume we hit the total count
            if (count > data.length) {
                me.setEstimatedTotalCount(startIndex + data.length);
            }
        }
        me.addData(data, startIndex);
        if (me.totalCount != null) {
            me.storage.values.length = me.totalCount;
        }
        me.triggerLazyLoadEnd(arguments[0], data);
    }
    // Adds data to the store
    addData(data, index) {
        const { client, storage } = this;
        if (!data?.length) {
            return;
        }
        // If this is the first load, and were loading from index 0, we can do a normal data set operation
        if (!client.count && !index) {
            client.data = data;
            return;
        }
        // If new id exists in collection, remove that row from the data
        for (let i = data.length - 1; i >= 0; i--) {
            const
                row      = data[i],
                existing = client.idRegister[row.id];
            if (existing) {
                existing.setByDataSource(row, true);
                existing.internalClearChanges(false, true, row);
                data.splice(i, 1);
            }
        }
        if (!data.length) {
            return;
        }
        const
            { values } = storage,
            records    = client.processRecords(data, undefined, true);
        // We cant splice from an index higher than the array's length
        if (values.length < index) {
            values.length = index;
        }
        // No index specified means were simply pushing to the array
        if (index == null) {
            index = values.length;
        }
        values.splice(index, records.length, ...records);
        client.joinRecordsToStore(records);
        client.updateDependentStores('add', records);
        // Need to add these records to the idMap and collection index
        for (const record of records) {
            client.idMap[record.id] = { index, visibleIndex : index, record };
            index += 1;
            storage.addToIndices(record);
        }
        client.trigger('change', {
            action : 'lazyload',
            records
        });
    }
    // If new id exists in collection, remove that record
    checkDuplicates(records) {
        const { storage } = this;
        for (const record of records) {
            const i = storage.indexOf(record.id);
            if (i >= 0) {
                storage.values.splice(i, 1);
            }
        }
    }
    /**
     * Fired when the store starts loading new chunks (the store enters a state of loading). This event will not be
     * triggered if new records are requested when the store already is loading.
     * @event lazyLoadStarted
     * @param {Core.data.Store} source This Store
     * @on-owner
     */
    triggerLazyLoadStart(params) {
        const me = this;
        if (!me.isLoading) {
            if (me.isConfiguring) {
                // If this is initial load, it might be too early to trigger the event
                me.delay(() => me.trigger('lazyLoadStarted'), 0);
            }
            else {
                me.client.trigger('lazyLoadStarted');
            }
        }
        // Currently internal use only
        me.client.trigger('beforeLazyLoad', { ...params });
    }
    /**
     * Fired when the store finished loading new chunks (the store stops loading). This event will not be triggered
     * if the store has loading requests pending response.
     * @event lazyLoadEnded
     * @param {Core.data.Store} source This Store
     * @on-owner
     */
    triggerLazyLoadEnd(params, data) {
        if (!this.isLoading) {
            this.client.trigger('lazyLoadEnded');
        }
        // Currently internal use only
        this.client.trigger('afterLazyLoad', { ...params, data });
    }
    /**
     * Only available if the store is configured as {@link Core.data.Store#config-lazyLoad}. Calling will initiate a
     * load request for 1 chunk of records starting from index 0. If the store has previously been loaded, it will be
     * cleared of all records and all lazy loading cache.
     * @param {Object} [params] An optional object with parameters that will be included in the fetch request (only
     *                          available for {@link Core.data.AjaxStore}).
     * @on-owner
     * @returns {Promise}
     */
    load(params) {
        if (this.client.count > 0) {
            this.clearLoaded();
        }
        return this.getAt(0);
    }
    // Correct?
    doDestroy() {
        this.storeListenersDetacher?.();
        this.loadQueue = {};
        super.doDestroy();
    }
};
StoreLazyLoadPlugin._$name = 'StoreLazyLoadPlugin';