import DateDataField from '../../../Core/data/field/DateDataField.js';
import Model from '../../../Core/data/Model.js';
import DateHelper from '../../../Core/helper/DateHelper.js';
/**
 * @module SchedulerPro/model/calendareditor/AvailabilityRangeModel
 */
/**
 * The class implements a record of
 * {@link SchedulerPro/data/calendareditor/AvailabilityRangeStore} store
 * (a part of the {@link SchedulerPro/widget/CalendarEditor calendar editor} data level).
 *
 * @extends Core/data/Model
 */
export default class AvailabilityRangeModel extends Model {
    static $name = 'AvailabilityRangeModel';
    static fields = [
        /**
         * Availability range start.
         * @field {String|Date} startDate
         * @category Common
         */
        { name : 'startDate', type : 'date', format : 'HH:mm' },
        /**
         * Availability range end.
         * @field {String|Date} endDate
         * @category Common
         */
        {
            name   : 'endDate',
            type   : 'date',
            format : 'HH:mm',
            convert(value, ...args) {
                // call original DateDataField to get Date value
                value = DateDataField.prototype.convert.call(this, value, ...args);
                if (value) {
                    value = DateHelper.getTime(value);
                    // if that's a 00:00 time move it to the next day 00:00
                    // to make startDate < endDate check happy
                    if (DateHelper.isMidnight(value)) {
                        value = DateHelper.getStartOfNextDay(value);
                    }
                }
                return value;
            }
        }
    ];
    // list all possible errors returned by this class getErrors method
    static errors = {
        errorMissingDate   : 'errorMissingDate',
        errorStartAfterEnd : 'errorStartAfterEnd',
        errorOverlap       : 'errorOverlap'
    };
    get isValid() {
        return !this.getErrors();
    }
    getErrors(values = {}) {
        const
            {
                startDate = this.startDate,
                endDate = this.endDate
            } = values,
            { errors } = this.constructor,
            result = [];
        if (!startDate || !endDate) {
            result.push(errors.errorMissingDate);
        }
        else {
            if (startDate > endDate) {
                result.push(errors.errorStartAfterEnd);
            }
            const store = this.firstStore;
            if (store && store?.validateOverlap({ startDate, endDate, record : this }) === false) {
                result.push(errors.errorOverlap);
            }
        }
        return result.length ? result : null;
    }
}
AvailabilityRangeModel._$name = 'AvailabilityRangeModel';