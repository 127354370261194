import { Mixin } from '../../../../ChronoGraph/class/BetterMixin.js';
import { ConstrainedScheduleMixin } from "./ConstrainedScheduleMixin.js";
/**
 * This mixin implements a schedule for date-based based constraint for the event.
 */
export class HasDateConstraintScheduleMixin extends Mixin([ConstrainedScheduleMixin], (base) => {
    const superProto = base.prototype;
    class HasDateConstraintScheduleMixin extends base {
        *calculateStartDateConstraintIntervals() {
            const intervals = yield* superProto.calculateStartDateConstraintIntervals.call(this);
            const manuallyScheduled = yield this.event.$.manuallyScheduled;
            const constraintType = yield this.event.$.constraintType;
            const constraintDate = yield this.event.$.constraintDate;
            // manually scheduled task ignores its constraints
            if (!manuallyScheduled && constraintType && constraintDate) {
                yield* this.event.createStartDateConstraintsInterval(constraintType, constraintDate, this, intervals);
            }
            return intervals;
        }
        *calculateEndDateConstraintIntervals() {
            const intervals = yield* superProto.calculateEndDateConstraintIntervals.call(this);
            const manuallyScheduled = yield this.event.$.manuallyScheduled;
            const constraintType = yield this.event.$.constraintType;
            const constraintDate = yield this.event.$.constraintDate;
            // manually scheduled task ignores its constraints
            if (!manuallyScheduled && constraintType && constraintDate) {
                yield* this.event.createEndDateConstraintsInterval(constraintType, constraintDate, this, intervals);
            }
            return intervals;
        }
    }
    return HasDateConstraintScheduleMixin;
}) {
}
