var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Mixin } from '../../../../ChronoGraph/class/BetterMixin.js';
import { calculate, field } from '../../../../ChronoGraph/replica/Entity.js';
import { ConstraintInterval } from "../../../chrono/Conflict.js";
import { dateConverter, model_field } from '../../../chrono/ModelFieldAtom.js';
import { intersectIntervals } from '../../../scheduling/DateInterval.js';
import { ConstraintIntervalSide, Direction } from '../../../scheduling/Types.js';
import { isDateFinite, MAX_DATE, MIN_DATE } from "../../../util/Constants.js";
import { ChronoPartOfProjectModelMixin } from "../mixin/ChronoPartOfProjectModelMixin.js";
//---------------------------------------------------------------------------------------------------------------------
export const calculateEffectiveStartDateConstraintInterval = function* (event, startDateIntervalIntersection, endDateIntervalIntersection, duration, collectIntersectionMeta) {
    if (endDateIntervalIntersection.isIntervalEmpty())
        return endDateIntervalIntersection; //EMPTY_INTERVAL
    // If intersection details collecting is enabled (need this when preparing a scheduling conflict info)
    if (collectIntersectionMeta && endDateIntervalIntersection.intersectionOf) {
        const reflectedIntervals = new Set();
        // Iterate over the intervals that took part in "endDateIntervalIntersection" building
        // and reflect each of them to task "End" side.
        // So we could compare each interval one by one.
        for (const interval of endDateIntervalIntersection.intersectionOf) {
            if (interval.isInfinite()) {
                reflectedIntervals.add(interval);
            }
            else {
                const startDate = interval.startDateIsFinite()
                    ? yield* event.calculateProjectedXDateWithDuration(interval.startDate, false, duration)
                    : interval.startDate;
                const endDate = interval.endDateIsFinite()
                    ? yield* event.calculateProjectedXDateWithDuration(interval.endDate, false, duration)
                    : interval.endDate;
                const originInterval = interval;
                reflectedIntervals.add(originInterval.copyWith({
                    reflectionOf: originInterval,
                    side: originInterval.side === ConstraintIntervalSide.Start ? ConstraintIntervalSide.End : ConstraintIntervalSide.Start,
                    startDate,
                    endDate,
                }));
            }
        }
        // override intersectionOf with reflected intervals
        endDateIntervalIntersection.intersectionOf = reflectedIntervals;
    }
    const startDate = endDateIntervalIntersection.startDateIsFinite()
        ? yield* event.calculateProjectedXDateWithDuration(endDateIntervalIntersection.startDate, false, duration)
        : null;
    const endDate = endDateIntervalIntersection.endDateIsFinite()
        ? yield* event.calculateProjectedXDateWithDuration(endDateIntervalIntersection.endDate, false, duration)
        : null;
    return intersectIntervals([
        startDateIntervalIntersection,
        ConstraintInterval.new({
            intersectionOf: endDateIntervalIntersection.intersectionOf,
            startDate,
            endDate
        })
    ], collectIntersectionMeta);
};
export const calculateEffectiveEndDateConstraintInterval = function* (event, startDateIntervalIntersection, endDateIntervalIntersection, duration, collectIntersectionMeta) {
    if (startDateIntervalIntersection.isIntervalEmpty())
        return startDateIntervalIntersection; //EMPTY_INTERVAL
    // If intersection details collecting is enabled (need this when preparing a scheduling conflict info)
    if (collectIntersectionMeta) {
        const reflectedIntervals = new Set();
        // Iterate over the intervals that took part in "startDateIntervalIntersection" building
        // and reflect each of them to task "End" side.
        // So we could compare each interval one by one.
        for (const interval of startDateIntervalIntersection.intersectionOf) {
            // no need to reflect infinite intervals
            if (interval.isInfinite()) {
                reflectedIntervals.add(interval);
            }
            // reflect finite interval
            else {
                const startDate = interval.startDateIsFinite()
                    ? yield* event.calculateProjectedXDateWithDuration(interval.startDate, true, duration)
                    : interval.startDate;
                const endDate = interval.endDateIsFinite()
                    ? yield* event.calculateProjectedXDateWithDuration(interval.endDate, true, duration)
                    : interval.endDate;
                const originInterval = interval;
                // Make a reflection of the interval
                reflectedIntervals.add(originInterval.copyWith({
                    reflectionOf: originInterval,
                    side: originInterval.side === ConstraintIntervalSide.Start ? ConstraintIntervalSide.End : ConstraintIntervalSide.Start,
                    startDate,
                    endDate,
                }));
            }
        }
        // override intersectionOf with reflected intervals
        startDateIntervalIntersection.intersectionOf = reflectedIntervals;
    }
    const startDate = startDateIntervalIntersection.startDateIsFinite()
        ? yield* event.calculateProjectedXDateWithDuration(startDateIntervalIntersection.startDate, true, duration)
        : null;
    const endDate = startDateIntervalIntersection.endDateIsFinite()
        ? yield* event.calculateProjectedXDateWithDuration(startDateIntervalIntersection.endDate, true, duration)
        : null;
    return intersectIntervals([
        endDateIntervalIntersection,
        ConstraintInterval.new({
            reflectionOf: startDate || endDate ? startDateIntervalIntersection : undefined,
            intersectionOf: startDate || endDate ? startDateIntervalIntersection.intersectionOf : undefined,
            startDate,
            endDate
        }),
    ], collectIntersectionMeta);
};
/**
 * Abstract schedule of the event. It just defines a position of the event, w/o specifying how it is calculated.
 */
export class EventScheduleMixin extends Mixin([ChronoPartOfProjectModelMixin], (base) => {
    class EventScheduleMixin extends base {
        constructor() {
            super(...arguments);
            this.direction = undefined;
            /**
             * The event, to which this schedule belongs. This property is immutable - it is assigned on creation
             * and can not be modified after.
             */
            this.event = undefined;
        }
        get project() {
            return this.event?.project;
        }
        pick(event) {
            throw new Error("Implement me");
        }
        *calculateStartDate() {
            throw new Error("Implement me");
        }
        *calculateEndDate() {
            throw new Error("Implement me");
        }
    }
    __decorate([
        model_field({ type: 'date', persist: false, calculated: true }, { lazy: true, converter: dateConverter, persistent: false })
    ], EventScheduleMixin.prototype, "startDate", void 0);
    __decorate([
        model_field({ type: 'date', persist: false, calculated: true }, { lazy: true, converter: dateConverter, persistent: false })
    ], EventScheduleMixin.prototype, "endDate", void 0);
    __decorate([
        calculate('startDate')
    ], EventScheduleMixin.prototype, "calculateStartDate", null);
    __decorate([
        calculate('endDate')
    ], EventScheduleMixin.prototype, "calculateEndDate", null);
    return EventScheduleMixin;
}) {
}
/**
 * Schedule of the event. It may or may be not used as a final position of the event itself, depending on
 * scheduling direction and other data.
 *
 * Accumulates the constraints for start/end dates.
 */
export class ConstrainedScheduleMixin extends Mixin([EventScheduleMixin], (base) => {
    class ConstrainedScheduleMixin extends base {
        constructor() {
            super(...arguments);
            this.direction = Direction.Forward;
            this.event = undefined;
        }
        pick(event) {
            return event.earlyPreSchedule;
        }
        // Skips non-working time if it's needed to the event
        *maybeSkipNonWorkingTime(date, isForward = true) {
            // We don't really need to skip non-working time for a summary task start/end dates.
            // It just reflects corresponding min/max values of its children
            if (yield* this.event.hasSubEvents())
                return date;
            let duration = yield* this.event.calculateEffectiveDuration();
            return date && duration > 0 ? yield* this.event.skipNonWorkingTime(date, isForward) : date;
        }
        *calculateEffectiveConstraintInterval(isStartDate, startDateConstraintIntervals, endDateConstraintIntervals, collectIntersectionMeta = false) {
            const effectiveDurationToUse = yield* this.event.calculateEffectiveDuration();
            if (effectiveDurationToUse == null) {
                return null;
            }
            const calculateIntervalFn = isStartDate ? calculateEffectiveStartDateConstraintInterval : calculateEffectiveEndDateConstraintInterval;
            const effectiveInterval = yield* calculateIntervalFn(this.event, intersectIntervals(startDateConstraintIntervals, collectIntersectionMeta), intersectIntervals(endDateConstraintIntervals, collectIntersectionMeta), effectiveDurationToUse, collectIntersectionMeta);
            return effectiveInterval;
        }
        /**
         * Calculation method for the [[startDateConstraintIntervals]]. Returns empty array by default.
         * Override this method to return some extra constraints for the start date.
         */
        *calculateStartDateConstraintIntervals() {
            return this.direction === Direction.Forward
                ? yield* this.event.calculateEarlyStartDateConstraintIntervals()
                : yield* this.event.calculateLateStartDateConstraintIntervals();
        }
        /**
         * Calculation method for the [[endDateConstraintIntervals]]. Returns empty array by default.
         * Override this method to return some extra constraints for the end date.
         */
        *calculateEndDateConstraintIntervals() {
            return this.direction === Direction.Forward
                ? yield* this.event.calculateEarlyEndDateConstraintIntervals()
                : yield* this.event.calculateLateEndDateConstraintIntervals();
        }
        *doCalculateEffectiveStartDateInterval(collectIntersectionMeta = false) {
            const startDateConstraintIntervals = yield this.$.startDateConstraintIntervals;
            const endDateConstraintIntervals = yield this.$.endDateConstraintIntervals;
            return yield* this.calculateEffectiveConstraintInterval(true, 
            // need to use concat instead of directly mutating the `startDateConstraintIntervals` since that is
            // used as storage for `this.$.earlyStartDateConstraintIntervals`
            startDateConstraintIntervals.concat(yield this.event.$.startDateConstraintIntervals), endDateConstraintIntervals.concat(yield this.event.$.endDateConstraintIntervals), collectIntersectionMeta);
        }
        *doCalculateEffectiveEndDateInterval(collectIntersectionMeta = false) {
            const startDateConstraintIntervals = yield this.$.startDateConstraintIntervals;
            const endDateConstraintIntervals = yield this.$.endDateConstraintIntervals;
            return yield* this.calculateEffectiveConstraintInterval(false, 
            // need to use concat instead of directly mutating the `startDateConstraintIntervals` since that is
            // used as storage for `this.$.earlyStartDateConstraintIntervals`
            startDateConstraintIntervals.concat(yield this.event.$.startDateConstraintIntervals), endDateConstraintIntervals.concat(yield this.event.$.endDateConstraintIntervals), collectIntersectionMeta);
        }
        /**
         * The method defines whether the provided child event should be
         * taken into account when calculating this summary event [[earlyStartDate]].
         * Child events roll up their [[earlyStartDate]] values to their summary tasks.
         * So a summary task [[earlyStartDate]] date gets equal to its minimal child [[earlyStartDate]].
         *
         * If the method returns `true` the child event is taken into account
         * and if the method returns `false` it's not.
         * By default, the method returns `true` to include all child events data.
         * @param child Child event to consider.
         * @returns `true` if the provided event should be taken into account, `false` if not.
         */
        *shouldRollupChildStartDate(child) {
            return this.direction === Direction.Forward
                ? yield* this.event.shouldRollupChildEarlyStartDate(child)
                : yield* this.event.shouldRollupChildLateStartDate(child);
        }
        *calculateMinChildrenStartDate() {
            let result = MAX_DATE;
            const subEventsIterator = yield* this.event.subEventsIterable();
            for (const childEvent of subEventsIterator) {
                if (!(yield* this.shouldRollupChildStartDate(childEvent)))
                    continue;
                let childDate;
                if ((yield childEvent.$.manuallyScheduled) && (yield* childEvent.hasSubEvents())) {
                    childDate = yield this.pick(childEvent).$.minChildrenStartDate;
                }
                childDate = childDate || (yield this.pick(childEvent).$.startDate);
                if (childDate && childDate < result)
                    result = childDate;
            }
            return result.getTime() - MAX_DATE.getTime() ? result : null;
        }
        /**
         * The method defines whether the provided child event should be
         * taken into account when calculating this summary event [[earlyEndDate]].
         * Child events roll up their [[earlyEndDate]] values to their summary tasks.
         * So a summary task [[earlyEndDate]] gets equal to its maximal child [[earlyEndDate]].
         *
         * If the method returns `true` the child event is taken into account
         * and if the method returns `false` it's not.
         * By default, the method returns `true` to include all child events data.
         * @param child Child event to consider.
         * @returns `true` if the provided event should be taken into account, `false` if not.
         */
        *shouldRollupChildEndDate(child) {
            return this.direction === Direction.Forward
                ? yield* this.event.shouldRollupChildEarlyEndDate(child)
                : yield* this.event.shouldRollupChildLateEndDate(child);
        }
        *calculateMaxChildrenEndDate() {
            let result = MIN_DATE;
            const subEventsIterator = yield* this.event.subEventsIterable();
            for (const childEvent of subEventsIterator) {
                if (!(yield* this.shouldRollupChildEndDate(childEvent)))
                    continue;
                let childDate;
                if ((yield childEvent.$.manuallyScheduled) && (yield* childEvent.hasSubEvents())) {
                    childDate = yield this.pick(childEvent).$.maxChildrenEndDate;
                }
                childDate = childDate || (yield this.pick(childEvent).$.endDate);
                if (childDate && childDate > result)
                    result = childDate;
            }
            return result.getTime() - MIN_DATE.getTime() ? result : null;
        }
        *calculateStartDate() {
            let date;
            // Manually scheduled task treat its current start date as its early start date
            // in case of forward scheduling.
            // Late dates in that case are calculated the same way it happens for automatic tasks
            if ((yield this.event.$.manuallyScheduled) && (yield this.event.$.effectiveDirection).direction === this.direction) {
                date = yield this.event.$.startDate;
            }
            // Parent task calculate its early start date as minimal early start date of its children
            else if (yield* this.event.hasSubEvents()) {
                date = yield this.$.minChildrenStartDate;
            }
            else if (!(yield* this.isConstrained())) {
                date = yield this.event.$.startDate;
            }
            else {
                let effectiveInterval = yield* this.doCalculateEffectiveStartDateInterval();
                if (effectiveInterval === null) {
                    return null;
                }
                else if (effectiveInterval.isIntervalEmpty()) {
                    const res = yield* this.event.onEmptyEffectiveInterval('start', this);
                    if (res.kind === 'return') {
                        return res.value;
                    }
                    else if (res.kind === 'resolved') {
                        effectiveInterval = res.value;
                    }
                }
                else {
                    yield* this.event.onNonEmptyEffectiveInterval('start', this);
                }
                date = this.direction === Direction.Forward
                    ? isDateFinite(effectiveInterval.startDate) ? effectiveInterval.startDate : null
                    : isDateFinite(effectiveInterval.endDate) ? effectiveInterval.endDate : null;
            }
            return yield* this.maybeSkipNonWorkingTime(date, true);
        }
        *calculateEndDate() {
            let date;
            // Manually scheduled task treat its current end date as its early end date
            // in case of forward scheduling.
            // Late dates in that case are calculated the same way it happens for automatic tasks
            if ((yield this.event.$.manuallyScheduled) && (yield this.event.$.effectiveDirection).direction === this.direction) {
                date = yield this.event.$.endDate;
            }
            // Parent task calculate its early end date as maximum early end date of its children
            else if (yield* this.event.hasSubEvents()) {
                date = yield this.$.maxChildrenEndDate;
            }
            else if (!(yield* this.isConstrained())) {
                date = yield this.event.$.endDate;
            }
            else {
                let effectiveInterval = yield* this.doCalculateEffectiveEndDateInterval();
                if (effectiveInterval === null) {
                    return null;
                }
                else if (effectiveInterval.isIntervalEmpty()) {
                    const res = yield* this.event.onEmptyEffectiveInterval('end', this);
                    if (res.kind === 'return') {
                        return res.value;
                    }
                    else if (res.kind === 'resolved') {
                        effectiveInterval = res.value;
                    }
                }
                else {
                    yield* this.event.onNonEmptyEffectiveInterval('end', this);
                }
                date = this.direction === Direction.Forward
                    ? isDateFinite(effectiveInterval.startDate) ? effectiveInterval.startDate : null
                    : isDateFinite(effectiveInterval.endDate) ? effectiveInterval.endDate : null;
            }
            return yield* this.maybeSkipNonWorkingTime(date, false);
        }
        *isConstrained() {
            const eventStartDateIntervals = yield this.event.$.startDateConstraintIntervals;
            const eventEndDateIntervals = yield this.event.$.endDateConstraintIntervals;
            const startDateIntervals = yield this.$.startDateConstraintIntervals;
            const endDateIntervals = yield this.$.endDateConstraintIntervals;
            return startDateIntervals?.length > 0
                || endDateIntervals?.length > 0
                || eventStartDateIntervals?.length > 0
                || eventEndDateIntervals?.length > 0;
        }
        *isFirstPass() {
            const projectDirection = yield this.getProject().$.effectiveDirection;
            // this condition indicates 1st pass in the calculation ("early" for forward project, "late" for backward)
            return projectDirection.direction === this.direction;
        }
    }
    __decorate([
        field({ lazy: true })
    ], ConstrainedScheduleMixin.prototype, "minChildrenStartDate", void 0);
    __decorate([
        field({ lazy: true })
    ], ConstrainedScheduleMixin.prototype, "maxChildrenEndDate", void 0);
    __decorate([
        field({ lazy: true })
    ], ConstrainedScheduleMixin.prototype, "startDateConstraintIntervals", void 0);
    __decorate([
        field({ lazy: true })
    ], ConstrainedScheduleMixin.prototype, "endDateConstraintIntervals", void 0);
    __decorate([
        calculate('startDateConstraintIntervals')
    ], ConstrainedScheduleMixin.prototype, "calculateStartDateConstraintIntervals", null);
    __decorate([
        calculate('endDateConstraintIntervals')
    ], ConstrainedScheduleMixin.prototype, "calculateEndDateConstraintIntervals", null);
    __decorate([
        calculate('minChildrenStartDate')
    ], ConstrainedScheduleMixin.prototype, "calculateMinChildrenStartDate", null);
    __decorate([
        calculate('maxChildrenEndDate')
    ], ConstrainedScheduleMixin.prototype, "calculateMaxChildrenEndDate", null);
    __decorate([
        calculate('startDate')
    ], ConstrainedScheduleMixin.prototype, "calculateStartDate", null);
    __decorate([
        calculate('endDate')
    ], ConstrainedScheduleMixin.prototype, "calculateEndDate", null);
    return ConstrainedScheduleMixin;
}) {
}
