import ArrayHelper from '../../../Core/helper/ArrayHelper.js';
import DayAvailabilityStore from '../../data/calendareditor/DayAvailabilityStore.js';
import DateHelper from '../../../Core/helper/DateHelper.js';
import CalendarEditorIntervalBaseModel from './CalendarEditorIntervalBaseModel.js';
/**
 * @module SchedulerPro/model/calendareditor/CalendarEditorWeekModel
 */
const MIN_DATE = DateHelper.getTime(0);
/**
 * This class represents a calendar week - a special interval providing daily working time settings.
 * The class is used by the calendar editor.
 *
 * @extends SchedulerPro/model/calendareditor/CalendarEditorIntervalBaseModel
 * @internal
 */
export default class CalendarEditorWeekModel extends CalendarEditorIntervalBaseModel {
    static $name = 'CalendarEditorWeekModel';
    static fields = [
        /**
         * Availability data.
         * @field {SchedulerPro.data.calendareditor.DayAvailabilityStore} availability
         */
        {
            name       : 'availability',
            type       : 'store',
            // keep id values to better use syncDataOnLoad
            usesId     : true,
            storeClass : DayAvailabilityStore
        }
    ];
    static errors = {
        errorStartAfterEnd           : 'errorStartAfterEnd',
        errorInvalidWeekAvailability : 'errorInvalidWeekAvailability',
        errorNoWeekAvailability      : 'errorNoWeekAvailability'
    };
    get isWeek() {
        return true;
    }
    get isOverride() {
        return this.startDate || this.endDate;
    }
    initAvailabilityStore(config) {
        // put default empty availability records
        config.data = config.data || ArrayHelper.populate(7, index => ({ id : index }));
        config.syncDataOnLoad = true;
    }
    /**
     * The methods reports whether the record has availability specified either
     * for the provided day (if provided) or for any day.
     * @param {Number} dayId Zero based day index.
     * @returns {Boolean} Returns `true` if there is an availability specified and `false` otherwise.
     */
    hasAvailability(dayId, availability = this.availability) {
        // check if the provided day got availability
        if (dayId !== undefined) {
            const dayAvailability = availability.getById(dayId);
            return dayAvailability.availability.count;
        }
        // some day got availability specified
        return availability.allRecords.some(dayAvailability => this.hasAvailability(dayAvailability.id));
    }
    static dayName(day) {
        switch (day) {
            case 0: return 'Sun';
            case 1: return 'Mon';
            case 2: return 'Tue';
            case 3: return 'Wed';
            case 4: return 'Thu';
            case 5: return 'Fri';
            case 6: return 'Sat';
            default: throw new Error('Unknown day value passed: ' + day);
        }
    }
    static buildDaysRule(days) {
        if (days.length && days.length < 7) {
            const daysText = days.map(day => this.dayName(day)).join(',');
            return `on ${daysText}`;
        }
        return '';
    }
    static buildSolidDayIntervals(days, intervalData = {}, sourceIntervals) {
        const
            intervals = [],
            // clone original array
            tmpDays = days.slice();
        // sort days
        tmpDays.sort((a, b) => a - b);
        // now sort them to change "Sun],[Fri,Sat" to "[Fri,Sat,Sun]"
        if (tmpDays[0] === 0 && tmpDays[tmpDays.length - 1] === 6) {
            let firstGapDay, prevDay;
            // find 1st gap
            for (const day of tmpDays) {
                if (day - prevDay > 1) {
                    firstGapDay = day;
                    break;
                }
                prevDay = day;
            }
            // sort days so the after the gap will be first entry
            tmpDays.sort((a, b) => {
                const
                    valueA = a < firstGapDay ? (a + 10) : a,
                    valueB = b < firstGapDay ? (b + 10) : b;
                return valueA - valueB;
            });
        }
        let lastDay, lastInterval;
        for (const day of tmpDays) {
            // if first interval or there is a gap w/ previous day
            if (!lastInterval || (day - lastDay > 1)) {
                // if have a started interval - close it
                if (lastInterval) {
                    lastInterval.recurrentEndDate = this.buildDaysRule([
                        // close it w/ previous day end
                        lastDay === 6 ? 0 : ++lastDay
                    ]);
                }
                const srcInterval = sourceIntervals.shift()?.toJSON() || {};
                // Start new non-working interval
                intervals.push(lastInterval = {
                    ...srcInterval,
                    recurrentStartDate : this.buildDaysRule([day]),
                    ...intervalData
                });
            }
            lastDay = day;
        }
        // close opened interval
        if (lastInterval) {
            lastInterval.recurrentEndDate = this.buildDaysRule([
                // close it w/ previous day end
                lastDay === 6 ? 0 : ++lastDay
            ]);
        }
        return intervals;
    }
    buildRawIntervals() {
        const
            {
                name,
                startDate,
                endDate,
                availability : dailyAvailability,
                calendar,
                intervals : sourceIntervals,
                compositeCode
            }                            = this,
            { unspecifiedTimeIsWorking } = calendar,
            self                         = this.constructor,
            nonWorkingDays               = [],
            daysByTimeRanges             = new Map(),
            intervals                    = [],
            tmpIntervals                 = sourceIntervals ? [...sourceIntervals] : [];
        if (dailyAvailability) {
            // iterate days
            for (const { id, availability } of dailyAvailability) {
                // if the day has availability
                if (availability?.count) {
                    const ranges = unspecifiedTimeIsWorking ? availability.invertRanges() : availability.getRange();
                    for (const { startDate, endDate } of ranges) {
                        const key = DateHelper.format(startDate, 'HH:mm') + '-' + DateHelper.format(endDate, 'HH:mm');
                        if (!daysByTimeRanges.get(key)) {
                            daysByTimeRanges.set(key, []);
                        }
                        daysByTimeRanges.get(key).push(id);
                    }
                }
                else {
                    nonWorkingDays.push(id);
                }
            }
            // If collected some non-working days
            if (nonWorkingDays.length && unspecifiedTimeIsWorking) {
                intervals.push(...self.buildSolidDayIntervals(nonWorkingDays, {
                    type      : 'Week',
                    isWorking : false,
                    name,
                    startDate,
                    endDate,
                    compositeCode
                }, tmpIntervals));
            }
            if (daysByTimeRanges.size) {
                daysByTimeRanges.forEach((days, key) => {
                    const [startTime, endTime] = key.split('-');
                    if (startTime === '00:00' && endTime === '00:00') {
                        // unspecified time intervals is supposed to do this job so bail out
                        if (!unspecifiedTimeIsWorking) {
                            intervals.push(...self.buildSolidDayIntervals(days, {
                                type      : 'Week',
                                isWorking : true,
                                name,
                                startDate,
                                endDate,
                                compositeCode
                            }, tmpIntervals));
                        }
                    }
                    else {
                        const
                            startDateDaysRule  = self.buildDaysRule(days),
                            recurrentStartDate = `${startDateDaysRule} at ${startTime}`.trim(),
                            recurrentEndDate   = endTime === '00:00' ? null : `${startDateDaysRule} at ${endTime}`.trim(),
                            srcInterval        = tmpIntervals.shift()?.toJSON() || {};
                        intervals.push({
                            ...srcInterval,
                            type      : 'Week',
                            isWorking : !unspecifiedTimeIsWorking,
                            recurrentStartDate,
                            recurrentEndDate,
                            name,
                            startDate,
                            endDate,
                            compositeCode
                        });
                    }
                });
            }
        }
        return intervals;
    }
    // Makes a sane copy of calendar by removing exceptions
    async buildCalendarSaneCopy(calendar, storeClass) {
        const
            { unspecifiedTimeIsWorking, name, parent } = calendar,
            // build the calendar copy
            result = new calendar.constructor({ unspecifiedTimeIsWorking, name }),
            // Make a CalendarEditorStore so it would process the calendar content
            newStore = new storeClass({
                calendar,
                autoPull : false,
                autoPush : false
            });
        // process calendar intervals
        await newStore.pullFromCalendar();
        // remove exception intervals
        newStore.remove(newStore.query(r => r.isException), true);
        // save left intervals to the copy we've made
        await newStore.pushToCalendar(result);
        // cleanup
        newStore.destroy();
        // specify parent manually to enable the calendar inheritance
        if (parent && !parent.isRoot && !parent.isDestroyed) {
            result.parent = await this.buildCalendarSaneCopy(parent, storeClass);
        }
        return result;
    }
    async buildCalendarCopy(storeClass) {
        const
            { calendar } = this,
            { unspecifiedTimeIsWorking, name, parent } = calendar,
            result = new calendar.constructor({ unspecifiedTimeIsWorking, name });
        // If "type" is not defined on the raw interval means we deal with old data.
        // So collect parent(s) week availability too so it could ingest this interval,
        // That's the old calendar inheritance way.
        if (!this.isTypeDefined && parent && !parent.isRoot) {
            // specify parent manually to enable the calendar inheritance
            result.parent = await this.buildCalendarSaneCopy(parent, storeClass);
        }
        return result;
    }
    async collectAvailability(store) {
        const
            intervalCopies       = [...this.intervals].map(interval => interval.copy()),
            dayAvailabilityIndex = {},
            ticks                = [],
            // make a dummy calendar to collect availability from
            dummyCalendar        = await this.buildCalendarCopy(store.constructor);
        let startDate = this.startDate || MIN_DATE;
        // If startDate is not 00:00
        if (!DateHelper.isMidnight(startDate)) {
            startDate = DateHelper.constrain(
                DateHelper.getStartOfNextDay(startDate, true),
                startDate,
                this.endDate
            );
        }
        let tickStartDate = startDate, tickEndDate;
        for (let i = 0; i < 7; i++) {
            // week day index
            const day = tickStartDate.getDay();
            dayAvailabilityIndex[day] = {
                id           : day,
                availability : null
            };
            tickEndDate = DateHelper.clearTime(DateHelper.add(tickStartDate, 1, 'day'));
            ticks.push({ startDate : tickStartDate, endDate : tickEndDate, prioritySortValue : -1 });
            // proceed to next day
            tickStartDate = tickEndDate;
        }
        dummyCalendar.addIntervals([...ticks, ...intervalCopies]);
        let lastRange;
        dummyCalendar.forEachAvailabilityInterval(
            { startDate, endDate : ticks[ticks.length - 1].endDate },
            (startDate, endDate, calendarCacheInterval) => {
                // if the calendar has working interval for that period
                if (calendarCacheInterval.getIsWorking()) {
                    // put that time range
                    const dayData = dayAvailabilityIndex[startDate.getDay()];
                    dayData.availability = dayData.availability || [];
                    startDate = DateHelper.format(startDate, 'HH:mm');
                    endDate   = DateHelper.format(endDate, 'HH:mm');
                    if (dayData.availability.length && lastRange.endDate === startDate) {
                        lastRange.endDate = endDate;
                    }
                    else {
                        lastRange = { startDate, endDate };
                        // put that time range
                        dayData.availability.push(lastRange);
                    }
                }
            }
        );
        return Object.values(dayAvailabilityIndex);
    }
    async afterRawIntervalsProcessed(store) {
        this.availability = await this.collectAvailability(store);
    }
    get isValid() {
        return !this.getErrors();
    }
    getErrors(values = {}) {
        const
            {
                availability = this.availability,
                startDate = this.startDate,
                endDate = this.endDate
            } = values,
            { errors } = this.constructor,
            result = [];
        // startDate is not allowed to be later than endDate
        if (startDate && endDate && startDate > endDate) {
            result.push(errors.errorStartAfterEnd);
        }
        // Each instance has 7 of these (one for each week day)
        // They in turn, has a number of AvailabilityRangeModels
        if (availability.some(day => !day.availability.isValid)) {
            result.push(errors.errorInvalidWeekAvailability);
        }
        else if (!this.isOverride && !this.hasAvailability(undefined, availability)) {
            result.push(errors.errorNoWeekAvailability);
        }
        return result.length ? result : null;
    }
}
CalendarEditorWeekModel._$name = 'CalendarEditorWeekModel';