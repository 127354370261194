var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Mixin } from '../../../../ChronoGraph/class/BetterMixin.js';
import { calculate } from "../../../../ChronoGraph/replica/Entity.js";
import { ConstrainedScheduleMixin } from "./ConstrainedScheduleMixin.js";
/**
 * This mixin implements scheduling children by a delay from their parent event.
 */
export class DelayFromParentScheduleMixin extends Mixin([
    ConstrainedScheduleMixin
], (base) => {
    const superProto = base.prototype;
    class DelayFromParentScheduleMixin extends base {
        // need to have at least one "entity" decorator in the class, otherwise the entity infrastructure won't be setup
        *calculateEndDateConstraintIntervals() {
            const intervals = yield* superProto.calculateEndDateConstraintIntervals.call(this);
            if (this.event.delayFromParent != null) {
                const parentEvent = yield this.event.$.parentEvent;
                const parentEarlyEndConstraints = yield parentEvent.earlySchedule.$.endDateConstraintIntervals;
                intervals.push(...parentEarlyEndConstraints);
            }
            return intervals;
        }
    }
    __decorate([
        calculate('endDateConstraintIntervals')
    ], DelayFromParentScheduleMixin.prototype, "calculateEndDateConstraintIntervals", null);
    return DelayFromParentScheduleMixin;
}) {
}
