import Widget from '../../../Core/widget/Widget.js';
import DateHelper from '../../../Core/helper/DateHelper.js';
/**
 * @module SchedulerPro/widget/calendareditor/CalendarEditorDateInfo
 */
/**
 * A widget displaying availability info specified by the provided {@link #config-calendar}
 * for the provided {@link #config-date}.
 * The widget is used by {@link SchedulerPro/widget/CalendarEditor the calendar editor}.
 * @extends Core/widget/Widget
 * @classtype calendareditordateinfo
 * @internal
 * @widget
 */
export default class CalendarEditorDateInfo extends Widget {
    static $name = 'CalendarEditorDateInfo';
    static type = 'calendareditordateinfo';
    // region Configs
    static configurable = {
        // this widget does not provide a value
        defaultBindProperty : null,
        /**
         * A date to display information for.
         * @config {Date}
         */
        date                : null,
        /**
         * A calendar to collect info from.
         * @config {SchedulerPro.model.CalendarModel}
         */
        calendar            : null,
        /**
         * A store to collect calendar info from.
         * @config {SchedulerPro.data.calendareditor.CalendarEditorStore}
         */
        store               : null,
        /**
         * A {@link Core/helper/DateHelper} format string to use to display dates.
         * @config {String}
         * @default
         */
        dateFormat          : 'L',
        /**
         * A {@link Core/helper/DateHelper} format string to use to display time values.
         * @config {String}
         * @default
         */
        timeFormat          : 'LT',
        refreshTimeout : 100
    };
    updateStore() {
        this.detachListeners('store');
        this.store?.ion({
            name                : 'store',
            change              : 'onCalendarStoreDataChange',
            calendarBumpVersion : 'onCalendarBumpVersion',
            thisObj             : this
        });
    }
    // endregion Configs
    // region Rendering
    compose() {
        const
            me = this,
            { date, calendar, store, dateFormat, timeFormat } = me,
            result = {
                // eslint-disable-next-line bryntum/no-listeners-in-lib
                listeners : {
                    click    : 'internalOnIntervalClick',
                    delegate : '.b-interval'
                }
            };
        // if got all mandatory parameters provided
        if (date && calendar && store) {
            const
                intervalsInfo = calendar.getDayIntervals(date),
                timeRanges    = [],
                intervals     = [],
                intervalSet   = new Set();
            let prevStartDate, prevEndDate, isWorking;
            for (const [startDate, endDate, interval] of intervalsInfo) {
                if (interval.isWorking) {
                    // collect time ranges ..after merging them (calendar doesn't do that)
                    if (prevEndDate && startDate <= prevEndDate) {
                        prevEndDate = endDate;
                    }
                    else {
                        if (prevEndDate) {
                            timeRanges.push([prevStartDate, prevEndDate]);
                        }
                        prevStartDate = startDate;
                        prevEndDate = endDate;
                    }
                    isWorking = true;
                }
                if (interval.getCalendar() !== calendar) {
                    intervalSet.add(interval.getCalendar());
                }
                else {
                    intervalSet.add(
                        store.getRecordByRawInterval(interval.mainInterval || interval)
                    );
                }
            }
            if (prevEndDate) {
                timeRanges.push([prevStartDate, prevEndDate]);
            }
            intervals.push(...[...intervalSet].filter(i => i));
            result.children = [
                {
                    tag   : 'span',
                    class : 'b-date-text',
                    text  : DateHelper.format(date, dateFormat)
                },
                // If working - display availability time
                ...(
                    isWorking ? [
                        me.L('L{isWorking}'),
                        {
                            tag      : 'ul',
                            class    : 'b-time-ranges',
                            children : timeRanges.map(([start, end]) => ({
                                tag  : 'li',
                                text : me.L('L{rangeTpl}')(
                                    DateHelper.format(start, timeFormat),
                                    DateHelper.format(end, timeFormat)
                                )
                            }))
                        }
                    ] : [
                        me.L('L{isNotWorking}')
                    ]
                ),
                // intervals making the date working/non-working
                ...(
                    intervals.length ? [
                        me.L('L{basedOn}'),
                        {
                            tag      : 'ul',
                            class    : 'b-intervals',
                            children : intervals.map(interval => ({
                                tag   : 'li',
                                class : {
                                    'b-icon'     : 1,
                                    'b-interval' : !interval.isCalendarModel,
                                    'b-calendar' : interval.isCalendarModel,
                                    ...(interval.cls ? { [interval.cls] : 1 } : {})
                                },
                                style : {
                                    '--interval-badge-color' : interval.color
                                },
                                dataset : {
                                    interval : interval.id
                                },
                                text : (interval.isCalendarModel ? interval.name + ' (parent calendar)'
                                    : interval.name) || '<Unnamed>'
                            }))
                        }
                    // If no intervals found consider the calendar uses its defaults for the date
                    ] : [
                        ' ' + me.L('L{byDefault}')
                    ]
                )
            ];
        }
        return result;
    }
    scheduleRecompose() {
        if (!this.hasTimeout('recompose')) {
            this.setTimeout({
                name  : 'recompose',
                fn    : this.recompose,
                delay : this.recomposeTimeout
            });
        }
    }
    // endregion Rendering
    // region Listeners
    onCalendarStoreDataChange() {
        this.scheduleRecompose();
    }
    onCalendarBumpVersion() {
        this.scheduleRecompose();
    }
    internalOnIntervalClick(event) {
        // get clicked record
        const interval = this.store.getById(event.target.dataset.interval);
        if (interval) {
            this.trigger('intervalClick', { ...event, interval });
        }
    }
    onLocalized() {
        this.scheduleRecompose();
    }
    // endregion Listeners
}
// Register this widget type with its Factory
CalendarEditorDateInfo.initClass();
CalendarEditorDateInfo._$name = 'CalendarEditorDateInfo';