import GridFeatureManager from '../../../Grid/feature/GridFeatureManager.js';
import PrintMixin from '../../../Grid/feature/export/mixin/PrintMixin.js';
import PdfExport from './PdfExport.js';
/**
 * @module Scheduler/feature/export/Print
 */
/**
 * Allows printing Scheduler contents using browser print dialog.
 *
 * This feature is based on {@link Scheduler.feature.export.PdfExport} with only difference that instead of sending
 * request to a backend it renders content to an IFrame element and requests print dialog for it. For more details about
 * preparing HTML for printing, please refer to the {@link Scheduler.feature.export.PdfExport} docs.
 *
 * ## Usage
 *
 * ```javascript
 * const scheduler = new Scheduler({
 *     features : {
 *         print : true
 *     }
 * })
 *
 * // Opens popup allowing to customize print settings
 * scheduler.features.print.showPrintDialog();
 *
 * // Simple print
 * scheduler.features.print.print({
 *     columns : scheduler.columns.map(c => c.id)
 * });
 * ```
 *
 * This feature is **disabled** by default.
 * For info on enabling it, see {@link Grid/view/mixin/GridFeatures}.
 *
 * @extends Scheduler/feature/export/PdfExport
 * @mixes Grid/feature/export/mixin/PrintMixin
 *
 * @demo Scheduler/print
 * @classtype print
 * @feature
 *
 * @typings Grid.feature.export.Print -> Grid.feature.export.GridPrint
 */
export default class Print extends PrintMixin(PdfExport) {
    /**
     * @hideConfigs clientURL, exportServer, fetchOptions, fileFormat, fileName, openAfterExport, openInNewTab, sendAsBinary
     */
    /**
     * @hideFunctions processExportContent, receiveExportContent, showExportDialog
     */
    static $name = 'Print';
};
Print._$name = 'Print'; GridFeatureManager.registerFeature(Print, false, 'Scheduler');
