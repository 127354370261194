var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Mixin } from '../../../../ChronoGraph/class/BetterMixin.js';
import { calculate } from "../../../../ChronoGraph/replica/Entity.js";
import { ConstraintIntervalSide, Direction } from '../../../scheduling/Types.js';
import { ConstrainedLateScheduleMixin } from "./ConstrainedLateScheduleMixin.js";
export class GanttProjectScheduleMixin extends Mixin([
    ConstrainedLateScheduleMixin
], (base) => {
    const superProto = base.prototype;
    class GanttProjectScheduleMixin extends base {
        // need to have at least one "entity" decorator in the class, otherwise the entity infrastructure won't be setup
        *calculateStartDateConstraintIntervals() {
            const intervals = yield* superProto.calculateStartDateConstraintIntervals.call(this);
            if (this.direction === Direction.Forward) {
                const direction = yield this.event.$.effectiveDirection;
                if (direction.direction === Direction.Forward) {
                    const startDate = yield this.event.$.startDate;
                    startDate && intervals.push(this.event.projectConstraintIntervalClass.new({
                        owner: this.event,
                        side: ConstraintIntervalSide.Start,
                        startDate
                    }));
                }
                else if (direction.direction === Direction.Backward) {
                    const endDate = yield this.event.$.endDate;
                    if (endDate) {
                        const startDate = yield this.event.latePreSchedule.$.startDate;
                        startDate && intervals.push(this.event.projectConstraintIntervalClass.new({
                            owner: this.event,
                            side: ConstraintIntervalSide.Start,
                            startDate
                        }));
                    }
                }
            }
            return intervals;
        }
        // need to have at least one "entity" decorator in the class, otherwise the entity infrastructure won't be setup
        *calculateEndDateConstraintIntervals() {
            const intervals = yield* superProto.calculateEndDateConstraintIntervals.call(this);
            if (this.direction === Direction.Backward) {
                const direction = yield this.event.$.effectiveDirection;
                if (direction.direction === Direction.Forward) {
                    const startDate = yield this.event.$.startDate;
                    if (startDate) {
                        const endDate = yield this.event.earlyPreSchedule.$.endDate;
                        endDate && intervals.push(this.event.projectConstraintIntervalClass.new({
                            owner: this.event,
                            side: ConstraintIntervalSide.End,
                            endDate
                        }));
                    }
                }
                else if (direction.direction === Direction.Backward) {
                    const endDate = yield this.event.$.endDate;
                    endDate && intervals.push(this.event.projectConstraintIntervalClass.new({
                        owner: this.event,
                        side: ConstraintIntervalSide.End,
                        endDate
                    }));
                }
            }
            return intervals;
        }
    }
    __decorate([
        calculate('startDateConstraintIntervals')
    ], GanttProjectScheduleMixin.prototype, "calculateStartDateConstraintIntervals", null);
    __decorate([
        calculate('endDateConstraintIntervals')
    ], GanttProjectScheduleMixin.prototype, "calculateEndDateConstraintIntervals", null);
    return GanttProjectScheduleMixin;
}) {
}
