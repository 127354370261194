import TimelineSummary from './TimelineSummary.js';
import GridFeatureManager from '../../Grid/feature/GridFeatureManager.js';
import ObjectHelper from '../../Core/helper/ObjectHelper.js';
/**
 * @module Scheduler/feature/Summary
 */
/**
 * Defines a summary, used by the Summary and GroupSummary features.
 * @typedef SchedulerSummaryOptions
 * @property {String} label Label for the summary
 * @property {Function} renderer Function to calculate and render the summary value
 * @property {Date} renderer.startDate
 * @property {Date} renderer.endDate
 * @property {Scheduler.data.EventStore} renderer.eventStore
 * @property {Scheduler.data.ResourceStore} renderer.resourceStore
 * @property {Scheduler.model.EventModel[]} events
 * @property {Scheduler.model.ResourceModel[]} resources
 * @property {Core.data.Model} groupRecord
 * @property {String} groupField
 * @property {Object} groupValue
 */
// noinspection JSClosureCompilerSyntax
/**
 * A special version of the Grid Summary feature. This feature displays a summary row in the grid footer.
 * For regular columns in the locked section - specify type of summary on columns, available types are:
 * <dl class="wide">
 * <dt>sum <dd>Sum of all values in the column
 * <dt>add <dd>Alias for sum
 * <dt>count <dd>Number of rows
 * <dt>countNotEmpty <dd>Number of rows containing a value
 * <dt>average <dd>Average of all values in the column
 * <dt>function <dd>A custom function, used with store.reduce. Should take arguments (sum, record)
 * </dl>
 * Columns can also specify a summaryRender to format the calculated sum.
 *
 * To summarize events, either provide a {@link #config-renderer} or use {@link #config-summaries}
 *
 * This feature is <strong>disabled</strong> by default.
 *
 * {@inlineexample Scheduler/feature/Summary.js}
 *
 * @demo Scheduler/summary
 *
 * @extends Scheduler/feature/TimelineSummary
 * @classtype summary
 * @feature
 *
 * @typings Grid.feature.Summary -> Grid.feature.GridSummary
 */
export default class Summary extends TimelineSummary {
    //region Config
    static get $name() {
        return 'Summary';
    }
    static get configurable() {
        return {
            /**
             * Array of summary configs which consists of a label and a {@link #config-renderer} function
             *
             * ```javascript
             * new Scheduler({
             *     features : {
             *         summary : {
             *             summaries : [
             *                 {
             *                     label : 'Label',
             *                     renderer : ({ startDate, endDate, eventStore, resourceStore, events, element }) => {
             *                         // return display value
             *                         returns '<div>Renderer output</div>';
             *                     }
             *                 }
             *             ]
             *         }
             *     }
             * });
             * ```
             *
             * @config {SchedulerSummaryOptions[]}
             */
            summaries : null,
            /**
             * Renderer function for a single time axis tick. Should calculate a sum and return HTML as a result.
             *
             * ```javascript
             * new Scheduler({
             *     features : {
             *         summary : {
             *             renderer : ({ startDate, endDate, eventStore, resourceStore, events, element }) => {
             *                 // return display value
             *                 returns '<div>Renderer output</div>';
             *             }
             *         }
             *     }
             * });
             * ```
             *
             * @param {Object} context Rendering context object
             * @param {Date} context.startDate Tick start date
             * @param {Date} context.endDate Tick end date
             * @param {Scheduler.model.EventModel[]} context.events Events which belong to the group
             * @param {Scheduler.data.EventStore} context.eventStore Event store
             * @param {Scheduler.data.ResourceStore} context.resourceStore Resource store
             * @param {HTMLElement} context.element Summary tick container
             * @returns {String} Html content
             * @config {Function}
             */
            renderer : null,
            /**
             * A config object for the {@link Grid.column.Column} used to contain the summary bar.
             * @config {GridColumnConfig}
             */
            verticalSummaryColumnConfig : null
        };
    }
    // Plugin configuration. This plugin chains some of the functions in Grid.
    static get pluginConfig() {
        const config = super.pluginConfig;
        config.chain.push('bindStore', 'updateEventStore', 'updateResourceStore');
        return config;
    }
    //endregion
    //region Init
    construct(scheduler, config) {
        const me = this;
        me.scheduler = scheduler;
        if (scheduler.isVertical) {
            scheduler.timeAxisSubGrid.resizable = false;
            config.hideFooters = true;
            scheduler.add(scheduler.createSubGrid('right'));
            me.summaryColumn = scheduler.columns.add(ObjectHelper.assign({
                filterable              : null,
                region                  : 'right',
                cellCls                 : 'b-grid-footer b-sch-summarybar',
                align                   : 'center',
                sortable                : false,
                editor                  : false,
                groupable               : false,
                htmlEncode              : false,
                cellMenuItems           : false,
                enableHeaderContextMenu : false,
                hidden                  : me.disabled
            }, me.verticalSummaryColumnConfig))[0];
        }
        super.construct(scheduler, config);
        // Feature might be run from Grid (in docs), should not crash
        if (scheduler.isSchedulerBase) {
            me.updateEventStore(scheduler.eventStore);
            me.updateResourceStore(scheduler.resourceStore);
        }
    }
    //endregion
    //region Render
    updateEventStore(eventStore) {
        this.detachListeners('summaryEventStore');
        eventStore.ion({
            name    : 'summaryEventStore',
            filter  : 'updateTimelineSummaries',
            thisObj : this
        });
    }
    updateResourceStore(resourceStore) {
        this.detachListeners('summaryResourceStore');
        resourceStore.ion({
            name    : 'summaryResourceStore',
            filter  : 'updateTimelineSummaries',
            thisObj : this
        });
    }
    /**
     * Updates summaries.
     * @private
     */
    updateTimelineSummaries() {
        const
            me                       = this,
            { client : timeline }    = me,
            { eventStore, timeAxis } = timeline,
            summaryContainer         = me.summaryBarElement,
            forResources             = (me.selectedOnly && timeline.selectedRecords.length)
                ? timeline.selectedRecords : timeline.resourceStore.records;
        if (summaryContainer && timeline.isEngineReady) {
            // group events by ticks info once here to avoid performance lags
            // should be inside `scheduler.isEngineReady` check to make sure all events were calculated
            // https://github.com/bryntum/support/issues/2977
            const eventsByTick = timeline.getResourcesEventsPerTick(forResources, ({ event }) => {
                return !eventStore.isFiltered || eventStore.records.includes(event);
            });
            Array.from(summaryContainer.children).forEach((element, i) => {
                const
                    tick   = timeAxis.getAt(i),
                    events = eventsByTick[i] || [];
                let html    = '',
                    tipHtml = `<header>${me.L('L{Summary for}', timeline.getFormattedDate(tick.startDate))}</header>`;
                me.summaries.forEach(config => {
                    const value     = config.renderer({
                            startDate     : tick.startDate,
                            endDate       : tick.endDate,
                            resourceStore : timeline.resourceStore,
                            eventStore,
                            events,
                            element
                        }),
                        valueHtml = value ? `<div class="b-timeaxis-summary-value">${value}</div>` : '';
                    if (me.summaries.length > 1 || value) {
                        html += valueHtml;
                    }
                    tipHtml += valueHtml ? (`<label>${config.label || ''}</label>` + valueHtml) : '';
                });
                element.innerHTML = html;
                // Only show tooltips for summary cells with content
                element._tipHtml  = html ? tipHtml : '';
            });
        }
    }
}
// Override Grids Summary with this improved version
Summary._$name = 'Summary'; GridFeatureManager.registerFeature(Summary, false, 'Scheduler');
