import StoreLazyLoadPlugin from './StoreLazyLoadPlugin.js';
/**
 * @module Core/data/plugin/AjaxStoreLazyLoadPlugin
 */
/**
 * Plugin for AjaxStore that handles lazy loading.
 * @plugin
 * @private
 * @extends Core/data/plugin/StoreLazyLoadPlugin
 */
export default class AjaxStoreLazyLoad extends StoreLazyLoadPlugin {
    static $name = 'AjaxStoreLazyLoadPlugin';
    // There's no merging of pluginConfigs
    static get pluginConfig() {
        const config = {};
        for (const prop in super.pluginConfig) {
            config[prop] = [...super.pluginConfig[prop]];
        }
        config.assign.push(
            'updateResponseTotalProperty',
            'updateResponseDataProperty',
            'beforePerformSort',
            'beforePerformFilter'
        );
        return config;
    };
    // AjaxStore's totalProperty will apply for lazyLoading
    updateResponseTotalProperty(name) {
        this.totalCountProperty = name;
    }
    // AjaxStore's dataProperty will apply for lazyLoading
    updateResponseDataProperty(name) {
        this.dataProperty = name;
    }
    // A sort operation will empty the store
    async beforePerformSort() {
        this.clearLoaded();
    }
    // A filter operation will empty the store
    async beforePerformFilter() {
        this.clearLoaded();
    }
    // Scoped to run on the client
    async requestData(params) {
        const
            {
                totalCountProperty,
                dataProperty
            }    = this.lazyLoad,
            resp = await this.internalLoad(params, 'Chunk', undefined, false);
        return {
            [totalCountProperty] : resp.json[totalCountProperty],
            [dataProperty]       : resp.data
        };
    }
    // Overridden to support params param
    async load(params) {
        const
            { client }          = this,
            originalStoreParams = client.params;
        if (params) {
            // Temporarily set provided params on the store
            client.params = {
                ...params,
                ...originalStoreParams
            };
        }
        await super.load();
        if (params) {
            // Reset the original params
            client.params = originalStoreParams;
        }
    }
};
AjaxStoreLazyLoad._$name = 'AjaxStoreLazyLoad';