import ColumnStore from '../../Grid/data/ColumnStore.js';
import PercentDoneColumn from './PercentDoneColumn.js';
/**
 * @module Gantt/column/PlannedPercentDoneColumn
 */
/**
 * A column representing the {@link Gantt/model/TaskModel#function-getPlannedPercentDone planned % complete}
 * of the task.
 *
 * Please refer to the {@link Gantt.model.TaskModel#function-getPlannedPercentDone} method's docs for details.
 *
 * Note, that if you would like to filter, sort, or group by this column, you need to define the
 * {@link Gantt.model.ProjectModel#field-statusDate} on the project and not as a config for this column.
 * This is because filtering/sorting/grouping code generally does not have access to the UI.
 *
 * @extends Gantt/column/PercentDoneColumn
 * @classtype plannedpercentdone
 * @column
 */
export default class PlannedPercentDoneColumn extends PercentDoneColumn {
    static $name = 'PlannedPercentDoneColumn';
    static type = 'plannedpercentdone';
    static fields = [
        /**
         * A reference date, to track the progress from. If not provided, the project's
         * {@link Gantt.model.ProjectModel#field-statusDate status date} is used.
         *
         * Note, that if you would like to filter, sort, or group by this column, you need to define the
         * {@link Gantt.model.ProjectModel#field-statusDate} on the project and not as this config.
         * This is because filtering/sorting/grouping code generally does not have access to the UI.
         *
         * @field {Date} statusDate
         */
        { name : 'statusDate', type : 'date', defaultValue : undefined },
        /**
         * An index (1-based) of the baseline version to be used for planned percent done calculation.
         * @field {Number} baselineVersion
         */
        { name : 'baselineVersion', type : 'number', defaultValue : 1 }
    ];
    static get defaults() {
        return {
            text     : 'L{plannedPercentDone}',
            unit     : '%',
            editor   : false,
            readOnly : true
        };
    }
    construct(data, store, meta, ...args) {
        super.construct(data, store, meta, ...args);
        this.grid.ion({
            projectChange : ({ project }) => this.attachToProject(project)
        });
        this.attachToProject(this.grid.project);
    }
    attachToProject(project) {
        this.detachListeners('project');
        project?.ion({
            name             : 'project',
            statusDateChange : 'onProjectStatusDateChange',
            thisObj          : this
        });
    }
    onProjectStatusDateChange() {
        if (this._statusDate === undefined) {
            this.grid.refreshColumn(this);
        }
    }
    updateStatusDate() {
        this.grid.refreshColumn(this);
    }
    formatValue(value, record) {
        const statusDate = this.statusDate ?? record.getProject()?.statusDate;
        value = statusDate ? record.getPlannedPercentDone(statusDate, this.baselineVersion) : 0;
        return record.getFormattedPercentDone(value);
    }
    get field() {
        return `baselines[${ this.baselineVersion - 1 }].plannedPercentDone`;
    }
    get sortable() {
        return this.statusDate ? false : this.get('sortable');
    }
    get filterable() {
        return this.statusDate ? false : this.get('filterable');
    }
    get groupable() {
        return this.statusDate ? false : this.get('groupable');
    }
}
ColumnStore.registerColumnType(PlannedPercentDoneColumn);
PlannedPercentDoneColumn._$name = 'PlannedPercentDoneColumn';