import Button from '../../../Core/widget/Button.js';
import Container from '../../../Core/widget/Container.js';
import ColorColumn from '../../../Grid/column/ColorColumn.js';
import Grid from '../../../Grid/view/Grid.js';
import CalendarEditorStore from '../../data/calendareditor/CalendarEditorStore.js';
/**
 * @module SchedulerPro/widget/calendareditor/CalendarEditorBaseTab
 */
/**
 * Base class for the {@link SchedulerPro/widget/CalendarEditor calendar editor}
 * {@link SchedulerPro/widget/calendareditor/CalendarEditorExceptionTab "Exceptions"} and
 * {@link SchedulerPro/widget/calendareditor/CalendarEditorWeekTab "Weeks"} tabs.
 *
 * @extends Core/widget/Container
 * @classtype calendareditorbasetab
 * @internal
 * @widget
 */
export default class CalendarEditorBaseTab extends Container {
    static $name = 'CalendarEditorBaseTab';
    static type = 'calendareditorbasetab';
    //region Configs
    static configurable = {
        /**
         * A store to collect calendar data from.
         * @config {SchedulerPro.data.calendareditor.CalendarEditorStore}
         */
        store        : null,
        defaultFocus : w => w.isField,
        items        : {
            grid : {
                type    : Grid.type,
                columns : [
                    {
                        type     : ColorColumn.type,
                        field    : 'color',
                        width    : 45,
                        minWidth : 45,
                        colors   : CalendarEditorStore.intervalColors
                    },
                    { field : 'name' }
                ],
                readOnly      : true,
                hideHeaders   : true,
                columnLines   : false,
                selectionMode : {
                    multiSelect : false
                },
                internalListeners : {
                    selectionChange : 'up.onGridSelect',
                    renderRows      : 'up.onGridRenderRows'
                },
                bbar : {
                    items : {
                        addButton : {
                            type : Button.type,
                            icon : 'b-fa-add',
                            cls  : {
                                'b-transparent' : 1
                            },
                            tooltip : 'Add record',
                            onClick : 'up.internalOnAddRecordClick'
                        },
                        removeButton : {
                            type : Button.type,
                            icon : 'b-fa-trash',
                            cls  : {
                                'b-transparent' : 1
                            },
                            tooltip : 'Remove record',
                            onClick : 'up.internalOnRemoveRecordClick'
                        }
                    }
                }
            },
            panel : {}
        }
    };
    updateStore(store) {
        this.widgetMap.grid.store = store;
    }
    updateRecord(record) {
        this._settingRecord = true;
        super.updateRecord(...arguments);
        this.widgetMap.panel.record = record;
        if (record) {
            this.widgetMap.grid.selectRow(record);
        }
        this._settingRecord = false;
    }
    //endregion Configs
    //region Constructor/destructor
    construct() {
        this._stores = [];
        super.construct(...arguments);
        this.widgetMap.grid.rowManager.ion({
            renderRow : 'onRowManagerRenderRow',
            thisObj   : this
        });
    }
    doDestroy() {
        // destroy stores we made
        CalendarEditorBaseTab.destroy(...this._stores);
        super.doDestroy();
    }
    //endregion Constructor/destructor
    //region Event listeners
    onRowManagerRenderRow({ row, record }) {
        // indicate invalid row
        row.assignCls({
            'b-invalid' : !record.isValid
        });
    }
    onGridSelect({ source }) {
        if (!this._settingRecord) {
            this.record = source.selectedRecord;
        }
        this.toggleRemoveButton();
    }
    onGridRenderRows({ source }) {
        // select first record if no selection
        if (!source.selectedRecord && source.store.first) {
            source.selectedRecord = source.store.first;
        }
    }
    internalOnAddRecordClick() {
        this.trigger('addRecordClick');
    }
    internalOnRemoveRecordClick() {
        this.trigger('removeRecordClick', { record : this.widgetMap.grid.selectedRecord });
    }
    //endregion Event listeners
    toggleRemoveButton(disabled) {
        const { grid } = this.widgetMap;
        return grid.widgetMap.removeButton.disabled = disabled || !grid.selectedRecord;
    }
}
CalendarEditorBaseTab.initClass();
CalendarEditorBaseTab._$name = 'CalendarEditorBaseTab';