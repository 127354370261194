import { Mixin } from '../../../../ChronoGraph/class/BetterMixin.js';
import { Direction } from "../../../scheduling/Types.js";
import { ConstrainedByDependenciesEventScheduleMixin } from "../scheduler_pro/ConstrainedByDependenciesEventScheduleMixin.js";
/**
 * The "late" schedule of the event, which is used when scheduling "backward" (as late as possible).
 */
export class ConstrainedLateScheduleMixin extends Mixin([ConstrainedByDependenciesEventScheduleMixin], (base) => {
    class ConstrainedLateScheduleMixin extends base {
        constructor() {
            super(...arguments);
            this.phase = 'phase1';
        }
        pick(event) {
            if (this.phase === 'phase1') {
                return (this.direction === Direction.Forward ? event.earlyPreSchedule : event.latePreSchedule);
            }
            else {
                return (this.direction === Direction.Forward ? event.earlySchedule : event.lateSchedule);
            }
        }
        pickOpposite(event) {
            if (this.phase === 'phase1') {
                return (this.direction === Direction.Forward ? event.latePreSchedule : event.earlyPreSchedule);
            }
            else {
                return (this.direction === Direction.Forward ? event.lateSchedule : event.earlySchedule);
            }
        }
        *pickPushingSchedule(pushingEvent, pushingDirection) {
            if (this.phase === 'phase1') {
                return this.pick(pushingEvent);
            }
            else {
                // possibly should be checking the parent event direction here, instead of the project's?
                // anyway, the idea is, that we should pick the "phase1" schedule only on one side
                // of the dependency with contradicting directions, for that, we compare the direction
                // with the direction of some "outer" context
                const projectDirection = yield this.getProject().$.effectiveDirection;
                if (this.direction === Direction.Forward) {
                    if (pushingDirection.direction === Direction.Backward && projectDirection.direction === Direction.Forward) {
                        return pushingEvent.latePreSchedule;
                    }
                    else {
                        return pushingEvent.earlySchedule;
                    }
                }
                else {
                    if (pushingDirection.direction === Direction.Forward && projectDirection.direction === Direction.Backward) {
                        return pushingEvent.earlyPreSchedule;
                    }
                    else {
                        return pushingEvent.lateSchedule;
                    }
                }
            }
        }
        *pickPushingStartDateIdentifier(pushingEvent) {
            return (yield* this.pickPushingSchedule(pushingEvent, yield pushingEvent.$.startDateDirection)).$.startDate;
        }
        *pickPushingEndDateIdentifier(pushingEvent) {
            return (yield* this.pickPushingSchedule(pushingEvent, yield pushingEvent.$.endDateDirection)).$.endDate;
        }
    }
    return ConstrainedLateScheduleMixin;
}) {
}
