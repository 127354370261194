import VerticalLayout from '../../Scheduler/eventlayout/VerticalLayout.js';
/**
 * @module SchedulerPro/eventlayout/ProVerticalLayout
 */
/**
 * Assists with event layout in vertical mode, add support for custom layout using `layoutFn`
 * @private
 * @mixes Scheduler/eventlayout/VerticalLayout
 */
export default class ProVerticalLayout extends VerticalLayout {
    // Custom layout
    applyLayout(events, resourceRecord, parentEventRecord) {
        const
            me            = this,
            { scheduler } = me,
            eventLayout   = scheduler.getEventLayout(resourceRecord, parentEventRecord);
        if (eventLayout.type === 'layoutFn') {
            const
                instanceMeta   = resourceRecord.instanceMeta(scheduler),
                newColumnWidth = eventLayout.layoutFn(events, resourceRecord, scheduler);
            if (newColumnWidth && newColumnWidth !== instanceMeta.columnWidth) {
                const oldWidth = instanceMeta.columnWidth ?? scheduler.getResourceWidth(resourceRecord);
                instanceMeta.columnWidth = newColumnWidth;
                // In order to update ColumnLines position
                scheduler.onVisibleResourceColumnChange(newColumnWidth, oldWidth);
            }
        }
        else {
            return super.applyLayout(events, resourceRecord, parentEventRecord);
        }
    }
}
ProVerticalLayout._$name = 'ProVerticalLayout';