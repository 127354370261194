import GridRowModel from '../../../Grid/data/GridRowModel.js';
import AvailabilityRangeStore from '../../data/calendareditor/AvailabilityRangeStore.js';
import '../../../Core/data/field/StoreDataField.js';
/**
 * @module SchedulerPro/model/calendareditor/DayAvailabilityModel
 */
/**
 * Represents a particular day availability and implements a record of
 * {@link SchedulerPro/data/calendareditor/DayAvailabilityStore} store
 * (a part of the {@link SchedulerPro/widget/CalendarEditor calendar editor} data level).
 *
 * @extends Grid/data/GridRowModel
 * @internal
 */
export default class DayAvailabilityModel extends GridRowModel {
    static $name = 'DayAvailabilityModel';
    static fields = [
        /**
         * Day identifier.
         * A zero-based index of the day (`0` - Sunday, `1` - Moday, `2` - Tuesday, `3` - Wedndesday,
         * `4` -Thursday, `5` - Friday and `6` - Saturday).
         * @field {String|Number} id
         */
        /**
         * Day availability.
         * @field {SchedulerPro.data.calendareditor.AvailabilityRangeStore} availability
         */
        {
            name       : 'availability',
            type       : 'store',
            storeClass : AvailabilityRangeStore,
            // force keeping id in the data to avoid redundant record removals
            usesId     : true
        }
    ];
    // Build availability field value
    getAvailabilityStoreValue(store) {
        const { allRecords } = store;
        return allRecords.filter(r => r.startDate || r.endDate).map(r => {
            const data = r.persistableData;
            if (!store.usesId) {
                delete data.id;
            }
            return data;
        });
    }
}
DayAvailabilityModel._$name = 'DayAvailabilityModel';