/**
 * @module Core/data/stm/state/CheckoutState
 */
import { throwInvalidMethodCall } from './StateBase.js';
import Registry from './Registry.js';
import { ReadyStateClass } from './ReadyState.js';
import { ACTION_QUEUE_PROP, REVISION_NAMES_PROP, STATE_PROP, TRANSACTION_PROP } from '../Props.js';
import Transaction from '../Transaction.js';
/**
 * STM checking-out state class.
 *
 * @internal
 */
export class CheckoutStateClass extends ReadyStateClass {
    canUndo(stm) {
        return false;
    }
    canRedo(stm) {
        return false;
    }
    onUndo(stm) {
        throwInvalidMethodCall();
    }
    onRedo(stm) {
        throwInvalidMethodCall();
    }
    onStartRevision(stm, revisionId) {
        const transaction = new Transaction({ title : revisionId });
        return [{
            [STATE_PROP]       : 'revisionrecordingstate',
            [TRANSACTION_PROP] : transaction
        }, () => {
            stm.notifyStoresAboutRevRecordingStart();
        }];
    }
    onStartTemporaryRevision(stm, revisionId, sourceTransaction) {
        const transaction = new Transaction({ title : revisionId });
        if (sourceTransaction) {
            transaction[ACTION_QUEUE_PROP] = sourceTransaction[ACTION_QUEUE_PROP].slice();
        }
        return [{
            [STATE_PROP]       : 'temporaryrevisionrecordingstate',
            [TRANSACTION_PROP] : transaction
        }, () => {
            stm.notifyStoresAboutTempRevRecordingStart();
        }];
    }
    onCheckoutToHead(stm) {
        stm.notifyStoresAboutCheckoutToHead({ revision : stm[REVISION_NAMES_PROP][stm[REVISION_NAMES_PROP].length - 1] });
        return stm.autoRecord ? 'autoreadystate' : 'readystate';
    }
    onStartTransaction(stm) {
        throwInvalidMethodCall();
    }
    onStopTransaction(stm) {
        throwInvalidMethodCall();
    }
    onStopTransactionDelayed(stm) {
        throwInvalidMethodCall();
    }
    onRejectTransaction(stm) {
        throwInvalidMethodCall();
    }
    onEnable(stm) {
        throwInvalidMethodCall();
    }
    onDisable(stm) {
        throwInvalidMethodCall();
    }
    onAutoRecordOn(stm) {
        throwInvalidMethodCall();
    }
    onAutoRecordOff(stm) {
        throwInvalidMethodCall();
    }
    onResetQueue(stm) {
        throwInvalidMethodCall();
    }
    // We should not do anything when we're checking out to different revision
    onModelUpdate()      {}
    onModelInsertChild() {}
    onModelRemoveChild() {}
    onStoreModelAdd()    {}
    onStoreModelInsert() {}
    onStoreModelRemove() {}
    onStoreRemoveAll()   {}
}
/**
 * STM checking-out state.
 *
 * @internal
 */
const CheckoutState = new CheckoutStateClass();
export default CheckoutState;
Registry.registerStmState('checkoutstate', CheckoutState);
