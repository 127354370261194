import { QUEUE_PROP, POS_PROP, REVISION_NAMES_PROP, REVISION_INDEX_PROP, REVISION_QUEUE_PROP } from './Props.js';
export const resetQueue = (stm, options) => {
    const { undo, redo, revision } = options;
    let newProps;
    if (undo && !redo) {
        newProps = {
            [QUEUE_PROP] : stm[QUEUE_PROP].slice(stm.position),
            [POS_PROP]   : 0
        };
    }
    else if (redo && !undo) {
        newProps = {
            [QUEUE_PROP] : stm[QUEUE_PROP].slice(0, stm.position)
        };
    }
    else {
        newProps = {
            [QUEUE_PROP] : [],
            [POS_PROP]   : 0
        };
    }
    if (revision) {
        newProps = Object.assign((newProps ?? {}), {
            [REVISION_NAMES_PROP] : [],
            [REVISION_INDEX_PROP] : -1,
            [REVISION_QUEUE_PROP] : []
        });
    };
    return [
        newProps,
        () => {
            stm.notifyStoresAboutQueueReset(options);
        }
    ];
};
