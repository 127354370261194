import PercentColumn from '../../Grid/column/PercentColumn.js';
import ColumnStore from '../../Grid/data/ColumnStore.js';
/**
 * @module Gantt/column/PercentDoneColumn
 */
/**
 * A column representing the {@link Gantt/model/TaskModel#field-percentDone} field of the task.
 *
 * {@inlineexample Gantt/column/PercentDoneColumn.js}
 *
 * ## Styling
 *
 * Cells in this column get a `b-percentdone-cell` class added.
 *
 * If {@link #config-mode} is set to `circle`, the resulting progress circle element in the cell gets a
 * few special CSS classes added:
 *
 * - If value equals 0, a `b-empty` CSS class is added to the circle element.
 * - If value equals 100, a `b-full` CSS class is added to the circle element.
 * - If value is > 100, a `b-over` CSS class is added to the circle element.
 *
 * Default editor is a {@link Core.widget.NumberField}.
 *
 * @extends Grid/column/PercentColumn
 * @classtype percentdone
 * @column
 */
export default class PercentDoneColumn extends PercentColumn {
    static $name = 'PercentDoneColumn';
    static type = 'percentdone';
    static isGanttColumn = true;
    //region Config
    static defaults =  {
        field      : 'percentDone',
        text       : 'L{% Done}',
        mode       : 'number',
        unit       : '%',
        step       : 1,
        min        : 0,
        max        : 100,
        width      : 90,
        filterable : {
            allowedOperators : ['=', '<', '<=', '>', '>='],
            filterFn({ value, record, operator, column }) {
                const
                    a = record.getFormattedPercentDone(record.percentDone),
                    b = record.getFormattedPercentDone(value);
                switch (operator) {
                    case '='  : return a === b;
                    case '<'  : return a < b;
                    case '<=' : return a <= b;
                    case '>'  : return a > b;
                    case '>=' : return a >= b;
                    default   : throw new Error('Invalid operator ' + operator);
                }
            }
        }
    };
    //endregion
    formatValue(value, record) {
        return record?.getFormattedPercentDone(value) ?? value;
    }
    formatAriaLabel(value, record) {
        return `${record.name} ${value}${this.L('% Done')}`;
    }
}
ColumnStore.registerColumnType(PercentDoneColumn);
PercentDoneColumn._$name = 'PercentDoneColumn';