import ResizeHelper from '../../Core/helper/ResizeHelper.js';
import InstancePlugin from '../../Core/mixin/InstancePlugin.js';
import GridFeatureManager from '../feature/GridFeatureManager.js';
import BrowserHelper from '../../Core/helper/BrowserHelper.js';
/**
 * @module Grid/feature/ColumnResize
 */
/**
 * Enables user to resize columns by dragging a handle on the right hand side of the header. To get notified about column
 * resize listen to `change` event on {@link Grid.data.ColumnStore columns} store.
 *
 * This feature is <strong>enabled</strong> by default.
 *
 * {@inlineexample Grid/feature/ColumnResize.js}
 *
 * @demo Grid/columns
 *
 * @extends Core/mixin/InstancePlugin
 * @classtype columnResize
 * @feature
 */
export default class ColumnResize extends InstancePlugin {
    static $name =  'ColumnResize';
    static configurable = {
        /**
         * Resize all cells below a resizing header during dragging.
         * `'auto'` means `true` on non-mobile platforms.
         * @config {'auto'|Boolean}
         * @default
         */
        liveResize : 'auto'
    };
    //region Init
    construct(grid, config) {
        const me = this;
        super.construct(grid, config);
        me.resizer = new ResizeHelper({
            name              : 'columnResize',
            targetSelector    : '.b-grid-header',
            handleSelector    : '.b-grid-header-resize-handle',
            outerElement      : grid.element,
            rtlSource         : grid,
            constrainToParent : false,
            internalListeners : {
                beforeresizestart : me.onBeforeResizeStart,
                resizestart       : me.onResizeStart,
                resizing          : me.onResizing,
                resize            : me.onResize,
                cancel            : me.onCancel,
                thisObj           : me
            }
        });
    }
    doDestroy() {
        this.resizer?.destroy();
        super.doDestroy();
    }
    //endregion
    changeLiveResize(liveResize) {
        if (liveResize === 'auto') {
            return !BrowserHelper.isMobileSafari;
        }
        return liveResize;
    }
    //region Events
    onBeforeResizeStart({ event, element }) {
        const { client } = this;
        /**
         * This event is fired prior to starting a column resize gesture. The resize is canceled if a listener returns
         * `false`.
         * @on-owner
         * @event beforeColumnResize
         * @param {Grid.view.Grid} source The grid instance
         * @param {Grid.column.Column} column The column
         * @param {Event} domEvent The browser event
         * @preventable
         */
        return !this.disabled && client.trigger('beforeColumnResize', {
            column   : client.columns.getById(element.dataset.columnId),
            domEvent : event
        });
    }
    onResizeStart({ context }) {
        const
            { client, resizer }       = this,
            column                    = context.column = client.columns.getById(context.element.dataset.columnId),
            { allRecords : siblings } = column.subGrid.columns;
        resizer.minWidth = column.minWidth;
        context.subGridBoundary = column.element.getBoundingClientRect()[client.rtl ? 'left' : 'right'];
        client.element.classList.add('b-column-resizing');
        // Unflex all preceding flexed siblings so that they do not change size
        // in response to target column size changes
        siblings.slice(0, siblings.indexOf(column)).forEach(c => {
            c.flex && c.element && c !== column.parent && c.set({
                width : c.element.offsetWidth,
                flex  : ''
            });
        });
        /**
         * This event is fired when a column resize gesture starts.
         * @on-owner
         * @event columnResizeStart
         * @param {Grid.view.Grid} source The grid instance
         * @param {Grid.column.Column} column The column
         * @param {Event} domEvent The browser event
         */
        client.trigger('columnResizeStart', {
            column,
            domEvent : context.event
        });
    }
    /**
     * Handle drag event - resize the column live unless it's a touch gesture
     * @private
     */
    onResizing({ context }) {
        if (context.valid && this.liveResize) {
            const
                { column }              = context,
                { subGrid }             = column,
                { scrollable, columns } = subGrid,
                { rtl }                 = this.client;
            this.client.resizingColumns = true;
            column.width = context.newWidth;
            // The horizontal scrollbar must provide instant visual feedback that the content width is changing
            subGrid.refreshFakeScroll();
            // Ensure we scroll with the mouse as it goes outside visible viewport
            if (column === columns.last && (rtl ? context.currentX < context.subGridBoundary : context.currentX > context.subGridBoundary)) {
                scrollable.x = scrollable.maxX;
            }
        }
    }
    /**
     * Handle drop event (only used for touch)
     * @fires columnResize
     * @private
     */
    onResize({ context }) {
        const
            { client } = this,
            { column, event } = context;
        client.element.classList.remove('b-column-resizing');
        if (context.valid) {
            if (this.liveResize) {
                client.resizingColumns = false;
                client.afterColumnsResized(column);
            }
            else {
                column.width = context.newWidth;
            }
            /**
             * This event is fired after a resize gesture is completed.
             * @on-owner
             * @event columnResize
             * @param {Grid.view.Grid} source The grid instance
             * @param {Grid.column.Column} column The resized column
             * @param {Event} domEvent The browser event
             */
            client.trigger('columnResize', { column, domEvent : event });
        }
    }
    /**
     * Restore column width on cancel (ESC)
     * @private
     */
    onCancel({ context }) {
        const { client } = this;
        client.element.classList.remove('b-column-resizing');
        context.column.width = context.elementWidth;
        client.resizingColumns = false;
    }
    //endregion
}
ColumnResize._$name = 'ColumnResize'; GridFeatureManager.registerFeature(ColumnResize, true);
