var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Mixin } from '../../../../ChronoGraph/class/Mixin.js';
import { calculate } from "../../../../ChronoGraph/replica/Entity.js";
import { ConstraintIntervalSide, Direction } from "../../../scheduling/Types.js";
import { ConstrainedScheduleMixin } from "../scheduler_pro/ConstrainedScheduleMixin.js";
import { ManuallyScheduledParentConstraintInterval } from "./ConstrainedByParentMixin.js";
//---------------------------------------------------------------------------------------------------------------------
/**
 * This mixin makes the event to "inherit" the constraints from its parent event.
 */
export class ConstrainedByParentScheduleMixin extends Mixin([
    ConstrainedScheduleMixin
], (base) => {
    const superProto = base.prototype;
    class ConstrainedByParentScheduleMixin extends base {
        // need to have at least one "entity" decorator in the class, otherwise the entity infrastructure won't be setup
        *calculateStartDateConstraintIntervals() {
            const intervals = yield* superProto.calculateStartDateConstraintIntervals.call(this);
            const parentEvent = yield this.event.$.parentEvent;
            if (parentEvent?.graph) {
                const applyConstraint = (yield parentEvent.$.manuallyScheduled)
                    && (yield this.event.$.effectiveDirection).direction === this.direction;
                if (!this.project.manuallyScheduledParentBreaksConstraintInheritance || !applyConstraint) {
                    const parentIntervals = yield this.pick(parentEvent).$.startDateConstraintIntervals;
                    intervals.push.apply(intervals, parentIntervals);
                }
                // If the parent is scheduled manually it restricts its children (even though it has no a constraint set)
                // so we append an artificial constraining interval
                if (applyConstraint && this.direction === Direction.Forward) {
                    intervals.push(ManuallyScheduledParentConstraintInterval.new({
                        owner: parentEvent,
                        side: ConstraintIntervalSide.Start,
                        startDate: yield parentEvent.$.startDate
                    }));
                }
            }
            return intervals;
        }
        *calculateEndDateConstraintIntervals() {
            const intervals = yield* superProto.calculateEndDateConstraintIntervals.call(this);
            const parentEvent = yield this.event.$.parentEvent;
            if (parentEvent?.graph) {
                const applyConstraint = (yield parentEvent.$.manuallyScheduled)
                    && (yield this.event.$.effectiveDirection).direction === this.direction;
                // Child inherits its parent tasks constraints
                // except the parent is manually scheduled (in this case the parent break constraints inheritance)
                if (!this.project.manuallyScheduledParentBreaksConstraintInheritance || !applyConstraint) {
                    const parentIntervals = yield this.pick(parentEvent).$.endDateConstraintIntervals;
                    intervals.push.apply(intervals, parentIntervals);
                }
                // If the parent is scheduled manually it restricts its children (even though it has no a constraint set)
                // so we append an artificial constraining interval
                if (applyConstraint && this.direction === Direction.Backward) {
                    intervals.push(ManuallyScheduledParentConstraintInterval.new({
                        owner: parentEvent,
                        side: ConstraintIntervalSide.End,
                        endDate: yield parentEvent.$.endDate
                    }));
                }
            }
            return intervals;
        }
    }
    __decorate([
        calculate('startDateConstraintIntervals')
    ], ConstrainedByParentScheduleMixin.prototype, "calculateStartDateConstraintIntervals", null);
    return ConstrainedByParentScheduleMixin;
}) {
}
