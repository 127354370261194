import ArrayHelper from '../helper/ArrayHelper.js';
import DomDataStore from '../data/DomDataStore.js';
import Widget from './Widget.js';
/**
 * @module Core/widget/Mask
 */
const
    isActive  = mask => mask.element.classList.contains('b-visible'),
    isPending = mask => !isActive(mask) && mask.isVisible;
/**
 * Masks a target element (document.body if none is specified). Call static methods for ease of use or make instance
 * for reusability.
 *
 * ```javascript
 *  Mask.mask('hello');
 *  Mask.unmask();
 * ````
 *
 * {@inlineexample Core/widget/Mask.js}
 *
 * Can show progress:
 *
 * ```javascript
 *  // Using progress by calling static method
 *  const mask = Mask.mask({
 *      text        :'The task is in progress',
 *      progress    : 0,
 *      maxProgress : 100
 *  });
 *
 *  let timer = setInterval(() => {
 *      mask.progress += 5;
 *      if (mask.progress >= mask.maxProgress) {
 *          Mask.unmask();
 *          clearInterval(timer);
 *      }
 *  }, 100);
 * ```
 *
 * ## Stacking Multiple Masks
 * An element can have multiple masks attached to it (for example, to handle independent asynchronous activities). When
 * an element has multiple masks attached, only the first mask created is visible to the user. This avoids unpleasant
 * transparency layering that would occur if all masks were visible at the same time. When the active mask is destroyed
 * or hidden (via {@link Core.widget.Widget#function-hide}), the next oldest mask is made visible. That is, masks are
 * displayed in FIFO order.
 *
 * When a mask is hidden, it is not a candidate in the FIFO order and is ignored when looking for the next oldest mask.
 * If the mask is shown (via {@link Core.widget.Widget#function-show}), the mask is again a candidate for being shown,
 * and will be shown immediately if there is not already a currently visible mask. This eliminates flickering effects
 * that would otherwise come from multiple masks competing for display.
 *
 * When the last mask is hidden or destroyed, the target element becomes unmasked. Showing and hiding of masks is useful
 * when the masks may be needed repeatedly over time.
 *
 * ## Masking Bryntum Widgets
 * Shortcut to masking Bryntum components:
 *
 * ```javascript
 *  // Using progress to mask a Bryntum component
 *  scheduler.mask({
 *      text:'Loading in progress',
 *      progress: 0,
 *      maxProgress: 100
 *  });
 *
 *  let timer = setInterval(() => {
 *      scheduler.masked.progress += 5;
 *      if (scheduler.masked.progress >= scheduler.masked.maxProgress) {
 *          scheduler.unmask();
 *          clearInterval(timer);
 *      }
 *  },100);
 * ```
 * @extends Core/widget/Widget
 * @noclasstype
 * @widget
 */
export default class Mask extends Widget {
    //region Config
    static $name = 'Mask';
    static type = 'mask';
    static configurable = {
        /**
         * Set this config to trigger an automatic close after the desired delay:
         * ```javascript
         *  mask.autoClose = 2000;
         * ```
         * If the mask has an `owner`, its `onMaskAutoClosing` method is called when the close starts and its
         * `onMaskAutoClose` method is called when the close finishes.
         * @config {Number}
         * @private
         */
        autoClose : null,
        /**
         * The portion of the {@link #config-target} element to be covered by this mask. By default, the mask fully
         * covers the `target`. In some cases, however, it may be desired to only cover the `'body'` (for example,
         * in a grid).
         *
         * This config is set in conjunction with `owner` which implements the method `syncMaskCover`.
         *
         * @config {String}
         * @private
         */
        cover : null,
        /**
         * The icon to show next to the text. Defaults to showing a spinner
         * @config {String}
         * @default
         */
        icon : 'b-icon b-icon-spinner',
        errorDefaults : {
            icon      : 'b-icon b-icon-warning',
            autoClose : 3000,
            showDelay : 0
        },
        /**
         * The maximum value of the progress indicator
         * @property {Number}
         */
        maxProgress : null,
        /**
         * Mode: bright, bright-blur, dark or dark-blur
         * @config {'bright'|'bright-blur'|'dark'|'dark-blur'}
         * @default
         */
        mode : 'dark',
        /**
         * Number expressing the progress
         * @property {Number}
         */
        progress : null,
        // The owner is involved in the following features:
        //
        // - The `autoClose` timer calls `onMaskAutoClose`.
        // - The `cover` config calls `syncMaskCover`.
        // - If the `target` is a string, that string names the property of the `owner` that holds the
        //   `HTMLElement` reference.
        /**
         * The element to be masked. If this config is a string, that string is the name of the property of the
         * {@link #config-owner} that holds the `HTMLElement` that is the actual target of the mask.
         *
         * NOTE: In prior releases, this used to be specified as the `element` config.
         *
         * @config {String|HTMLElement}
         */
        target : {
            $config : 'nullify',
            value   : undefined
        },
        /**
         * The text (or HTML) to show in mask
         * @prp {String}
         */
        text : null,
        type : null,
        /**
         * The number of milliseconds to delay before making the mask visible. If set, the mask will have an
         * initial `opacity` of 0 but will function in all other ways as a normal mask. Setting this delay can
         * reduce flicker in cases where load operations are typically short (for example, a second or less).
         *
         * @config {Number}
         */
        showDelay : null,
        useTransition : false
    };
    static delayable = {
        deferredClose : 0,
        delayedShow   : 0,
        syncCover     : {
            type  : 'throttle',
            delay : 100
        }
    };
    //endregion
    //region Static
    // Used to give masks unique names
    static counter = 0;
    /**
     * Returns the array of `Mask` instances for the given `element`.
     * @param {HTMLElement} element The element for which to return `Mask` instances
     * @returns {Core.widget.Mask|Core.widget.Mask[]}
     */
    static get(element) {
        return DomDataStore.get(element, 'masks') || DomDataStore.set(element, 'masks', []);
    }
    static getActive(element) {
        return Mask.get(element).find(isActive) || null;
    }
    static getPending(element) {
        return Mask.get(element).filter(isPending).sort(Widget.weightSortFn);
    }
    /**
     * Shows a mask with the specified message.
     *
     * Masks stack, call {@link #function-unmask-static} to remove the topmost mask. Or call {@link #function-close}
     * on the returned mask to close it specifically. Only one mask is displayed for a given `target` element at a time.
     * For example, if two masks are added to an element, the first mask is displayed. If the first mask is closed,
     * then the second mask will become visible.
     *
     * @param {String|MaskConfig} text Message
     * @param {HTMLElement} [target] The element to mask (default is `document.body`)
     * @returns {Core.widget.Mask}
     */
    static mask(text, target = document.body) {
        return Mask.new({ target }, text);
    }
    static mergeConfigs(...sources) {
        sources = sources.map(c => typeof c === 'string' ? { text : c } : c);
        return super.mergeConfigs(...sources);
    }
    static sync(target) {
        const
            active  = Mask.getActive(target),
            pending = !active && Mask.getPending(target)[0] || null,
            mask    = active || pending,
            mode    = mask?.mode || '',
            cls     = mode.endsWith('blur') ? `b-masked-${mode}` : '';
        let child, classList, remove;
        if (pending) {
            classList = pending.element.classList;
            if (pending.showDelay && !target.classList.contains('b-masked')) {
                classList.add('b-delayed-show');
                pending.delayedShow();  // removes b-delayed-show
            }
            classList.add('b-visible');
        }
        target.classList.toggle('b-masked', Boolean(mask));
        for (child of target.children) {
            classList = child.classList;
            if (!classList.contains('b-mask')) {   // ignore Mask instances
                remove = Array.from(classList).filter(c => c.startsWith('b-masked-') && cls !== c);
                remove.length && classList.remove(...remove);
                cls && classList.add(cls);
            }
        }
    }
    /**
     * Close the most recently created mask for the specified element.
     * @param {HTMLElement} [element] The element to unmask (default is `document.body`)
     * @returns {Promise|null} A promise which is resolved when the mask is gone, or null if element is not masked
     */
    static unmask(element = document.body) {
        const
            masks = Mask.get(element),
            n = masks.length;
        return n ? masks[n - 1]?.close() : null;
    }
    /**
     * Close all masks for the specified element
     * @param {HTMLElement} [element] The element to unmask (default is `document.body`)
     * @returns {Promise|null} A promise which is resolved when the mask is gone, or null if element is not masked
     * @internal
     */
    static unmaskAll(element = document.body) {
        Mask.get(element).filter(mask => !isActive(mask)).forEach(m => m.destroy());
        return Mask.unmask(element);
    }
    //endregion
    //region Init
    destroy() {
        if (this.type !== 'trial') {
            super.destroy();
        }
    }
    compose() {
        const { icon, maxProgress, mode, progress, showDelay, text, useTransition } = this;
        return {
            class : {
                'b-mask'                : 1,
                'b-delayed-show'        : showDelay,
                'b-progress'            : maxProgress,
                'b-prevent-transitions' : !useTransition,
                // 'b-hidden'              : hidden,
                // 'b-visible'             : !hidden,
                [`b-mask-${mode}`]      : 1
            },
            children : {
                maskContent : {
                    class    : 'b-mask-content',
                    children : {
                        progressElement : maxProgress ? {
                            class : 'b-mask-progress-bar',
                            style : {
                                width : `${Math.max(0, Math.min(100, Math.round(progress / maxProgress * 100)))}%`
                            }
                        } : null,
                        maskText : {
                            class : 'b-mask-text',
                            html  : (icon ? `<i class="b-mask-icon ${icon}"></i>` : '') + text
                        }
                    }
                }
            }
        };
    }
    //endregion
    //region Config
    generateAutoId() {
        const { type } = this;
        return `mask${typeof type === 'string' ? type.trim() : ''}-${Mask.counter++}`;
    }
    updateAutoClose(delay) {
        this.deferredClose.cancel();
        if (delay) {
            this.deferredClose.delay = delay;
            this.deferredClose();
        }
    }
    updateCover() {
        this.syncCover();
    }
    syncCover() {
        this.owner?.syncMaskCover?.(this);  // pass "this" since owner may not yet have assigned us to "masked"
    }
    set error(value) {
        this.setConfig({
            ...this.errorDefaults,
            text : value
        });
    }
    onOwnerResize() {
        this.syncCover();
    }
    updateOwner(owner) {
        this.detachListeners('cover');
        owner?.ion({
            name      : 'cover',
            recompose : 'onOwnerResize',
            resize    : 'onOwnerResize',
            thisObj   : this
        });
    }
    updateShowDelay(delay) {
        const { delayedShow } = this;
        delayedShow.delay = delay;
        if (!delay) {
            delayedShow.flush();
        }
    }
    changeTarget(target) {
        if (target === undefined) {
            target = document.body;
        }
        else if (typeof target === 'string') {
            target = this.owner[target];  // must supply "owner" in this case
        }
        return target;
    }
    updateTarget(target, was) {
        const
            me              = this,
            { id, element } = me,  // mask-1, masktrial-2, ...
            masks           = was && Mask.get(was);
        if (was) {
            if (was[id] === me) {
                delete was[id];
            }
            ArrayHelper.remove(masks, me);
            Mask.sync(was);
        }
        if (target) {
            if (!target[id]) {
                target[id] = me;
            }
            if (element.parentNode !== target) {
                target.appendChild(element);
            }
            ArrayHelper.include(Mask.get(target), me);
            Mask.sync(target);
        }
        else {
            element.remove();
        }
    }
    //endregion
    //region Show & hide
    deferredClose() {
        const
            me        = this,
            { owner } = me;
        me.close().then(() => owner?.onMaskAutoClose?.(me));
        owner?.onMaskAutoClosing?.(me);
    }
    delayedShow() {
        this.element.classList.remove('b-delayed-show');
    }
    updateHidden(hidden, was) {
        super.updateHidden?.(hidden, was);
        // Let sync() decide which Mask will get b-visible but if this Mask has it, remove it so that it doesn't look
        // like the active Mask. "In the end, there can be only one!"
        hidden && this.element.classList.remove('b-visible');
        Mask.sync(this.target);
    }
    afterShow(...args) {
        super.afterShow?.(...args);
        Mask.sync(this.target);
    }
    afterHide(...args) {
        super.afterHide?.(...args);
        this.element.classList.remove('b-visible');   // see updateHidden
        Mask.sync(this.target);
    }
    /**
     * Close mask (removes it)
     * @returns {Promise} A promise which is resolved after the mask is closed and destroyed.
     */
    async close() {
        await this.hide();
        this.destroy();
    }
    //endregion
}
Mask.initClass();
Mask._$name = 'Mask';