import DomDataStore from '../../Core/data/DomDataStore.js';
import DomHelper from '../../Core/helper/DomHelper.js';
import InstancePlugin from '../../Core/mixin/InstancePlugin.js';
import GridFeatureManager from './GridFeatureManager.js';
import DomSync from '../../Core/helper/DomSync.js';
import ArrayHelper from '../../Core/helper/ArrayHelper.js';
import StringHelper from '../../Core/helper/StringHelper.js';
/**
 * @module Grid/feature/Group
 */
/**
 * Enables rendering and handling of row groups. The actual grouping is done in the store, but triggered by `[shift]` +
 * clicking headers or by using two finger tap (one on header, one anywhere on grid). Use `[shift]` + `[alt]` + click to
 * remove a column grouper.
 *
 * {@inlineexample Grid/feature/Group.js}
 *
 * Groups can be expanded/collapsed by clicking on the group row or pressing [space] when group row is selected.
 * The actual grouping is done by the store, see {@link Core.data.mixin.StoreGroup#function-group}.
 *
 * Grouping by a field performs sorting by the field automatically. It's not possible to prevent sorting.
 * If you group, the records have to be sorted so that records in a group stick together. You can either control sorting
 * direction, or provide a custom sorting function called {@link #config-groupSortFn} to your feature config object.
 *
 * For info on programmatically handling grouping, see {@link Core.data.mixin.StoreGroup}.
 *
 * Example snippets:
 *
 * ```javascript
 * // grouping feature is enabled, no default value though
 * let grid = new Grid({
 *     features : {
 *         group : true
 *     }
 * });
 *
 * // use initial grouping
 * let grid = new Grid({
 *     features : {
 *         group : 'city'
 *     }
 * });
 *
 * // default grouper and custom renderer, which will be applied to each cell except the "group" cell
 * let grid = new Grid({
 *     features : {
 *       group : {
 *           field : 'city',
 *           ascending : false,
 *           renderer : ({ isFirstColumn, count, groupRowFor, record }) => isFirstColumn ? `${groupRowFor} (${count})` : ''
 *       }
 *     }
 * });
 *
 * // group using custom sort function
 * let grid = new Grid({
 *     features : {
 *         group       : {
 *             field       : 'city',
 *             groupSortFn : (a, b) => a.city.length < b.city.length ? -1 : 1
 *         }
 *     }
 * });
 *
 * // can also be specified on the store
 * let grid = new Grid({
 *     store : {
 *         groupers : [
 *             { field : 'city', ascending : false }
 *         ]
 *     }
 * });
 *
 * // custom sorting function can also be specified on the store
 * let grid = new Grid({
 *     store : {
 *         groupers : [{
 *             field : 'city',
 *             fn : (recordA, recordB) => {
 *                 // apply custom logic, for example:
 *                 return recordA.city.length < recordB.city.length ? -1 : 1;
 *             }
 *         }]
 *     }
 * });
 * ```
 *
 * Currently, grouping is not supported when using pagination, the underlying store cannot group data that is split into
 * pages.
 *
 * {@note}Custom height for group header rows cannot be set with CSS, should instead be defined in a renderer function
 * using the `size` param. See the {@link #config-renderer} config for details.{/@note}
 *
 * {@note}This feature will not work properly when Store uses {@link Core.data.Store#config-lazyLoad}{/@note}
 *
 * ## Toggling a group collapsed state programmatically
 *
 * You can easily toggle a group´s collapsed state, by passing a group member record like so:
 *
 * ```javascript
 * // collapse the group for a certain record
 * grid.features.group.toggleCollapse(record, true);
 * ```
 *
 * ## Grouping by multi-value fields
 *
 * The group field's value may be an array. This means that one record can be a member of more than one
 * group. When this is the case, the second and subsequent generated groups contain a
 * {@link Core.data.Model#function-link linked record} which is a copy of the original record. Please note that some of
 * the linked records fields are not shared, like `id` (which is always generated).
 *
 * {@inlineexample Grid/feature/GroupMulti.js}
 *
 * ## Keyboard shortcuts
 * This feature has the following default keyboard shortcuts:
 *
 * | Keys     | Action        | Action description                                                         |
 * |----------|---------------|----------------------------------------------------------------------------|
 * | `Space`  | *toggleGroup* | When a group header is focused, this expands or collapses the grouped rows |
 *
 * For more information on how to customize keyboard shortcuts, please see the
 * [Customizing keyboard shortcuts guide](#Grid/guides/customization/keymap.md)
 *
 * This feature is **enabled** by default.
 *
 * @demo Grid/grouping
 *
 * @extends Core/mixin/InstancePlugin
 * @classtype group
 * @feature
 */
export default class Group extends InstancePlugin {
    static get $name() {
        return 'Group';
    }
    static get configurable() {
        return {
            /**
             * The name of the record field to group by.
             * @prp {String}
             */
            field : null,
            /**
             * The icon to use for the collapse icon in collapsed state
             * @config {String}
             */
            expandIconCls : 'b-icon-group-expand',
            /**
             * The icon to use for the collapse icon in expanded state
             * @config {String}
             */
            collapseIconCls : 'b-icon-group-collapse',
            /**
             * The sort direction of the groups.
             * @prp {Boolean}
             * @default
             */
            ascending : true,
            /**
             * The height of group header rows. Can also be set on a per-group-header basis using the {@link #config-renderer}
             * @prp {Number}
             */
            headerHeight : null,
            /**
             * Set to `true` to show the number of members of each group in the group header
             * @prp {Boolean}
             */
            showCount : true,
            /**
             * A function used to sort the groups.
             * When grouping, the records have to be sorted so that records in a group stick together.
             * Technically that means that records having the same {@link #config-field} value
             * should go next to each other.
             * And this function (if provided) is responsible for applying such grouping order.
             * ```javascript
             * const grid = new Grid({
             *     features : {
             *         group : {
             *             // group by category
             *             field       : 'category',
             *             groupSortFn : (a, b) => {
             *                 const
             *                     aCategory = a.category || '',
             *                     bCategory = b.category || '';
             *
             *                 // 1st sort by "calegory" field
             *                 return aCategory > bCategory ? -1 :
             *                     aCategory < bCategory ? 1 :
             *                     // inside calegory groups we sort by "name" field
             *                     (a.name > b.name ? -1 : 1);
             *             }
             *         }
             *     }
             * });
             * ```
             *
             * @config {Function}
             * @param {*} first The first value to compare
             * @param {*} second The second value to compare
             * @returns {Number}  Returns `1` if first value is greater than second value, `-1` if the opposite is true or `0` if they're equal
             */
            groupSortFn : null,
            /**
             * A function which produces the HTML for a group header.
             * The function is called in the context of this Group feature object.
             * Default group renderer displays the `groupRowFor` and `count`.
             *
             * @config {Function}
             * @param {Object} renderData Object containing renderer parameters
             * @param {*} renderData.groupRowFor The value of the `field` for the group. Type depends on `field` used for grouping
             * @param {Core.data.Model} renderData.record The group record representing the group
             * @param {Number} renderData.count Number of records in the group
             * @param {Grid.column.Column} renderData.column The column the renderer runs for
             * @param {Boolean} renderData.isFirstColumn True, if `column` is the first column. If `RowNumberColumn` is the real first column, it's not taken into account
             * @param {Grid.column.Column} renderData.groupColumn The column under which the `field` is shown
             * @param {Object} renderData.size Sizing information for the group header row, only `height` is relevant
             * @param {Number} renderData.size.height The height of the row, set this if you want a custom height for the group header row.
             *   That is UI part, so do not rely on its existence
             * @param {Grid.view.Grid} renderData.grid The owning grid
             * @param {HTMLElement} renderData.rowElement The owning row element
             * @returns {DomConfig|String|null}
             * @default
             *
             * @category Rendering
             */
            renderer : null,
            /**
             * See {@link #keyboard-shortcuts Keyboard shortcuts} for details
             * @config {Object<String,String>}
             */
            keyMap : {
                ' ' : 'toggleGroup'
            },
            /**
             * By default, clicking anywhere in a group row toggles its expanded/collapsed state.
             *
             * Configure this as `false` to limit this to only toggling on click of the expanded/collapsed
             * state icon.
             * @prp {Boolean}
             * @default
             */
            toggleOnRowClick : true
        };
    }
    //region Init
    construct(grid, config) {
        const me = this;
        if (grid.features.tree) {
            return;
        }
        // groupSummary feature needs to be initialized first, if it is used
        me._thisIsAUsedExpression(grid.features.groupSummary);
        // process initial config into an actual config object
        config = me.processConfig(config);
        me.grid = grid;
        super.construct(grid, config);
        grid.ion({
            lockRows         : 'onLockRows',
            beforeUnlockRows : 'onUnlockRows',
            thisObj          : me
        });
        if (!grid.features.lockRows?.enabled || grid.splitFrom) {
            me.init(grid);
        }
    }
    init(grid) {
        this.grid = grid;
        this.bindStore(grid.store);
        this.detachListeners('rowManager');
        grid.rowManager.ion({
            name            : 'rowManager',
            beforeRenderRow : 'onBeforeRenderRow',
            renderCell      : 'renderCell',
            // The feature gets to see cells being rendered before the GroupSummary feature
            // because this injects header content into group header rows and adds rendering
            // info to the cells renderData which GroupSummary must comply with.
            prio    : 1100,
            thisObj : this
        });
        if (!grid.isConfiguring) {
            grid.renderRows();
        }
    }
    // Group feature handles special config cases, where user can supply a string or a group config object
    // instead of a normal config object
    processConfig(config) {
        if (typeof config === 'string') {
            return {
                field : config
            };
        }
        return config;
    }
    // override setConfig to process config before applying it (used mainly from ReactGrid)
    setConfig(config) {
        if (config === null) {
            this.store.clearGroupers();
        }
        else {
            super.setConfig(this.processConfig(config));
        }
    }
    bindStore(store) {
        const
            me         = this,
            { client } = me;
        me.detachListeners('store');
        if (client.isLockedRows) {
            return;
        }
        store.ion({
            name        : 'store',
            group       : 'onStoreGroup',
            change      : 'onStoreChange',
            toggleGroup : 'onStoreToggleGroup',
            thisObj     : me
        });
        // For LockRows scenario, ensure we react to locking/unlocking
        if ((me.field || store.isGrouped) && !client.isConfiguring) {
            store.group({
                field     : me.field,
                ascending : me.ascending,
                fn        : me.groupSortFn
            });
        }
        else {
            me.onStoreGroup({ groupers : store.groupers });
        }
    }
    updateRenderer(renderer) {
        this.groupRenderer = renderer;
    }
    updateField(field) {
        const me = this;
        if (me.client.features.lockRows?.enabled && !me.client.splitFrom) {
            return;
        }
        // Do not reapply grouping if already grouped by the field. me will prevent group direction from flipping
        // when splitting grids using group feature configured with field (store is shared)
        if (!me.isConfiguring || !me.store.groupers?.some(g => g.field === field)) {
            me.store.group({
                field,
                ascending : me.ascending,
                fn        : me.groupSortFn
            });
        }
    }
    updateGroupSortFn(fn) {
        if (!this.isConfiguring) {
            this.store.group({
                field     : this.field,
                ascending : this.ascending,
                fn
            });
        }
    }
    updateAscending(ascending) {
        const me = this;
        if (!me.isConfiguring) {
            me.store.group({
                field : me.field,
                ascending,
                fn    : me.groupSortFn
            });
        }
    }
    doDisable(disable) {
        const { store } = this;
        // Grouping mostly happens in store, need to clear groupers there to remove headers.
        // Use configured groupers as first sorters to somewhat maintain the order
        if (disable && store.isGrouped) {
            const { sorters } = store;
            sorters.unshift(...store.groupers);
            this.currentGroupers = store.groupers;
            store.clearGroupers();
            store.sort(sorters);
        }
        else if (!disable && this.currentGroupers) {
            store.group(this.currentGroupers[0]);
            this.currentGroupers = null;
        }
        super.doDisable(disable);
    }
    get store() {
        return this.grid.store;
    }
    //endregion
    //region Plugin config
    // Plugin configuration. This plugin chains some of the functions in Grid.
    static get pluginConfig() {
        return {
            assign : ['collapseAll', 'expandAll'],
            chain  : ['renderHeader', 'populateHeaderMenu', 'getColumnDragToolbarItems', 'onElementTouchStart',
                'onElementClick', 'bindStore']
        };
    }
    //endregion
    //region Expand/collapse
    refreshGrid(groupRecord) {
        const { store, rowManager } = this.grid;
        // If collapsing the group reduces amount of records below amount of rendered rows, we need to refresh
        // entire view
        // https://github.com/bryntum/support/issues/5893
        if (rowManager.rowCount > store.count || !rowManager.getRowFor(groupRecord)) {
            rowManager.renderFromRow();
        }
        else {
            // render from group record and down, no need to touch those above
            rowManager.renderFromRecord(groupRecord);
        }
    }
    /**
     * Collapses or expands a group header record (you can also pass a record that is part of a group) depending on its
     * current state.
     * @param {Core.data.Model|String} recordOrId Record or records id for a group row to collapse or expand
     * @param {Boolean} collapse Force collapse (`true`) or expand (`false`)
     * @fires toggleGroup
     */
    toggleCollapse(recordOrId, collapse) {
        this.internalToggleCollapse(recordOrId, collapse);
    }
    /**
     * Collapses or expands a group depending on its current state
     * @param {Core.data.Model|String} recordOrId Record or records id for a group row to collapse or expand
     * @param {Boolean} collapse Force collapse (true) or expand (true)
     * @param {Boolean} [skipRender] True to not render rows
     * @param {Event} [domEvent] The user interaction event (eg a `click` event) if the toggle request was
     * instigated by user interaction.
     * @param {Boolean} [allRecords] True if this event is part of toggling all groups
     * @internal
     * @fires toggleGroup
     */
    internalToggleCollapse(recordOrId, collapse, skipRender = false, domEvent, allRecords = false) {
        const
            me              = this,
            { store, grid } = me;
        let groupRecord = store.getById(recordOrId);
        if (!groupRecord.isGroupHeader) {
            groupRecord = store.getGroupHeaderForRecord(groupRecord);
        }
        if (!groupRecord.isGroupHeader) {
            return;
        }
        collapse = collapse === undefined ? !groupRecord.meta.collapsed : collapse;
        /**
         * Fired when a group is going to be expanded or collapsed using the UI.
         * Returning `false` from a listener prevents the operation
         * @event beforeToggleGroup
         * @on-owner
         * @preventable
         * @param {Core.data.Model} groupRecord Group record
         * @param {Boolean} collapse Collapsed (true) or expanded (false)
         * @param {Event} domEvent The user interaction event (eg a `click` event) if the toggle request was
         * instigated by user interaction.
         */
        if (grid.trigger('beforeToggleGroup', { groupRecord, collapse, domEvent }) === false) {
            return;
        }
        me.isToggling = true;
        if (collapse) {
            store.collapse(groupRecord);
        }
        else {
            store.expand(groupRecord);
        }
        me.isToggling = false;
        if (!skipRender) {
            me.refreshGrid(groupRecord);
        }
        /**
         * Group expanded or collapsed
         * @event toggleGroup
         * @on-owner
         * @param {Core.data.Model} groupRecord Group record
         * @param {Boolean} collapse Collapsed (true) or expanded (false)
         * @param {Boolean} [allRecords] True if this event is part of toggling all groups
         */
        grid.trigger('toggleGroup', { groupRecord, collapse, allRecords });
        grid.afterToggleGroup();
    }
    /**
     * Collapse all groups. This function is exposed on Grid and can thus be called as `grid.collapseAll()`
     * @on-owner
     * @non-lazy-load
     * @typings {Promise<void>}
     * @category Grouping
     */
    collapseAll() {
        const
            me              = this,
            { store, grid } = me;
        if (store.isGrouped && !me.disabled) {
            store.groupRecords.forEach(r => me.internalToggleCollapse(r, true, true, undefined, true));
            grid.trigger('collapseAllGroups');
            grid.refreshRows(null, true);
        }
    }
    /**
     * Expand all groups. This function is exposed on Grid and can thus be called as `grid.expandAll()`
     * @on-owner
     * @non-lazy-load
     * @typings {Promise<void>}
     * @category Grouping
     */
    expandAll() {
        const
            me              = this,
            { store, grid } = me;
        if (store.isGrouped && !me.disabled) {
            store.groupRecords.forEach(r => me.internalToggleCollapse(r, false, true, undefined, true));
            grid.trigger('expandAllGroups');
            grid.refreshRows();
        }
    }
    //endregion
    //region Rendering
    onLockRows({ clone }) {
        this.grid = clone;
    }
    onUnlockRows({ source }) {
        const { client } = this;
        if (!client.isDestroying) {
            this.init(client);
        }
    }
    /**
     * Called before rendering row contents, used to reset rows no longer used as group rows
     * @private
     */
    onBeforeRenderRow({ row }) {
        // row.id contains previous record id on before render
        const oldRecord    = row.grid.store.getById(row.id);
        // force update of inner html if this row used for group data
        row.forceInnerHTML = row.forceInnerHTML || oldRecord?.isGroupHeader;
    }
    /**
     * Called when a cell is rendered, styles the group rows first cell.
     * @private
     */
    renderCell(renderData) {
        const
            me         = this,
            {
                cellElement,
                row,
                column,
                grid
            }          = renderData,
            { meta }   = renderData.record,
            rowClasses = {
                'b-group-row'            : 0,
                'b-grid-group-collapsed' : 0
            };
        if (!me.disabled && me.store.isGrouped && 'groupRowFor' in meta) {
            // do nothing with action column to make possible using actions for groups
            if (column.type === 'action') {
                return;
            }
            // let column clear the cell, in case it needs to do some cleanup
            column.clearCell(cellElement);
            // this is a group row, add css classes
            rowClasses['b-grid-group-collapsed'] = meta.collapsed;
            rowClasses['b-group-row']            = 1;
            if (grid.buildGroupHeader) {
                grid.buildGroupHeader(renderData);
            }
            else {
                me.buildGroupHeader(renderData);
            }
            if (column === me.groupHeaderColumn) {
                DomHelper.createElement({
                    parent    : cellElement,
                    tag       : 'i',
                    className : {
                        'b-group-state-icon' : 1,
                        'b-fw-icon'          : 1,
                        [me.expandIconCls]   : meta.collapsed,
                        [me.collapseIconCls] : !meta.collapsed
                    },
                    nextSibling : cellElement.firstChild
                });
                cellElement.classList.add('b-group-title');
                cellElement.$groupHeader = cellElement._hasHtml = true;
            }
        }
        else if (cellElement.$groupHeader) {
            cellElement.querySelector('.b-group-state-icon')?.remove();
            cellElement.classList.remove('b-group-title');
            cellElement.$groupHeader = false;
        }
        // Still need to sync row classes is disabled or not grouped.
        // Previous b-group-row and b-grid-group-collapsed classes must be removed.
        row.assignCls(rowClasses);
    }
    // renderData.cellElement is required
    buildGroupHeader(renderData) {
        const
            me               = this,
            {
                record,
                cellElement,
                column,
                persist
            }                = renderData,
            { grid }         = me,
            meta             = record.meta,
            groupRowFor      = meta.emptyArray ? grid.L('L{Object.None}') : meta.groupRowFor,
            { groupSummary } = grid.features,
            // Need to adjust count if group summary is used
            count            = meta.childCount - (groupSummary && groupSummary.target !== 'header' ? 1 : 0);
        let html         = null,
            applyDefault = true;
        if (persist || column) {
            const
                groupColumn         = grid.columns.get(meta.groupField),
                isGroupHeaderColumn = renderData.isFirstColumn = column === me.groupHeaderColumn;
            // First try using columns groupRenderer (might not even have a column if grouping programmatically)
            if (groupColumn?.groupRenderer) {
                if (isGroupHeaderColumn) {
                    // groupRenderer could return nothing and just apply changes directly to DOM element
                    html = groupColumn.groupRenderer({
                        ...renderData,
                        groupRowFor,
                        groupRecords : record.groupChildren,
                        groupColumn,
                        count
                    });
                    applyDefault = false;
                }
            }
            // Secondly use features groupRenderer, if configured with one
            else if (me.groupRenderer) {
                // groupRenderer could return nothing and just apply changes directly to DOM element
                html = me.groupRenderer({
                    ...renderData,
                    groupRowFor,
                    groupRecords  : record.groupChildren,
                    groupColumn,
                    count,
                    isFirstColumn : isGroupHeaderColumn
                });
            }
            if (!renderData.size?.height && me.headerHeight) {
                renderData.size.height = me.headerHeight;
            }
            // Third, just display unformatted value and child count (also applied for features groupRenderer that do
            // not output any html of their own)
            if (isGroupHeaderColumn && html == null && applyDefault && DomHelper.getChildElementCount(cellElement) === 0) {
                html = StringHelper.encodeHtml(`${groupRowFor === '__novalue__' ? '' : groupRowFor}` + (me.showCount ? ` (${count})` : ''));
            }
        }
        else if (me.groupRenderer) {
            // groupRenderer could return nothing and just apply changes directly to DOM element
            html = me.groupRenderer(renderData);
        }
        // Renderers could return nothing and just apply changes directly to DOM element
        if (typeof html === 'string') {
            cellElement.innerHTML = html;
        }
        else if (typeof html === 'object') {
            DomSync.sync({
                targetElement : cellElement,
                domConfig     : {
                    onlyChildren : true,
                    children     : ArrayHelper.asArray(html)
                }
            });
        }
        // If groupRenderer added elements to the cell, we need to remember that to clear it on re-usage as a normal cell
        if (DomHelper.getChildElementCount(cellElement) > 0) {
            cellElement._hasHtml = true;
        }
        return cellElement.innerHTML;
    }
    get groupHeaderColumn() {
        return this.grid.columns.visibleColumns.find(column => !column.groupHeaderReserved);
    }
    /**
     * Called when a header is rendered, adds grouping icon if grouped by that column.
     * @private
     * @param headerContainerElement
     */
    renderHeader(headerContainerElement = this.grid.headerContainer) {
        const { store, grid } = this;
        if (headerContainerElement && store.isGrouped) {
            // Sorted from start, reflect in rendering
            for (const groupInfo of store.groupers) {
                // Might be grouping by field without column, which is valid
                const
                    column = grid.columns.get(groupInfo.field),
                    header = column && grid.getHeaderElement(column.id);
                header?.classList.add('b-group', groupInfo.ascending ? 'b-asc' : 'b-desc');
                // If sort feature is active, it provides the icon - if not we add it here
                if (header && (!grid.features.sort?.enabled || column.sortable === false)) {
                    const textEl = column.textWrapper;
                    if (!textEl?.querySelector('.b-sort-icon')) {
                        DomHelper.createElement({
                            parent    : textEl,
                            className : 'b-sort-icon'
                        });
                    }
                }
            }
        }
    }
    //endregion
    //region Context menu
    /**
     * Supply items for headers context menu.
     * @param {Object} options Contains menu items and extra data retrieved from the menu target.
     * @param {Grid.column.Column} options.column Column for which the menu will be shown
     * @param {Object<String,MenuItemConfig|Boolean|null>} options.items A named object to describe menu items
     * @internal
     */
    populateHeaderMenu({ column, items }) {
        const me = this;
        if (column.groupable !== false) {
            items.groupAsc = {
                text        : 'L{groupAscending}',
                localeClass : me,
                icon        : 'b-fw-icon b-icon-group-asc',
                cls         : 'b-separator',
                weight      : 400,
                disabled    : me.disabled,
                onItem      : () => me.store.group(column.field, true)
            };
            items.groupDesc = {
                text        : 'L{groupDescending}',
                localeClass : me,
                icon        : 'b-fw-icon b-icon-group-desc',
                weight      : 410,
                disabled    : me.disabled,
                onItem      : () => me.store.group(column.field, false)
            };
        }
        if (me.store.isGrouped) {
            items.groupRemove = {
                text        : 'L{stopGrouping}',
                localeClass : me,
                icon        : 'b-fw-icon b-icon-clear',
                cls         : column.groupable ? '' : 'b-separator',
                weight      : 420,
                disabled    : me.disabled,
                onItem      : () => me.store.clearGroupers()
            };
        }
    }
    /**
     * Supply items to ColumnDragToolbar
     * @private
     */
    getColumnDragToolbarItems(column, items) {
        const
            me                  = this,
            { store, disabled } = me;
        items.push({
            text        : 'L{groupAscendingShort}',
            group       : 'L{group}',
            localeClass : me,
            icon        : 'b-icon b-icon-group-asc',
            ref         : 'groupAsc',
            cls         : 'b-separator',
            weight      : 110,
            disabled,
            onDrop      : ({ column }) => store.group(column.field, true)
        });
        items.push({
            text        : 'L{groupDescendingShort}',
            group       : 'L{group}',
            localeClass : me,
            icon        : 'b-icon b-icon-group-desc',
            ref         : 'groupDesc',
            weight      : 110,
            disabled,
            onDrop      : ({ column }) => store.group(column.field, false)
        });
        const grouped = store.groupers?.some(col => col.field === column.field) && !disabled;
        items.push({
            text        : 'L{stopGroupingShort}',
            group       : 'L{group}',
            localeClass : me,
            icon        : 'b-icon b-icon-clear',
            ref         : 'groupRemove',
            disabled    : !grouped,
            weight      : 110,
            onDrop      : ({ column }) => store.removeGrouper(column.field)
        });
        return items;
    }
    //endregion
    //region Events - Store
    /**
     * Called when store grouping changes. Reflects on header and rerenders rows.
     * @private
     */
    onStoreGroup({ groupers }) {
        const
            { grid }        = this,
            { element }     = grid,
            curGroupHeaders = element && DomHelper.children(element, '.b-grid-header.b-group');
        if (element) {
            for (const header of curGroupHeaders) {
                header.classList.remove('b-group', 'b-asc', 'b-desc');
            }
            if (groupers) {
                this.renderHeader();
            }
        }
    }
    onStoreChange({ action, records }) {
        const
            { client }            = this,
            { rowManager, store } = client;
        if (store.isGrouped && action === 'move') {
            const
                { field } = store.groupers[0],
                fromRow   = Math.min(...records.reduce((result, record) => {
                    // Get index of the new group
                    result.push(store.indexOf(record.instanceMeta(store).groupParent));
                    // Get index of the old group
                    if (field in record.meta.modified) {
                        const oldGroup = store.groupRecords.find(r => r.meta.groupRowFor === record.meta.modified[field]);
                        if (oldGroup) {
                            result.push(store.indexOf(oldGroup));
                        }
                    }
                    return result;
                }, []));
            rowManager.renderFromRow(rowManager.getRow(fromRow));
        }
    }
    // React to programmatic expand/collapse
    onStoreToggleGroup({ groupRecord }) {
        if (!this.isToggling) {
            this.refreshGrid(groupRecord);
        }
    }
    //endregion
    //region Events - Grid
    /**
     * Store touches when user touches header, used in onElementTouchEnd.
     * @private
     */
    onElementTouchStart(event) {
        const
            me         = this,
            { target } = event,
            header     = target.closest('.b-grid-header'),
            column     = header && me.grid.getColumnFromElement(header);
        // If it's a multi touch, group.
        if (event.touches.length > 1 && column && column.groupable !== false && !me.disabled) {
            me.store.group(column.field);
        }
    }
    /**
     * React to click on headers (to group by that column if [alt] is pressed) and on group rows (expand/collapse).
     * @private
     * @param event
     * @returns {Boolean}
     */
    onElementClick(event) {
        const
            me         = this,
            { store }  = me,
            { target } = event,
            row        = target.closest('.b-group-row'),
            header     = target.closest('.b-grid-header'),
            field      = header?.dataset.column;
        // prevent expand/collapse if disabled or clicked on item with own handler
        if (
            target.classList.contains('b-resizer') ||
            me.disabled ||
            target.classList.contains('b-action-item') ||
            event.handled
        ) {
            return;
        }
        // Header
        if (header && field) {
            const columnGrouper = store.groupers?.find(g => g.field === field);
            // Store has a grouper for this column's field; flip grouper order
            if (columnGrouper && !event.shiftKey) {
                columnGrouper.ascending = !columnGrouper.ascending;
                store.group();
                return false;
            }
            // Group or ungroup
            else if (event.shiftKey) {
                const column = me.grid.columns.get(field);
                if (column.groupable !== false) {
                    if (event.altKey) {
                        store.removeGrouper(field);
                    }
                    else {
                        store.group(field);
                    }
                }
            }
        }
        // Anywhere on group-row if toggleOnRowClick set, otherwise only on icon
        if (row && (me.toggleOnRowClick || event.target.classList.contains('b-group-state-icon'))) {
            me.internalToggleCollapse(DomDataStore.get(row).id, undefined, undefined, event);
            return false;
        }
    }
    /**
     * Toggle groups with [space].
     * @private
     */
    toggleGroup() {
        const
            { grid }        = this,
            { focusedCell } = grid;
        // only catch space when focus is on a group header cell
        if (!this.disabled && !focusedCell.isActionable && focusedCell.record?.isGroupHeader) {
            this.internalToggleCollapse(focusedCell.id);
            // Other features (like context menu) must not process this.
            return true;
        }
        return false;
    }
    //endregion
    updateHeaderHeight() {
        if (!this.isConfiguring) {
            this.client.refreshRows();
        }
    }
    updateShowCount() {
        if (!this.isConfiguring) {
            this.client.refreshRows();
        }
    }
}
Group._$name = 'Group'; GridFeatureManager.registerFeature(Group, true, ['Grid', 'Scheduler']);
GridFeatureManager.registerFeature(Group, false, ['TreeGrid']);
