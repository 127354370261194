import PackMixin from './PackMixin.js';
/**
 * @module Scheduler/eventlayout/VerticalLayout
 */
/**
 * Assists with event layout in vertical mode, handles `eventLayout: none|pack|mixed`
 * @private
 * @mixes Scheduler/eventlayout/PackMixin
 */
export default class VerticalLayout extends PackMixin() {
    static get defaultConfig() {
        return {
            coordProp : 'leftFactor',
            sizeProp  : 'widthFactor'
        };
    }
    // Try to pack the events to consume as little space as possible
    applyLayout(events, resourceRecord, parentEventRecord) {
        const
            me          = this,
            columnWidth = me.scheduler.getResourceWidth(resourceRecord, parentEventRecord),
            {
                barMargin,
                resourceMarginObject : { start : resourceMarginStart, total : resourceMarginTotal }
            }           = me.scheduler.getResourceLayoutSettings(resourceRecord, parentEventRecord),
            eventLayout = me.scheduler.getEventLayout(resourceRecord, parentEventRecord),
            layoutType  = eventLayout.type;
        return me.packEventsInBands(events, (tplData, clusterIndex, slot, slotSize) => {
            // Stretch events to fill available width
            if (layoutType === 'none') {
                tplData.width = columnWidth - resourceMarginTotal;
                tplData.left += resourceMarginStart;
            }
            else {
                // Fractions of resource column
                tplData.widthFactor = slotSize;
                const
                    leftFactor      = tplData.leftFactor = slot.start + (clusterIndex * slotSize),
                    // Number of "columns" in the current slot
                    packColumnCount = Math.round(1 / slotSize),
                    // Index among those columns for current event
                    packColumnIndex = leftFactor / slotSize,
                    // Width with all bar margins subtracted
                    availableWidth  = columnWidth - resourceMarginTotal - barMargin * (packColumnCount - 1);
                // Allowing two events to overlap? Slightly offset the second
                if (layoutType === 'mixed' && packColumnCount === 2) {
                    tplData.left += leftFactor * columnWidth / 5 + barMargin;
                    tplData.width = columnWidth - leftFactor * columnWidth / 5 - barMargin * 2;
                    tplData.zIndex = 5 + packColumnIndex;
                }
                // Pack by default
                else {
                    // Fractional width
                    tplData.width = slotSize * availableWidth;
                    // Translate to absolute position
                    tplData.left += leftFactor * availableWidth + resourceMarginStart + barMargin * packColumnIndex;
                }
            }
            tplData.cls['b-sch-event-narrow'] = tplData.width < me.scheduler.narrowEventWidth;
        });
    }
}
VerticalLayout._$name = 'VerticalLayout';