import BrowserHelper from '../../../Core/helper/BrowserHelper.js';
export const RowsRange = {
    all     : 'all',
    visible : 'visible'
};
// Paper format behaves different between in portrait/landscape mode and in print/export. For the most popular format
// A3/A4 we pick values which work best both for print and export. Legal and Letter formats do not require any hacks.
export const PaperFormat = {
    A6 : {
        width  : 4.11,
        height : 5.81
    },
    A5 : {
        width  : 5.81,
        height : 8.25
    },
    A4 : {
        width           : 8.25,
        height          : 11.69,
        portraitWidth   : 8.3,
        portraitHeight  : 11.7,
        landscapeWidth  : 11.7,
        landscapeHeight : 8.26
    },
    A3 : {
        width           : 11.69,
        height          : 16.49,
        portraitWidth   : 11.7,
        // 16.54 works perfect in print, but in export (puppeteer) it results in extra empty page
        portraitHeight  : 16.49,
        landscapeWidth  : 16.54,
        landscapeHeight : 11.68
    },
    Legal : {
        width           : 8.5,
        height          : 14,
        portraitWidth   : 8.5,
        portraitHeight  : 14,
        landscapeWidth  : 14,
        landscapeHeight : 8.15
    },
    Letter : {
        width           : 8.5,
        height          : 11,
        portraitWidth   : 8.5,
        portraitHeight  : 10.95,
        landscapeWidth  : 11,
        landscapeHeight : 8.3
    }
};
export function getPrintPaperSizeAdjustments() {
    if (BrowserHelper.isFirefox) {
        return {
            portraitWidth   : 0,
            portraitHeight  : 0,
            landscapeWidth  : 0,
            landscapeHeight : 0.26
        };
    }
    if (BrowserHelper.isSafari) {
        return {
            portraitWidth   : 0,
            portraitHeight  : 0.1,
            landscapeWidth  : 0,
            landscapeHeight : 0.5
        };
    }
    return {
        portraitWidth   : 0,
        portraitHeight  : 0,
        landscapeWidth  : 0,
        landscapeHeight : 0.25
    };
};
export const Orientation = {
    portrait  : 'portrait',
    landscape : 'landscape'
};
export const FileFormat = {
    pdf : 'pdf',
    png : 'png'
};
export const FileMIMEType = {
    pdf : 'application/pdf',
    png : 'image/png'
};
