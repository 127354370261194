let counter = 1;
export default class Queue {
    constructor(name = `Queue #${counter++}`) {
        this.name = '';
        this.length = 0;
        this.finishedStep = 0;
        this.stacks = [];
        this.ongoing = Promise.resolve();
        this.handleReject = () => { };
        this.name = name;
    }
    debug(message) {
        if (globalThis.bryntum?.DEBUG) {
            console.log(this.name, message);
        }
    }
    queue(fn, handleReject = this.handleReject) {
        const currentStep = this.length++;
        if (globalThis.bryntum?.DEBUG) {
            const stack = new Error().stack;
            this.stacks.push(stack);
            this.debug(`Queued fn #${currentStep}`);
            this.debug(stack);
        }
        const result = this.ongoing.then(async () => {
            this.debug(`Running queued fn #${currentStep}`);
            const result = await fn();
            this.finishedStep++;
            this.debug(`Finished queued fn #${currentStep}`);
            return result;
        });
        this.ongoing = result.catch(handleReject);
        return result;
    }
}
Queue._$name = 'Queue';