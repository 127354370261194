import DomHelper from '../../Core/helper/DomHelper.js';
import InstancePlugin from '../../Core/mixin/InstancePlugin.js';
import GridFeatureManager from '../../Grid/feature/GridFeatureManager.js';
import Delayable from '../../Core/mixin/Delayable.js';
import Widget from '../../Core/widget/Widget.js';
/**
 * @module Scheduler/feature/ScheduleContext
 */
/**
 * Allow visually selecting a schedule "cell" by clicking, or {@link #config-triggerEvent any other pointer gesture}.
 *
 * This feature is **disabled** by default
 *
 * ```javascript
 * const scheduler = new Scheduler({
 *     features : {
 *         // Configure as a truthy value to enable the feature
 *         scheduleContext : {
 *             triggerEvent : 'hover',
 *             renderer     : (context, element) => {
 *                 element.innerText = '😎';
 *             }
 *         }
 *     }
 * });
 * ```
 *
 * The contextual details are available in the {@link #property-context} property.
 *
 * **Note that the context is cleared upon change of {@link Scheduler.view.Scheduler#property-viewPreset}
 * such as when zooming in or out.**
 *
 * {@inlineexample Scheduler/feature/ScheduleContext.js}
 *
 * @extends Core/mixin/InstancePlugin
 * @classtype scheduleContext
 * @demo Scheduler/schedule-context
 * @feature
 */
export default class ScheduleContext extends InstancePlugin.mixin(Delayable) {
    static get $name() {
        return 'ScheduleContext';
    }
    static configurable = {
        /**
         * The pointer event type to use to update the context. May be `'hover'` to highlight the
         * tick context when moving the mouse across the timeline.
         * @config {'click'|'hover'|'contextmenu'|'mousedown'}
         * @default
         */
        triggerEvent : 'click',
        /**
         * A function (or the name of a function) which may mutate the contents of the context overlay
         * element which tracks the active resource/tick context. Use this when simple, decorative or
         * informational HTML is required.
         *
         * Use the {@link #config-widget} option to show a widget with interaction in a tick cell.
         *
         * @config {String|Function}
         * @param {TimelineContext} context The context being highlighted.
         * @param {HTMLElement} element The context highlight element. This will be empty each time.
         * @returns {void}
         */
        renderer : null,
        /**
         * The widget shown in timeline ticks. The widget will be sized and positioned to fit the tick.
         *
         * Before being shown, it will be primed with a property called `timelineContext` which is a {@link TimelineContext}
         * which describes exactly which tick is active.
         *
         * The widget's {@link Core.widget.Widget#property-owner} will be the Scheduler.
         *
         * The {@link Core.widget.Widget#property-owner}'s {@link Scheduler.view.Scheduler#property-timelineContext}
         * will also reference the current context.
         *
         * @member {Core.widget.Widget} widget
         * @property {TimelineContext} timelineContext The timeline context for which the widget was activated.
         * @readonly
         */
        /**
         * A widget config describing the widget to show in the context tick.
         * @config {ContainerItemConfig}
         */
        widget : {
            $config : ['lazy', 'nullify'],
            value   : null
        },
        /**
         * The active context.
         * @member {TimelineContext} timelineContext
         * @readonly
         */
        context : {
            $config : {
                // Reject non-changes so that when using mousemove, we only update the context
                // when it changes.
                equal(c1, c2) {
                    return c1?.index === c2?.index &&
                        c1?.tickParentIndex === c2?.tickParentIndex &&
                        !((c1?.tickStartDate || 0) - (c2?.tickStartDate || 0)) &&
                        c1?.eventRecord === c2?.eventRecord;
                }
            }
        },
        /**
         * By default, the ScheduleContext feature hides its widget or element when the
         * pointer is over event/task bar.
         *
         * Set this property to `true` to activate when over an event/task bar.
         * @prp {Boolean} activateOnEvent
         * @default false
         */
        activateOnEvent : null,
        /**
         * By default, the ScheduleContext feature hides its widget or element when the
         * pointer is over a tick in which an event/task bar exists.
         *
         * Set this property to `true` to activate when an event/task bar also exists in the context tick.
         * @prp {Boolean} shareWithEvent
         * @default false
         */
        shareWithEvent : null
    };
    /**
     * The contextual information about which cell was clicked on and highlighted.
     *
     * When the {@link Scheduler.view.Scheduler#property-viewPreset} is changed (such as when zooming)
     * the context is cleared and the highlight is removed.
     *
     * @member {Object} context
     * @property {Scheduler.view.TimelineBase} context.source The owning Scheduler
     * @property {Date} context.date Date at mouse position
     * @property {Scheduler.model.TimeSpan} context.tick A record which encapsulates the time axis tick clicked on.
     * @property {Number} context.tickIndex The index of the time axis tick clicked on.
     * @property {Date} context.tickStartDate The start date of the current time axis tick
     * @property {Date} context.tickEndDate The end date of the current time axis tick
     * @property {Grid.row.Row} context.row Clicked row (in horizontal mode only)
     * @property {Number} context.index Index of clicked resource
     * @property {Scheduler.model.ResourceModel} context.resourceRecord Resource record
     * @property {MouseEvent} context.event Browser event
     */
    construct(client, config) {
        super.construct(client, config);
        const
            { triggerEvent } = this,
            listeners        = {
                datachange              : 'syncContextElement',
                timeaxisviewmodelupdate : 'onTimeAxisViewModelUpdate',
                presetchange            : 'clear',
                thisObj                 : this
            };
        // If mousemove is our trigger, we can use the client's timelineContextChange event
        if (triggerEvent === 'mouseover') {
            listeners.timelineContextChange = 'onTimelineContextChange';
        }
        // Otherwise, we have to listen for the required events on Schedule.
        // If activateOnEvent is set, the trigger event will also be listened for on events/tasks.
        else {
            // Context menu will be expected to update the context if click or mousedown
            // is the triggerEvent. Context menu is a mousedown gesture.
            if (triggerEvent === 'click' || triggerEvent === 'mousedown') {
                listeners.schedulecontextmenu = 'onScheduleContextGesture';
            }
            listeners[`schedule${triggerEvent}`] = 'onScheduleContextGesture';
        }
        client.ion(listeners);
        client.rowManager.ion({
            rowheight : 'syncContextElement',
            thisObj   : this
        });
    }
    changeTriggerEvent(triggerEvent) {
        // Both these things should route through to using the client's timelineContextChange event
        if (triggerEvent === 'hover' || triggerEvent === 'mousemove') {
            triggerEvent = 'mouseover';
        }
        return triggerEvent;
    }
    changeWidget(widget, oldWidget) {
        if (widget) {
            return Widget.reconfigure(oldWidget, {
                ...widget,
                owner         : this.client,
                positioned    : true,
                appendTo      : this.client.timeAxisSubGridElement,
                hidden        : true,
                showAnimation : false
            }, this);
        }
        oldWidget.destroy();
    }
    get element() {
        if (!this._element) {
            this._element = this.widget?.element || DomHelper.createElement({
                parent : this.client.timeAxisSubGridElement
            });
            this.element.classList.add('b-schedule-selected-tick');
        }
        return this._element;
    }
    // Handle the Client's own timelineContextChange event which it maintains on mousemove
    onTimelineContextChange({ context }) {
        this.context = context;
    }
    // Handle the scheduleclick or eventclick Scheduler events if we re not using mouseover
    onScheduleContextGesture(context) {
        this.context = context;
    }
    onTimeAxisViewModelUpdate({ source : timeAxisViewModel }) {
        // Just a mutation of existing tick details, sync the element
        if (timeAxisViewModel.timeAxis.includes(this.context?.tick)) {
            this.syncContextElement();
        }
        // The tick has gone, we have moved to a new ViewPreset, so clear the context.
        else {
            this.clear();
        }
    }
    /**
     * Clears the current visible context element
     */
    clear() {
        this.context = null;
    }
    changeContext(context) {
        // If we own any floating menu / combo picker open, don't move the widget
        if (!this.widget?.query(widget => widget.floating && widget.isVisible)) {
            // If there is an event in this tick, and we are not configured to share with
            // event bars, we must hide
            if (!this.shareWithEvent && this.hasEvent(context)) {
                return null;
            }
            // If activateOnEvent is false, hide when over an event
            return (!this.activateOnEvent && context?.eventElement) ? null : context || null;
        }
    }
    updateActivateOnEvent(activateOnEvent) {
        const me = this;
        // activateOnEvent implies that we can share a tick with an event bar.
        if (activateOnEvent) {
            const { triggerEvent, client } = me;
            me.shareWithEvent = true;
            // If we are to activate on event bars and they want to avctivate on click or mousedown etc,
            // then we need the appropriate event/task listener.
            if (triggerEvent !== 'mouseover') {
                client.ion({
                    name                                            : 'scheduleContextTrigger',
                    [`${client.scheduledEventName}${triggerEvent}`] : 'onScheduleContextGesture',
                    thisObj                                         : me
                });
            }
        }
        // Revert to original setting if we are inactive when mouse over event bar
        else {
            me.shareWithEvent = me.config.shareWithEvent;
            me.detachListeners('scheduleContextTrigger');
        }
    }
    /**
     * Utility function to see if there is an event bar coinciding with the passed timeline context
     * @param {TimelineContext} context The tick context to check
     * @returns {Boolean} `true` if there is an event for the passed tick context
     * @internal
     */
    hasEvent(context) {
        return Boolean(context && this.client.eventStore.getEvents({
            resourceRecord : context.resourceRecord,
            startDate      : context.tickStartDate,
            endDate        : context.tickEndDate
        })?.length);
    }
    cursorTrackingSuspended = 0;
    updateContext(context, oldContext) {
        this.syncContextElement();
    }
    syncContextElement() {
        const
            me = this,
            {
                client,
                element,
                widget,
                context
            } = me,
            {
                isVertical
            }   = client,
            row = context && (isVertical ? client.rowManager.rows[0] : client.getRowFor(context.resourceRecord));
        if (context && row && this.enabled && !context.domEvent.buttons) {
            const
                me  = this,
                { renderer } = me,
                { style }    = element,
                {
                    tickStartDate,
                    tickEndDate,
                    resourceRecord
                }            = context,
                // get the position clicked based on dates
                renderData   = client.currentOrientation.getTimeSpanRenderData({
                    startDate   : tickStartDate,
                    endDate     : tickEndDate,
                    startDateMS : tickStartDate.getTime(),
                    endDateMS   : tickEndDate.getTime()
                }, resourceRecord);
            let top, width, height;
            if (isVertical) {
                top = renderData.top;
                width = renderData.resourceWidth;
                height = renderData.height;
            }
            else {
                top = row.top;
                width = renderData.width;
                height = row.height;
            }
            // In case we updated on a datachange action : 'remove' or 'add' event.
            context.index = row.index;
            element.classList.add('b-schedule-selected-tick');
            // Add an extra tagging class if our element *contains* a widget
            element.classList.toggle('b-contains-widget', Boolean(element.querySelector('.b-widget')));
            // Move to current cell
            if (widget) {
                // If we are showing the widget here, it must start from being hidden.
                // The show lifecycle point will be used by apps to fix the UI to match the context.
                widget.hide(false);
                // Widgets are brought to top, so cannot share with events
                if (!context.eventRecord) {
                    widget.setConfig({
                        width,
                        height,
                        timelineContext : context,
                        x               : renderData.left,
                        y               : top
                    });
                    widget.show({ animate : false });
                }
            }
            else if (element) {
                style.width = `${width}px`;
                style.height = `${height}px`;
                DomHelper.setTranslateXY(element, renderData.left, top);
                // Undo any contents added by the renderer last time round
                element.innerHTML = '';
                // Show the context and the element to the renderer
                renderer && me.callback(renderer, me, [context, element]);
                style.display = '';
            }
        }
        else {
            widget?.hide(false) || (element.style.display = 'none');
        }
    }
}
ScheduleContext.featureClass = 'b-scheduler-context';
ScheduleContext._$name = 'ScheduleContext'; GridFeatureManager.registerFeature(ScheduleContext, false, ['Scheduler']);
