import GridFeatureManager from '../../Grid/feature/GridFeatureManager.js';
import AbstractTimeRanges from '../../Scheduler/feature/AbstractTimeRanges.js';
import AttachToProjectMixin from '../../Scheduler/data/mixin/AttachToProjectMixin.js';
/**
 * @module Gantt/feature/ProjectLines
 */
/**
 * This feature draws the vertical lines in the schedule area, indicating project start/end dates
 * and also its {@link Gantt.model.ProjectModel#field-statusDate status date}. The latter can be disabled
 * with the {@link #config-showStatusDate} config.
 *
 * {@inlineexample Gantt/guides/gettingstarted/basic.js}
 *
 * This feature is **enabled** by default.
 *
 * @extends Scheduler/feature/TimeRanges
 * @demo Gantt/advanced
 * @classtype projectLines
 * @feature
 */
export default class ProjectLines extends AbstractTimeRanges.mixin(AttachToProjectMixin) {
    //region Config
    static get $name() {
        return 'ProjectLines';
    }
    static get defaultConfig() {
        return {
            showHeaderElements : true,
            cls                : 'b-gantt-project-line',
            /**
             * A boolean specifying whether to show the project's {@link Gantt.model.ProjectModel#field-statusDate}
             *
             * @config {Boolean}
             * @default
             */
            showStatusDate : false
        };
    }
    //endregion
    //region Project
    attachToProject(project) {
        super.attachToProject(project);
        project.ion({
            name             : 'project',
            refresh          : this.onProjectRefresh,
            statusDateChange : this.onProjectRefresh,
            thisObj          : this
        });
    }
    //endregion
    //region Init
    // We must override the TimeRanges superclass implementation which ingests the client's project's
    // timeRangeStore. We implement our own store
    startConfigure() {}
    updateLocalization() {
        this.renderRanges();
    }
    //endregion
    onProjectRefresh() {
        this.renderRanges();
    }
    shouldRenderRange(range) {
        const { client } = this;
        return client.timeAxis.dateInAxis(range.startDate);
    }
    get timeRanges() {
        const { startDate, endDate, statusDate } = this.client.project;
        const ranges = startDate && endDate ? [
            {
                name : this.L('L{Project Start}'),
                startDate
            },
            {
                name      : this.L('L{Project End}'),
                startDate : endDate
            }
        ] : [];
        if (statusDate && this.showStatusDate) {
            ranges.push({
                name      : this.L('L{statusDate}'),
                startDate : statusDate
            });
        }
        return ranges;
    }
}
ProjectLines._$name = 'ProjectLines'; GridFeatureManager.registerFeature(ProjectLines, true, 'Gantt');
