import Model from '../../Core/data/Model.js';
import PartOfProject from '../data/mixin/PartOfProject.js';
import { CalendarIntervalMixin } from '../../Engine/calendar/CalendarIntervalMixin.js';
/**
 * @module SchedulerPro/model/CalendarIntervalModel
 */
/**
 * This is a documentation-only class, representing an interval in the {@link SchedulerPro/model/CalendarModel calendar}
 *
 * Please refer to the [calendars guide](#SchedulerPro/guides/basics/calendars.md) for details
 *
 * @extends Core/data/Model
 */
export default class CalendarIntervalModel extends PartOfProject(CalendarIntervalMixin.derive(Model)) {
    // NOTE: Leave field defs at top to be picked up by jsdoc
    //region Fields
    /**
     * Interval type. Specifies which kind the interval is - a week or an exception.
     *
     * @field {'Week'|'Exception'} type
     */
    /**
     * The start date of the fixed (not recurrent) time interval.
     *
     * @field {Date} startDate
     */
    /**
     * The end date of the fixed (not recurrent) time interval.
     *
     * @field {Date} endDate
     */
    /**
     * The start date of the recurrent time interval. Should be specified as any expression, recognized
     * by the excellent [later](http://bunkat.github.io/later/) library.
     *
     * Please refer to [`later` documentation](https://bunkat.github.io/later/parsers.html) for the details about
     * supported parsers.
     *
     * For simple scenarios, you can use string format. We recommend using `later.parse` for
     * advanced use cases only.
     *
     * ```javascript
     * // occurs at 10:15am every day
     * recurrentStartDate  : 'at 10:15 am'
     *
     * // occurs everyday at 13:00 starting from 28 Feb.
     * recurrentStartDate  : 'at 13:00 every 1 day of the year starting on the 59'
     *
     * // For advanced use cases
     * // occurs the 2nd minute every hour
     * recurrentStartDate  : later.parse.recur().on(2).minute()
     *
     * // fires on the last day of every month at 5am
     * recurrentStartDate  : later.parse.recur().on(5).hour().last().dayOfMonth();
     * ```
     *
     * @field {String} recurrentStartDate
     */
    /**
     * The end date of the recurrent time interval. Should be specified as any expression, recognized
     * by the excellent [later](http://bunkat.github.io/later/) library.
     *
     * Please refer to [`later` documentation](https://bunkat.github.io/later/parsers.html) for the details about
     * supported parsers.
     *
     * For simple scenarios, you can use string format. We recommend using `later.parse` for
     * advanced use cases only.
     *
     * ```javascript
     * // occurs at 10:30am every day
     * recurrentEndDate  : 'at 10:30 am'
     *
     * // occurs everyday at 14:00 Starting from 28 Feb.
     * recurrentEndDate  : 'at 14:00 every 1 day of the year starting on the 59'
     *
     * // For advanced use cases
     * // occurs the 3rd minute every hour
     * recurrentEndDate  : later.parse.recur().on(3).minute()
     *
     * // occurs on the last day of every month at 6am
     * recurrentEndDate  : later.parse.recur().on(6).hour().last().dayOfMonth();
     * ```
     *
     * The field value can be omitted when {@link #field-recurrentStartDate} has a recurrence rule provided.
     * In this case the interval will be calculated based on {@link #field-recurrentStartDate start date schedule}
     * only and its end will be calculated as the end of the day found based on the
     * start date schedule.
     *
     * @field {String} recurrentEndDate
     */
    /**
     * The priority of the interval. By default, recurrent intervals are assigned the priority 20 and static - priority 30.
     *
     * This field influences which interval is selected for `isWorking` value in case of intervals intersection.
     *
     * Intervals intersection occurs for example in this case:
     * ```javascript
     * {
     *     id        : 1,
     *     name      : 'My Calendar',
     *     intervals : [
     *         {
     *             recurrentStartDate : 'at 08:00',
     *             recurrentEndDate   : 'at 16:00',
     *             isWorking          : true
     *         },
     *         {
     *             recurrentStartDate : 'on Sat at 00:00',
     *             recurrentEndDate   : 'on Mon at 00:00',
     *             isWorking          : false
     *         }
     *     ],
     *     unspecifiedTimeIsWorking : false
     * }
     * ```
     *
     * The intention here is to define a calendar with "normal" working time `08:00-16:00` and non-working weekend days.
     * However, the intervals intersects - for weekend days, the timespan `08:00-16:00` presents in both intervals,
     * with the different `isWorking` flag.
     *
     * Which interval "wins" in such case of intersection is defined by the following rules:
     *
     * - Intervals from the child calendar always wins over the parent's
     * - Static intervals wins over the recurrent intervals (in the same calendar)
     * - Between the static intervals of the calendar, the one with bigger `priority` field
     *   value wins over the one with smaller `priority` field value.
     * - Between the static intervals of the calendar with the same `priority`, the one with bigger index wins over
     *   the one with smaller index ("last win")
     * - Between the recurrent intervals of the calendar, the one with bigger `priority` field
     *   value wins over the one with smaller `priority` field value
     * - Between the recurrent intervals of the calendar with the same `priority`, the ones with bigger index wins over
     *   the ones with smaller index ("last win")
     *
     * @field {Number} priority
     */
    /**
     * The "is working" flag, which defines what kind of interval this is - either working or non-working. Default value is `false`,
     * denoting non-working intervals.
     *
     * @field {Boolean} isWorking
     * @default false
     */
    /**
     * Working time for the interval (works if the interval is of `Exception` type).
     * @field {SchedulerPro.data.calendareditor.AvailabilityRangeStore} availability
     * @accepts {SchedulerPro.model.calendareditor.AvailabilityRangeModel[]|AvailabilityRangeModelConfig[]}
     */
    /**
     * Identifier of the calendar editor interval this record is part of.
     * Sometimes many `CalendarIntervalModel` instances represent a single calendar editor interval
     * and in this case this field have the same value for such group of records.
     *
     * @field {String} compositeCode
     */
    /**
     * A CSS class to add to the element visualizing this interval, when using the NonWorkingTime feature.
     * <div class="note">Note that only non working intervals are visible.</div>
     *
     * @field {String} cls
     */
    /**
     * A CSS class used to add an icon to the element visualizing this interval, when using the NonWorkingTime feature.
     * <div class="note">Note that only non working intervals are visible.</div>
     *
     * @field {String} iconCls
     */
    //endregion
    //region Methods
    /**
     * Whether this interval is recurrent (both `recurrentStartDate` and `recurrentEndDate` are present and parsed correctly
     * by the `later` library).
     *
     * @method isRecurrent
     * @returns {Boolean}
     */
    /**
     * Whether this interval is static - both `startDate` and `endDate` are present.
     *
     * @method isStatic
     * @returns {Boolean}
     */
    /**
     * Returns an internal representation of the recurrent start date from the `later` library.
     *
     * @method getStartDateSchedule
     * @returns {Object}
     */
    /**
     * Returns an internal representation of the recurrent end date from the `later` library.
     *
     * @method getEndDateSchedule
     * @returns {Object}
     */
    //endregion
    //region Config
    static get $name() {
        return 'CalendarIntervalModel';
    }
    //endregion
}
CalendarIntervalModel._$name = 'CalendarIntervalModel';