import GridFeatureManager from '../../Grid/feature/GridFeatureManager.js';
import SchedulerLabels from '../../Scheduler/feature/Labels.js';
/**
 * @module Gantt/feature/Labels
 */
/**
 * Configuration object for a label used by the Labels feature.
 * @typedef {Object} GanttLabelConfig
 * @property {String} field The name of a field in the {@link Gantt.model.TaskModel}
 * @property {Function} renderer A function, that is passed an object containing `taskRecord`, and `domConfig`
 * properties. Either return the HTML/DomConfig to display as the label, or mutate the provided `domConfig` object.
 * @property {Gantt.model.TaskModel} renderer.taskRecord The Task record
 * @property {DomConfig} renderer.domConfig The DOMConfig representing the label
 * @property {Object} thisObj The `this` reference to use in the `renderer`.
 * @property {InputFieldConfig|Core.widget.Field} editor If the label is to be editable, a field configuration object with a
 * `type` property, or an instantiated Field. **The `editor` property is mandatory for editing to work**.
 */
/**
 * A feature that lets you display a label on each side of a Gantt task bar. See
 * {@link Scheduler/feature/Labels} for more information.
 *
 * ```javascript
 * new Gantt({
 *    features : {
 *        labels : {
 *            // Label rendered before the task bar with content from the record's `name` field
 *            before : {
 *                field  : 'name',
 *                editor : {
 *                    type : 'textfield'
 *                }
 *            },
 *            // Label rendered below the task bar with custom content
 *            // from a renderer
 *            bottom : {
 *                renderer : ({ taskRecord }) => `ID: ${taskRecord.id}`
 *            }
 *        }
 *    }
 * });
 * ```
 * This feature is **disabled** by default.
 *
 * For info on enabling it, see {@link Grid.view.mixin.GridFeatures}.
 *
 * @extends Scheduler/feature/Labels
 * @demo Gantt/labels
 * @classtype labels
 * @feature
 *
 * @typings Scheduler.feature.Labels -> Scheduler.feature.SchedulerLabels
 */
export default class Labels extends SchedulerLabels {
    static get $name() {
        return 'Labels';
    }
    static get pluginConfig() {
        return {
            chain : ['onTaskDataGenerated']
        };
    }
    static configurable = {
        /**
         * Top label configuration object.
         * @config {GanttLabelConfig}
         */
        top : null,
        /**
         * Configuration object for the label which appears *after* the task bar in the current writing direction.
         * @config {GanttLabelConfig}
         */
        after : null,
        /**
         * Right label configuration object.
         * @config {GanttLabelConfig}
         */
        right : null,
        /**
         * Bottom label configuration object.
         * @config {GanttLabelConfig}
         */
        bottom : null,
        /**
         * Configuration object for the label which appears *before* the task bar in the current writing direction.
         * @config {GanttLabelConfig}
         */
        before : null,
        /**
         * Left label configuration object.
         * @config {GanttLabelConfig}
         */
        left : null
    };
    onTaskDataGenerated(data) {
        this.onEventDataGenerated(data);
    }
}
Labels._$name = 'Labels'; GridFeatureManager.registerFeature(Labels, false, 'Gantt');
