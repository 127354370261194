import Model from '../../Core/data/Model.js';
import DateHelper from '../../Core/helper/DateHelper.js';
import RecurrenceDayRuleEncoder from '../data/util/recurrence/RecurrenceDayRuleEncoder.js';
import DailyRecurrenceIterator from '../data/util/recurrence/DailyRecurrenceIterator.js';
import WeeklyRecurrenceIterator from '../data/util/recurrence/WeeklyRecurrenceIterator.js';
import MonthlyRecurrenceIterator from '../data/util/recurrence/MonthlyRecurrenceIterator.js';
import YearlyRecurrenceIterator from '../data/util/recurrence/YearlyRecurrenceIterator.js';
/**
 * @module Scheduler/model/RecurrenceModel
 */
const recurrenceIterators = {};
[DailyRecurrenceIterator, WeeklyRecurrenceIterator, MonthlyRecurrenceIterator, YearlyRecurrenceIterator].forEach(it => {
    recurrenceIterators[it.frequency] = it;
});
const
    frequencyToLater = {
        DAILY   : 'days',
        WEEKLY  : 'weeks',
        MONTHLY : 'months',
        YEARLY  : 'years'
    },
    dayNameToLater = {
        MO : 'Mon',
        TU : 'Tue',
        WE : 'Wed',
        TH : 'Thu',
        FR : 'Fri',
        SA : 'Sat',
        SU : 'Sun'
    };
function convertStringOfIntegerItemsValue(value) {
    if (value) {
        if (typeof value == 'string') {
            value = value.split(',').map(item => parseInt(item, 10));
        }
    }
    else {
        value = null;
    }
    return value;
}
function convertStringOfItemsValue(value) {
    if (value) {
        if (typeof value == 'string') {
            value = value.split(',');
        }
    }
    else {
        value = null;
    }
    return value;
}
function isEqualAsString(value1, value2) {
    return String(value1) === String(value2);
}
function convertInteger(value) {
    if (this.defaultValue && value === undefined) {
        return this.defaultValue;
    }
    if (this.allowNull && value == null) {
        return null;
    }
    value = parseInt(value);
    return isNaN(value) ? undefined : value;
}
/**
 * This class encapsulates the recurrence information of a {@link Scheduler/model/mixin/RecurringTimeSpan}. The
 * {@link #property-rule} describes how the timespan will repeat, and is based on
 * [RFC-5545](https://tools.ietf.org/html/rfc5545#section-3.3.10).
 *
 * Examples:
 * ```javascript
 * const recurrenceModel = new RecurrenceModel();
 * // every weekday
 * recurrenceModel.rule = 'FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,TU,WE,TH,FR';
 *
 * // every other week
 * recurrenceModel.rule = 'FREQ=WEEKLY;INTERVAL=2';
 *
 * // every 5th day, until May 31st, 2025
 * recurrenceModel.rule = 'FREQ=DAILY;INTERVAL=5;UNTIL=20250531T000000';
 * ```
 * It is a subclass of {@link Core.data.Model} class.
 * Please refer to the documentation for that class to become familiar with the base interface of this class.
 *
 * The data source for the fields in this class can be customized by subclassing this class.
 *
 * @extends Core/data/Model
 */
export default class RecurrenceModel extends Model {
    static $name = 'RecurrenceModel';
    /**
     * Indicates that this is a `RecurrenceModel` class instance
     * (allows to avoid using `instanceof`).
     * @property {Boolean}
     * @readonly
     */
    get isRecurrenceModel() {
        return true;
    }
    //region Fields
    static fields = [
        { name : 'date', type : 'date' },
        /**
         * Field defines the recurrence frequency. Supported values are: `DAILY`, `WEEKLY`, `MONTHLY`, `YEARLY`.
         * @field {'DAILY'|'WEEKLY'|'MONTHLY'|'YEARLY'} frequency
         */
        { name : 'frequency', defaultValue : 'DAILY' },
        /**
         * Field defines how often the recurrence repeats.
         * For example, if the recurrence is weekly its interval is 2, then the timespan repeats every two weeks.
         * @field {Number} interval
         */
        { name : 'interval', defaultValue : 1, convert : convertInteger },
        /**
         * End date of the recurrence. Specifies when the recurrence ends.
         * The value is optional, the recurrence can as well be stopped using {@link #field-count} field value.
         * @field {Date} endDate
         */
        { name : 'endDate', type : 'date' },
        /**
         * Specifies the number of occurrences after which the recurrence ends.
         * The value includes the associated timespan itself so values less than 2 make no sense.
         * The field is optional, the recurrence as well can be stopped using {@link #field-endDate} field value.
         * @field {Number} count
         */
        { name : 'count', allowNull : true, convert : convertInteger },
        /**
         * Specifies days of the week on which the timespan should occur.
         * An array of string values `SU`, `MO`, `TU`, `WE`, `TH`, `FR`, `SA`
         * corresponding to Sunday, Monday, Tuesday, Wednesday, Thursday, Friday, and Saturday days of the week.
         * Each value can also be preceded by a positive (+n) or negative (-n) integer.
         * If present, this indicates the nth occurrence of a specific day within the monthly or yearly recurrence.
         *
         * **Not applicable** for daily {@link #field-frequency}.
         * @field {String[]} days
         */
        {
            name    : 'days',
            convert : convertStringOfItemsValue,
            isEqual : isEqualAsString
        },
        /**
         * Specifies days of the month on which the timespan should occur.
         * An array of integer values (-31..-1 - +1..+31, negative values mean counting backwards from the month end).
         * **Applicable only** for monthly and yearly {@link #field-frequency}.
         * @field {Number[]} monthDays
         */
        {
            name    : 'monthDays',
            convert : convertStringOfIntegerItemsValue,
            isEqual : isEqualAsString
        },
        /**
         * Specifies months of the year on which the timespan should occur.
         * An array of integer values (1 - 12).
         * **Applicable only** for yearly {@link #field-frequency}.
         * @field {Number[]} months
         */
        {
            name    : 'months',
            convert : convertStringOfIntegerItemsValue,
            isEqual : isEqualAsString
        },
        /**
         * The positions to include in the recurrence. The values operate on a set of recurrence instances **in one interval** of the recurrence rule.
         * An array of integer values (valid values are 1 to 366 or -366 to -1, negative values mean counting backwards from the end of the built list of occurrences).
         * **Not applicable** for daily {@link #field-frequency}.
         * @field {Number[]} positions
         */
        {
            name    : 'positions',
            convert : convertStringOfIntegerItemsValue,
            isEqual : isEqualAsString
        }
    ];
    //endregion Fields
    //region Construction
    construct(data = {}) {
        const
            me = this,
            {
                rule,
                timeSpan,
                laterJsSchedule
            } = data;
        me._suspendedTimeSpanNotifying = 0;
        delete data.timeSpan;
        delete data.rule;
        delete data.laterJsSchedule;
        super.construct(...arguments);
        if (rule) {
            me.suspendTimeSpanNotifying();
            me.rule = rule;
            me.resumeTimeSpanNotifying();
        }
        me.timeSpan = timeSpan;
        if (laterJsSchedule) {
            me.laterJsSchedule = laterJsSchedule;
        }
    }
    static processData(data, ignoreDefaults = false, store, record, forceUseRaw) {
        const
            { fieldMap }         = this,
            { name, dataSource } = fieldMap.date;
        let date = data[dataSource || name];
        if (typeof date === 'string') {
            date = fieldMap.date.convert(date, data, record);
        }
        if (date) {
            Object.assign(data, this.getDateValues(date));
            data[dataSource || name] = date;
        }
        return super.processData(...arguments);
    }
    //endregion Construction
    get dateFormat() {
        return this._dateFormat || 'YYYYMMDDTHHmmss';
    }
    set dateFormat(format) {
        this._dateFormat = format;
    }
    get recurrenceIterator() {
        return recurrenceIterators[this.frequency];
    }
    /**
     * The timespan this recurrence is associated with.
     * @property {Scheduler.model.TimeSpan}
     */
    get timeSpan() {
        return this._timeSpan;
    }
    set timeSpan(value) {
        this._timeSpan = value;
    }
    get timeSpanDate() {
        return this.timeSpan?.startDate;
    }
    static getDateValues(date) {
        if (date) {
            return {
                days      : [RecurrenceDayRuleEncoder.encodeDay(date.getDay())],
                monthDays : [date.getDate()],
                months    : [date.getMonth() + 1]
            };
        }
    }
    getDateValues(date) {
        date = this.date || this.timeSpanDate;
        return this.constructor.getDateValues(date);
    }
    getRRule(includeTimeSpanData = false) {
        const
            me     = this,
            date   = me.date || me.timeSpanDate,
            result = [];
        if (me.frequency) {
            result.push(`FREQ=${me.frequency}`);
            let { days, monthDays, months } = me;
            // if it's told to include timeSpan.startDate values into the RRULE
            if (includeTimeSpanData && date) {
                const {
                    days      : defaultDays,
                    monthDays : defaultMonthDays,
                    months    : defaultMonths
                } = me.constructor.getDateValues(date);
                switch (me.frequency) {
                    case 'WEEKLY' :
                        if (!days) {
                            days = defaultDays;
                        }
                        break;
                    case 'MONTHLY' :
                        if (!monthDays?.length) {
                            monthDays = defaultMonthDays;
                        }
                        break;
                    case 'YEARLY' :
                        if (!monthDays?.length) {
                            monthDays = defaultMonthDays;
                        }
                        if (!months?.length) {
                            months = defaultMonths;
                        }
                        break;
                }
            }
            if (me.interval > 1) {
                result.push(`INTERVAL=${me.interval}`);
            }
            if (days?.length) {
                result.push('BYDAY=' + days.join(','));
            }
            if (monthDays?.length) {
                result.push('BYMONTHDAY=' + monthDays.join(','));
            }
            if (months?.length) {
                result.push('BYMONTH=' + months.join(','));
            }
            if (me.count) {
                result.push(`COUNT=${me.count}`);
            }
            if (me.endDate) {
                result.push('UNTIL=' + DateHelper.format(me.endDate, me.dateFormat));
            }
            if (me.positions?.length) {
                result.push('BYSETPOS=' + me.positions.join(','));
            }
        }
        return result.join(';');
    }
    /**
     * The recurrence rule. A string in [RFC-5545](https://tools.ietf.org/html/rfc5545#section-3.3.10) described format
     * ("RRULE" expression).
     * @property {String}
     */
    get rule() {
        return this.getRRule();
    }
    set rule(rule) {
        const
            me     = this,
            values = {
                frequency : null,
                interval  : null,
                count     : null,
                endDate   : null,
                days      : null,
                monthDays : null,
                months    : null,
                positions : null
            };
        me.beginBatch();
        if (rule) {
            const parts = rule.split(';');
            for (let i = 0, len = parts.length; i < len; i++) {
                const
                    part = parts[i].split('='),
                    value  = part[1];
                switch (part[0]) {
                    case 'FREQ':
                        values.frequency = value;
                        break;
                    case 'INTERVAL':
                        values.interval = value;
                        break;
                    case 'COUNT':
                        values.count = value;
                        values.until = null;
                        break;
                    case 'UNTIL':
                        if (value) {
                            values.endDate = DateHelper.parse(value, me.dateFormat);
                        }
                        else {
                            values.endDate = null;
                        }
                        values.count = null;
                        break;
                    case 'BYDAY':
                        values.days = value;
                        break;
                    case 'BYMONTHDAY':
                        values.monthDays = value;
                        break;
                    case 'BYMONTH':
                        values.months = value;
                        break;
                    case 'BYSETPOS':
                        values.positions = value;
                        break;
                }
            }
        }
        me.set(values);
        if (rule) {
            me.sanitize();
        }
        me.endBatch();
    }
    /**
     * Iterate occurrences for the owning timespan across the specified date range. This method can be called even
     * if the timespan is not yet a member of a store, however, the occurrences returned will not be cached across
     * subsequent calls to this method.
     * @param {Date} startDate The start date of the iteration.
     * @param {Date} endDate The end date of the iteration.
     * @param {Function} fn The function to call for each occurrence.
     * @param {Scheduler.model.TimeSpan} fn.occurrence The occurrence.
     * @param {Boolean} fn.first A flag which is `true` for the first occurrence of this recurrence.
     * @param {Number} fn.counter A counter of how many dates have been visited in this iteration.
     * @param {Date} fn.date The occurrence date.
     * @internal
     */
    forEachOccurrence(startDate, endDate, fn) {
        if (this.timeSpanDate) {
            this.recurrenceIterator.forEachDate({
                recurrence : this,
                startDate,
                endDate,
                fn(date, counter, first, timeSpan) {
                    return fn(timeSpan.buildOccurrence(date, first), first, counter, date);
                }
            });
        }
    }
    /**
     * Cleans up fields that do not makes sense for the current {@link #field-frequency} value.
     * @private
     */
    sanitize() {
        const
            me               = this,
            { timeSpanDate } = me,
            values           = {};
        me.isSanitizingSuspended = true;
        switch (me.frequency) {
            case 'DAILY' :
                values.positions    = null;
                values.days         = null;
                values.monthDays    = null;
                values.months       = null;
                break;
            case 'WEEKLY' : {
                values.positions = null;
                values.monthDays = null;
                values.months = null;
                const { days } = me;
                if (timeSpanDate && days?.length === 1 && days[0] === RecurrenceDayRuleEncoder.encodeDay(timeSpanDate.getDay())) {
                    values.days = null;
                }
                break;
            }
            case 'MONTHLY' : {
                if (me.monthDays?.length) {
                    values.positions = null;
                    values.days = null;
                }
                values.months = null;
                const { monthDays } = me;
                if (timeSpanDate && monthDays?.length === 1 && monthDays[0] === timeSpanDate.getDate()) {
                    values.monthDays = null;
                }
                break;
            }
            case 'YEARLY' : {
                const { months } = me;
                if (timeSpanDate && months?.length === 1 && months[0] === timeSpanDate.getMonth() + 1) {
                    values.months = null;
                }
                break;
            }
        }
        me.set(values);
        me.isSanitizingSuspended = false;
    }
    copy(...args) {
        const result = super.copy(...args);
        result.dateFormat = this.dateFormat;
        result.timeSpan   = this.timeSpan;
        return result;
    }
    afterChange(toSet, wasSet, silent) {
        const
            result       = super.afterChange(toSet, wasSet, silent),
            { timeSpan } = this;
        if (!this.isSanitizingSuspended) {
            // cleanup data to match the chosen frequency
            this.sanitize();
        }
        if (timeSpan) {
            timeSpan.sanitizeRecurrenceData(this);
            if (!this.isTimeSpanNotifyingSuspended) {
                // call the bound timeSpan onRecurrenceChanged hook with some context
                timeSpan.onRecurrenceChanged(this, ...arguments);
            }
        }
        return result;
    }
    setValue(fieldName, value) {
        // handle single integer provided to monthDays
        if (fieldName === 'monthDays' && Number.isInteger(value)) {
            value = [value];
        }
        return super.setValue(fieldName, value);
    }
    set(field, value, ...args) {
        const values = typeof field === 'object' ? field : { [field] : value };
        // reset "endDate" field if "count" is being set
        if (values.count) {
            values.endDate = null;
        }
        // reset "count" field if "endDate" is being set
        else if (values.endDate) {
            values.count = null;
        }
        return super.set(values, undefined, ...args);
    }
    get isTimeSpanNotifyingSuspended() {
        return Boolean(this._suspendedTimeSpanNotifying);
    }
    suspendTimeSpanNotifying() {
        this._suspendedTimeSpanNotifying++;
    }
    resumeTimeSpanNotifying() {
        if (this._suspendedTimeSpanNotifying) this._suspendedTimeSpanNotifying--;
    }
    reset() {
        this.allFields.forEach(({ name }) => this.set(name, null));
    }
    set laterJsSchedule(schedule) {
        // Unwrap later.parse result if provided.
        // We don't support composite schedules so take the 1st part only
        schedule = schedule?.schedules?.[0] || schedule;
        if (!schedule) {
            return;
        }
        this.reset();
        const {
            s, // seconds - 0-59
            m, // minutes - 0-59
            h, // hours - 0-23
            t, // time - seconds from midnight
            D, // day of month 1-based (0 means last day of month)
            d, // day of week 1-7
            // dc, // Nth day of week in a month (like 2nd Thursday)
            // dy, // day of year 1-based
            // wm, // week of month 1-based (0 means last week)
            // wy, // week of year 1-based (0 means last week)
            M//, // month 1-12
            // Y // year
        } = schedule;
        const data = { interval : 1 };
        if (s || m || h || t) {
            data.frequency = 'DAILY';
        }
        if (d) {
            data.frequency = 'WEEKLY';
            data.days = d.map(d => RecurrenceDayRuleEncoder.encodeDay(d - 1));
        }
        if (D) {
            data.frequency = 'MONTHLY';
            data.monthDays = D.map(d => d || -1);
        }
        if (M) {
            data.frequency = 'YEARLY';
            data.months = M.slice();
        }
        Object.assign(this, data);
    }
    get laterJsSchedule() {
        const { frequency, interval, days, monthDays, months, positions } = this;
        let result = interval > 1  ? `every ${interval} ${frequencyToLater[frequency]} ` : '';
        switch (this.frequency) {
            case 'YEARLY' :
                // `every 2 years on the 24 day of Dec`
                if (months?.length) {
                    result += `on the ${months.join(',')} month `;
                }
            // Disable alert on missing "break" since we intentionally fallthrough here
            // eslint-disable-next-line no-fallthrough
            case 'MONTHLY' :
                if (monthDays?.length) {
                    result += `on the ${monthDays.join(',')} day `;
                    // stop execution here to not append excessive days & positions rule
                    break;
                }
                // ! We don't break here to build the following rules too
            // Disable alert on missing "break" since we intentionally fallthrough here
            // eslint-disable-next-line no-fallthrough
            case 'WEEKLY' :
                if (days?.length) {
                    // on the last day instance
                    result += `on ${days.map(d => dayNameToLater[d]).join(',')} `;
                }
                if (positions?.length) {
                    // on the last day instance
                    result += `on the ${positions.join(',')} day instance `;
                }
        }
        return result.trim();
    }
}
RecurrenceModel._$name = 'RecurrenceModel';