import DomHelper from '../../../Core/helper/DomHelper.js';
import FunctionHelper from '../../../Core/helper/FunctionHelper.js';
/**
 * @module Scheduler/data/mixin/LazyLoadView
 */
/**
 * Mixin for Scheduler that handles lazy loading.
 * @mixin
 * @private
 */
export default Target => class LazyLoadView extends Target {
    static $name = 'LazyLoadView';
    construct(config) {
        const me              = this;
        me.$lazyLoadDetachers = new Map();
        me.$lazyLoadCount     = 0;
        super.construct(config);
        if (me.crudManager?.lazyLoad) {
            // If we are using crudManager, a resource-initiated load will have to be populated with a startDate and
            // an endDate
            me.$lazyLoadDetachers.set(me, FunctionHelper.before(me.resourceStore.lazyLoad, 'doLazyLoad', params => {
                const dateRange = me.eventStore.lazyLoad.calculateDateRange({
                    startDate    : me.startDate,
                    endDate      : me.endDate,
                    visibleStart : me.visibleDateRange.startDate,
                    visibleEnd   : me.visibleDateRange.endDate
                });
                Object.assign(params, dateRange);
            }));
            // These should not be passed when lazy loading
            me.passStartEndParameters = false;
        }
    }
    updateEventStore(store, oldStore) {
        super.updateEventStore?.(store, oldStore);
        this.lazyLoadAttachStore(store, oldStore);
        if (store.lazyLoad) {
            // These should not be passed when lazy loading
            this.passStartEndParameters = false;
        }
    }
    updateResourceTimeRangeStore(store, oldStore) {
        super.updateResourceTimeRangeStore?.(store, oldStore);
        this.lazyLoadAttachStore(store, oldStore);
    }
    updateAssignmentStore(store, oldStore) {
        super.updateAssignmentStore?.(store, oldStore);
        this.lazyLoadAttachStore(store, oldStore);
    }
    lazyLoadAttachStore(store, oldStore) {
        const
            me                     = this,
            { $lazyLoadDetachers } = me;
        if (oldStore) {
            $lazyLoadDetachers.get(oldStore)?.();
            $lazyLoadDetachers.delete(oldStore);
            if (oldStore.lazyLoad.beforeLoadCall) {
                oldStore.lazyLoad.beforeLoadCall = null;
            }
        }
        if (store?.lazyLoad) {
            $lazyLoadDetachers.get(store)?.(); // Just in case
            $lazyLoadDetachers.set(store, store.ion({
                lazyLoadStarted : 'storeLazyLoadStarted',
                lazyLoadEnded   : 'storeLazyLoadEnded',
                thisObj         : me
            }));
            if (store.lazyLoad.isDateStoreLazyLoadPlugin) {
                store.lazyLoad.beforeLoadCall = params => {
                    Object.assign(params, {
                        startDate      : me.startDate,
                        endDate        : me.endDate,
                        visibleStart   : me.visibleDateRange.startDate,
                        visibleEnd     : me.visibleDateRange.endDate,
                        resourceRecord : me.firstVisibleRow?.cellContext?.record
                    });
                };
            }
        }
    }
    set $lazyLoadCount(count) {
        if (this.$_lazyLoadCount === 0 && count > 0) {
            this.lazyLoadShowLoadingIndicator();
        }
        else if (this.$_lazyLoadCount > 0 && count === 0) {
            this.lazyLoadHideLoadingIndicator();
        }
        this.$_lazyLoadCount = count;
    }
    get $lazyLoadCount() {
        return this.$_lazyLoadCount;
    }
    storeLazyLoadStarted() {
        this.$lazyLoadCount += 1;
    }
    storeLazyLoadEnded() {
        this.$lazyLoadCount -= 1;
    }
    // Shows a loading indicator inside the main header row
    lazyLoadShowLoadingIndicator() {
        const me = this;
        if (!me.$lazyLoadingIndicatorElement) {
            me.$lazyLoadingIndicatorElement = DomHelper.createElement({
                parent        : me.timeAxisSubGrid.header.element,
                retainElement : true,
                className     : 'b-timeline-loading-indicator-wrap',
                children      : [{
                    className : 'b-timeline-loading-indicator'
                }]
            });
        }
        else {
            me.timeAxisSubGrid.header.element.appendChild(me.$lazyLoadingIndicatorElement);
        }
        me.readOnly = true;
    }
    lazyLoadHideLoadingIndicator() {
        this.$lazyLoadingIndicatorElement?.remove();
        this.readOnly = false;
        // This didn't work that well.
        // this.captureScrollTargetRow();
        // this.rowManager.ion({
        //     renderDone() {
        //         this.restoreScrollTargetRow();
        //     },
        //     once    : true,
        //     thisObj : this
        // });
    }
    doDestroy() {
        this.$lazyLoadDetachers?.forEach(detacher => detacher());
        super.doDestroy();
    }
};
