/**
 * @module SchedulerPro/widget/GanttTaskEditor
 */
import TaskEditorBase from './TaskEditorBase.js';
import './taskeditor/GeneralTab.js';
import './taskeditor/SuccessorsTab.js';
import './taskeditor/PredecessorsTab.js';
import './taskeditor/ResourcesTab.js';
import './taskeditor/AdvancedTab.js';
import './taskeditor/NotesTab.js';
import '../../Core/widget/TabPanel.js';
/**
 * A subclass of {@link SchedulerPro.widget.TaskEditorBase} for Gantt projects which SchedulerPro can handle as well.
 *
 * @extends SchedulerPro/widget/TaskEditorBase
 * @internalwidget
 */
export default class GanttTaskEditor extends TaskEditorBase {
    // Factoryable type name
    static get type() {
        return 'gantttaskeditor';
    }
    //region Config
    static get $name() {
        return 'GanttTaskEditor';
    }
    static configurable = {
        items : {
            tabs : {
                type        : 'tabpanel',
                defaultType : 'formtab',
                flex        : '1 0 100%',
                autoHeight  : true,
                layoutConfig : {
                    alignItems   : 'stretch',
                    alignContent : 'stretch'
                },
                items : {
                    generalTab : {
                        type   : 'generaltab',
                        weight : 100
                    },
                    predecessorsTab : {
                        type   : 'predecessorstab',
                        weight : 200
                    },
                    successorsTab : {
                        type   : 'successorstab',
                        weight : 300
                    },
                    resourcesTab : {
                        type   : 'resourcestab',
                        weight : 400
                    },
                    advancedTab : {
                        type   : 'advancedtab',
                        weight : 500
                    },
                    notesTab : {
                        type   : 'notestab',
                        weight : 600
                    }
                }
            }
        }
    };
    //endregion
}
// Register this widget type with its Factory
GanttTaskEditor.initClass();
GanttTaskEditor._$name = 'GanttTaskEditor';