import InstancePlugin from '../../Core/mixin/InstancePlugin.js';
import GridFeatureManager from '../../Grid/feature/GridFeatureManager.js';
import HeaderDragSelect from './mixin/HeaderDragSelect.js';
/**
 * @module Scheduler/feature/HeaderZoom
 */
/**
 * Enables users to click and drag to zoom to a date range in Scheduler's header time axis. Only supported in horizontal
 * mode.
 *
 * ```javascript
 * const scheduler = new Scheduler({
 *   features : {
 *     headerZoom : true
 *   }
 * });
 * ```
 *
 * {@inlineexample Scheduler/feature/HeaderZoom.js}
 *
 * <div class="note">Not compatible with the {@link Scheduler/feature/TimeSelection} feature.</div>
 *
 * This feature is **disabled** by default.
 * For info on enabling it, see {@link Grid.view.mixin.GridFeatures}.
 *
 * @extends Core/mixin/InstancePlugin
 * @classtype headerZoom
 * @feature
 */
export default class HeaderZoom extends InstancePlugin.mixin(HeaderDragSelect) {
    static $name = 'HeaderZoom';
    onTimeSpanDragSelected({ startDate, endDate }) {
        this.client.zoomToSpan({
            startDate,
            endDate
        });
    }
}
HeaderZoom._$name = 'HeaderZoom'; GridFeatureManager.registerFeature(HeaderZoom, false, 'Scheduler');
