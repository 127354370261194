import Rectangle from '../../../../Core/helper/util/Rectangle.js';
import DomHelper from '../../../../Core/helper/DomHelper.js';
import DomSync from '../../../../Core/helper/DomSync.js';
import { ScheduleRange } from '../Utils.js';
import AbstractTimeRanges from '../../AbstractTimeRanges.js';
export default base => class SchedulerExporterMixin extends base {
    cloneElement(element, target, clear) {
        super.cloneElement(element, target, clear);
        const clonedEl = this.element.querySelector('.b-schedulerbase');
        // Remove default animation classes
        clonedEl?.classList.remove(...['fade-in', 'slide-from-left', 'slide-from-top', 'zoom-in'].map(name => `b-initial-${name}`));
    }
    async prepareComponent(config) {
        const
            me              = this,
            { client }      = config,
            includeTimeline = client.timeAxisSubGrid.width > 0;
        switch (config.scheduleRange) {
            case ScheduleRange.completeview:
                config.rangeStart = client.startDate;
                config.rangeEnd   = client.endDate;
                break;
            case ScheduleRange.currentview: {
                const { startDate, endDate } = client.visibleDateRange;
                config.rangeStart = startDate;
                config.rangeEnd = endDate;
                break;
            }
        }
        await client.waitForAnimations();
        // Disable infinite scroll before export, so it doesn't change time span
        config.infiniteScroll = client.infiniteScroll;
        client.infiniteScroll = false;
        // Don't change timespan if time axis subgrid is not visible
        if (includeTimeline) {
            // https://github.com/bryntum/support/issues/8220
            // setTimeSpan should not be called on infinite scroll and visible schedule export
            if (config.scheduleRange !== ScheduleRange.currentview) {
                // set new timespan before calling parent to get proper scheduler header/content size
                client.setTimeSpan(config.rangeStart, config.rangeEnd);
            }
            if (config.scheduleRange === ScheduleRange.daterange) {
                // In case time axis is filtered or generated by custom fn we need to adjust passed ranges to actual dates
                config.rangeStart = client.startDate;
                config.rangeEnd = client.endDate;
            }
            // Access svgCanvas el to create dependency canvas early
            client.svgCanvas;
        }
        // Disable event animations during export
        me._oldEnableEventAnimations = client.enableEventAnimations;
        client.enableEventAnimations = false;
        await super.prepareComponent(config);
        const
            { exportMeta, element } = me,
            fgCanvasEl              = element.querySelector('.b-sch-foreground-canvas'),
            rtrCanvasEl             = element.querySelector('.b-resource-time-range-canvas'),
            timeAxisEl              = element.querySelector('.b-horizontaltimeaxis');
        // Canvas elements get their width from a CSS variable which does not work in puppeteer
        DomHelper.forEachSelector(element, '.b-sch-canvas', element => {
            let selector = '';
            for (const cls of element.classList.values()) {
                selector += `.${cls}`;
            }
            const originalElement = client.element.querySelector(selector);
            if (originalElement) {
                element.style.width = `${originalElement.clientWidth}px`;
            }
        });
        exportMeta.includeTimeline = includeTimeline;
        if (includeTimeline && config.scheduleRange !== ScheduleRange.completeview) {
            // If we are exporting sub-range of dates we need to change subgrid size accordingly
            exportMeta.totalWidth -= exportMeta.subGrids.normal.width;
            exportMeta.totalWidth += exportMeta.subGrids.normal.width = client.timeAxisViewModel.getDistanceBetweenDates(config.rangeStart, config.rangeEnd);
            const
                horizontalPages = Math.ceil(exportMeta.totalWidth / exportMeta.pageWidth),
                totalPages      = horizontalPages * exportMeta.verticalPages;
            exportMeta.horizontalPages = horizontalPages;
            exportMeta.totalPages = totalPages;
            // store left scroll to imitate normal grid/header scroll using margin
            exportMeta.subGrids.normal.scrollLeft = client.getCoordinateFromDate(config.rangeStart);
        }
        exportMeta.timeAxisHeaders = [];
        exportMeta.timeAxisPlaceholders = [];
        exportMeta.headersCollected = false;
        exportMeta.eventsBoxes = new Map();
        exportMeta.resourceTimeRangeBoxes = new Map();
        exportMeta.client = client;
        if (!includeTimeline) {
            return;
        }
        DomHelper.forEachSelector(timeAxisEl, '.b-sch-header-row', headerRow => {
            exportMeta.timeAxisPlaceholders.push(me.createPlaceholder(headerRow));
            exportMeta.timeAxisHeaders.push(new Map());
        });
        // Add placeholder for events, clear all event elements, but not the entire elements as it contains svg canvas
        exportMeta.subGrids.normal.eventsPlaceholder = me.createPlaceholder(fgCanvasEl, false);
        DomHelper.removeEachSelector(fgCanvasEl, '.b-sch-event-wrap');
        DomHelper.removeEachSelector(me.element, '.b-released');
        // Ditto for resourceTimeRanges
        if (rtrCanvasEl) {
            exportMeta.subGrids.normal.resourceTimeRangesPlaceholder = me.createPlaceholder(rtrCanvasEl, false);
            DomHelper.removeEachSelector(rtrCanvasEl, '.b-sch-resourcetimerange');
        }
        const
            columnLinesCanvas      = element.querySelector('.b-column-lines-canvas'),
            timeRangesHeaderCanvas = element.querySelector('.b-sch-timeaxiscolumn .b-timeranges-canvas'),
            timeRangesBodyCanvas   = element.querySelector('.b-timeaxissubgrid .b-timeranges-canvas');
        if (client.hasActiveFeature('columnLines') && columnLinesCanvas) {
            exportMeta.columnLinesPlaceholder = me.createPlaceholder(columnLinesCanvas);
            exportMeta.columnLines = '';
        }
        // There are several features that use timeranges canvas, we need to check them all
        if (timeRangesBodyCanvas && Object.values(client.features).some(f => f instanceof AbstractTimeRanges)) {
            exportMeta.timeRanges = {};
            // header is optional
            if (timeRangesHeaderCanvas) {
                exportMeta.timeRanges.header = '';
                exportMeta.timeRangesHeaderPlaceholder = me.createPlaceholder(timeRangesHeaderCanvas);
            }
            exportMeta.timeRanges.body = '';
            exportMeta.timeRangesBodyPlaceholder = me.createPlaceholder(timeRangesBodyCanvas);
        }
        if (client.hasActiveFeature('dependencies')) {
            client.features.dependencies.fillDrawingCache();
            const svgCanvasEl = element.querySelector(`[id="${client.svgCanvas.getAttribute('id')}"]`);
            // Same as above, clear only dependency lines, because there might be markers added by user
            if (svgCanvasEl) {
                exportMeta.dependencyCanvasEl = svgCanvasEl;
                exportMeta.dependenciesPlaceholder = me.createPlaceholder(svgCanvasEl, false, {
                    ns  : 'http://www.w3.org/2000/svg',
                    tag : 'path'
                });
                DomHelper.removeEachSelector(svgCanvasEl, '.b-sch-dependency');
            }
        }
    }
    async restoreComponent(config) {
        const { client } = config;
        client.infiniteScroll = config.infiniteScroll;
        client.enableEventAnimations = this._oldEnableEventAnimations;
        await super.restoreComponent(config);
    }
    async onRowsCollected(rows, config) {
        const me = this;
        await super.onRowsCollected(rows, config);
        // Only collect this data if timeline is visible
        if (me.exportMeta.includeTimeline) {
            const { pageRangeStart, pageRangeEnd } = me.getCurrentPageDateRange(config);
            // If first page does not include timeline we don't need to render anything for it
            if (pageRangeStart && pageRangeEnd) {
                me.renderHeaders(config, pageRangeStart, pageRangeEnd);
                me.renderLines(config, pageRangeStart, pageRangeEnd);
                me.renderRanges(config, pageRangeStart, pageRangeEnd);
                me.renderEvents(config, rows, pageRangeStart, pageRangeEnd);
            }
        }
    }
    getCurrentPageDateRange({ rangeStart, rangeEnd, client }) {
        const
            me = this,
            { exportMeta } = me,
            { horizontalPages, horizontalPosition, pageWidth, subGrids } = exportMeta;
        let pageRangeStart, pageRangeEnd;
        // when exporting to multiple pages we only need to scroll sub-range within visible time span
        if (horizontalPages > 1) {
            const
                pageStartX = horizontalPosition * pageWidth,
                pageEndX   = (horizontalPosition + 1) * pageWidth,
                // Assuming normal grid is right next to right side of the locked grid
                // There is also a default splitter
                normalGridX = subGrids.locked.width + subGrids.locked.splitterWidth;
            if (pageEndX <= normalGridX) {
                pageRangeEnd = pageRangeStart = null;
            }
            else {
                const { scrollLeft = 0 } = subGrids.normal;
                pageRangeStart = client.getDateFromCoordinate(Math.max(pageStartX - normalGridX + scrollLeft, 0));
                pageRangeEnd = client.getDateFromCoordinate(pageEndX - normalGridX + scrollLeft) || rangeEnd;
            }
        }
        else {
            pageRangeStart = rangeStart;
            pageRangeEnd   = rangeEnd;
        }
        return {
            pageRangeStart,
            pageRangeEnd
        };
    }
    prepareExportElement() {
        const
            { element, exportMeta }                = this,
            { id, headerId, footerId, scrollLeft } = exportMeta.subGrids.normal,
            el                                     = element.querySelector(`[id="${id}"]`);
        el.querySelectorAll('.b-sch-canvas').forEach(canvasEl => {
            canvasEl.style.height = '';
            // Simulate horizontal scroll
            if (scrollLeft) {
                canvasEl.style.marginLeft = `-${scrollLeft}px`;
            }
        });
        if (scrollLeft) {
            [headerId, footerId].forEach(id => {
                const el = element.querySelector(`[id="${id}"] .b-widget-scroller`);
                if (el) {
                    el.style.marginLeft = `-${scrollLeft}px`;
                }
            });
        }
        return super.prepareExportElement();
    }
    //#region Direct rendering
    renderHeaders(config, start, end) {
        const
            me               = this,
            { exportMeta }   = me,
            { client }       = config,
            timeAxisHeaders  = exportMeta.timeAxisHeaders,
            // Get the time axis view reference that we will use to build cells for specific time ranges
            { timeAxisView } = client.timeAxisColumn,
            domConfig        = timeAxisView.buildCells(start, end),
            targetElement    = document.createElement('div');
        DomSync.sync({
            targetElement,
            domConfig
        });
        DomHelper.forEachSelector(targetElement, '.b-sch-header-row', (headerRow, index) => {
            const headersMap = timeAxisHeaders[index];
            DomHelper.forEachSelector(headerRow, '.b-sch-header-timeaxis-cell', el => {
                if (!headersMap.has(el.dataset.tickIndex)) {
                    headersMap.set(el.dataset.tickIndex, el.outerHTML);
                }
            });
        });
    }
    renderEvents(config, rows, start, end) {
        const
            me         = this,
            { client } = config,
            normalRows = me.exportMeta.subGrids.normal.rows;
        rows.forEach((row, index) => {
            const
                rowConfig       = normalRows[index],
                eventsMap       = rowConfig[1],
                resource        = client.store.getAt(row.dataIndex),
                resourceLayout  = client.currentOrientation.getResourceLayout(resource),
                left            = client.getCoordinateFromDate(start),
                right           = client.getCoordinateFromDate(end),
                eventDOMConfigs = client.currentOrientation.getEventDOMConfigForCurrentView(
                    resourceLayout, row, client.rtl ? right : left, client.rtl ? left : right),
                targetElement   = document.createElement('div');
            eventDOMConfigs.forEach(domConfig => {
                const
                    { eventId }                                          = domConfig.dataset,
                    { insetInlineStart, insetBlockStart, width, height } = domConfig.style;
                DomSync.sync({
                    targetElement,
                    domConfig
                });
                // Include entityName to distinguish between events and resource time ranges
                eventsMap.set(eventId, [targetElement.outerHTML, new Rectangle(insetInlineStart, insetBlockStart, width, height), undefined, domConfig.elementData.entityName]);
            });
        });
    }
    renderLines(config, start, end) {
        const
            me              = this,
            { client }      = config,
            { exportMeta }  = me,
            {
                columnLinesPlaceholder
            } = exportMeta;
        if (columnLinesPlaceholder) {
            const
                domConfigs    = client.features.columnLines.getColumnLinesDOMConfig(start, end),
                targetElement = document.createElement('div');
            DomSync.sync({
                targetElement,
                domConfig : {
                    onlyChildren : true,
                    children     : domConfigs
                }
            });
            exportMeta.columnLines = targetElement.innerHTML;
        }
    }
    renderRanges(config, start, end) {
        const
            me             = this,
            { client }     = config,
            { exportMeta } = me,
            { timeRanges } = exportMeta;
        if (timeRanges) {
            // Clean-up time ranges before rendering new batch
            timeRanges.body = timeRanges.header = '';
            for (const feature of Object.values(client.features).filter(f => f instanceof AbstractTimeRanges)) {
                const
                    domConfigs    = feature.getDOMConfig(start, end),
                    targetElement = document.createElement('div');
                // domConfigs is an array of two elements - first includes time range configs for body, second - for head
                domConfigs.forEach((children, i) => {
                    DomSync.sync({
                        targetElement,
                        domConfig : {
                            children,
                            onlyChildren : true
                        }
                    });
                    // body configs
                    if (i === 0) {
                        timeRanges.body += targetElement.innerHTML;
                    }
                    // header configs
                    else {
                        timeRanges.header += targetElement.innerHTML;
                    }
                });
            }
        }
    }
    //#endregion
    buildPageHtml(config) {
        const
            me = this,
            {
                subGrids,
                timeAxisHeaders,
                timeAxisPlaceholders,
                columnLines,
                columnLinesPlaceholder,
                timeRanges,
                timeRangesHeaderPlaceholder,
                timeRangesBodyPlaceholder
            }  = me.exportMeta;
        // Now when rows are collected, we need to add them to exported grid
        let html = me.prepareExportElement();
        Object.values(subGrids).forEach(({ placeHolder, eventsPlaceholder, resourceTimeRangesPlaceholder, rows, mergedCellsHtml }) => {
            const
                placeHolderText                           = placeHolder.outerHTML,
                // Rows can be repositioned, in which case event related to that row should also be translated
                { resources, events, resourceTimeRanges } = me.positionRows(rows, config);
            let contentHtml =  resources.join('');
            if (mergedCellsHtml?.length) {
                contentHtml += `<div class="b-grid-merged-cells-container">${mergedCellsHtml.join('')}</div>`;
            }
            html = html.replace(placeHolderText, contentHtml);
            if (eventsPlaceholder) {
                html = html.replace(eventsPlaceholder.outerHTML, events.join(''));
            }
            if (resourceTimeRangesPlaceholder) {
                html = html.replace(resourceTimeRangesPlaceholder.outerHTML, resourceTimeRanges.join(''));
            }
        });
        timeAxisHeaders.forEach((headers, index) => {
            html = html.replace(timeAxisPlaceholders[index].outerHTML, Array.from(headers.values()).join(''));
        });
        if (columnLines) {
            html = html.replace(columnLinesPlaceholder.outerHTML, columnLines);
            me.exportMeta.columnLines = '';
        }
        if (timeRanges) {
            html = html.replace(timeRangesBodyPlaceholder.outerHTML, timeRanges.body);
            // time ranges header element is optional
            if (timeRangesHeaderPlaceholder) {
                html = html.replace(timeRangesHeaderPlaceholder.outerHTML, timeRanges.header);
            }
            me.exportMeta.timeRanges = {};
        }
        html = me.buildDependenciesHtml(html);
        return html;
    }
    getEventBox(event, dependency) {
        const
            me = this,
            {
                client,
                eventsBoxes,
                currentPageFirstRowIndex,
                exactGridHeight
            }  = me.exportMeta;
        let box = event && eventsBoxes.get(String(event.id));
        // In scheduler milestone box left edge is aligned with milestone start date. Later element is rotated and
        // shifted by CSS by 50% of its width. Dependency feature relies on actual element sizes, but pdf export
        // does not render actual elements. Therefore, we need to adjust the box.
        if (box && event.isMilestone) {
            box.translate(-box.width / 2, 0);
        }
        if (!box) {
            const bottomResourceIndex = dependency.fromEvent.assignments.reduce((result, { resource }) => {
                if (result === null) {
                    result = client.resourceStore.indexOf(resource);
                }
                else {
                    result = Math.max(result, client.resourceStore.indexOf(resource));
                }
                return result;
            }, null);
            if (bottomResourceIndex < currentPageFirstRowIndex) {
                box = new Rectangle(0, -client.rowHeight * 2, 10, client.rowHeight);
            }
            else {
                box = new Rectangle(0, exactGridHeight + client.rowHeight, 10, client.rowHeight);
            }
        }
        return box;
    }
    renderDependencies() {
        const
            me                = this,
            {
                client,
                eventsBoxes
            }                 = me.exportMeta,
            { dependencies }  = client,
            dependencyFeature = client.features.dependencies,
            targetElement     = DomHelper.createElement();
        let draw = false;
        dependencies.forEach(dependency => {
            if ((!eventsBoxes.has(String(dependency.fromEvent?.id)) &&
                !eventsBoxes.has(String(dependency.toEvent?.id))) ||
                !dependencyFeature.isDependencyVisible(dependency)) {
                return;
            }
            const
                fromBox = me.getEventBox(dependency.fromEvent, dependency),
                toBox   = me.getEventBox(dependency.toEvent, dependency);
            dependencyFeature.drawDependency(dependency, true, { from : fromBox?.clone(), to : toBox?.clone() });
            draw = true;
        });
        // Force dom sync
        if (draw) {
            dependencyFeature.domSync(targetElement, true);
        }
        return targetElement.innerHTML;
    }
    buildDependenciesHtml(html) {
        const { dependenciesPlaceholder, includeTimeline } = this.exportMeta;
        if (dependenciesPlaceholder && includeTimeline) {
            const placeholder = dependenciesPlaceholder.outerHTML;
            html = html.replace(placeholder, this.renderDependencies());
        }
        return html;
    }
    positionRows(rows) {
        const
            resources          = [],
            events             = [],
            resourceTimeRanges = [];
        rows.forEach(([html, eventsMap]) => {
            resources.push(html);
            for (const [key, [html, box, extras = [], entityName]] of eventsMap) {
                // Store event box to render dependencies later
                this.exportMeta.eventsBoxes.set(String(key), box);
                if (entityName === 'resourceTimeRange')  {
                    resourceTimeRanges.push(html);
                }
                else {
                    // extras are for things like baselines
                    events.push(html + extras.join(''));
                }
            }
        });
        return { resources, events, resourceTimeRanges };
    }
};
