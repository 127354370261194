import VerticalRendering from '../../../Scheduler/view/orientation/VerticalRendering.js';
import ProVerticalLayout from '../../eventlayout/ProVerticalLayout.js';
/**
 * @module SchedulerPro/view/orientation/ProVerticalRendering
 */
/**
 * Handles event rendering in Scheduler Pro horizontal mode. Populates render data with buffer duration.
 *
 * @internal
 */
export default class ProVerticalRendering extends VerticalRendering {
    static $name = 'ProVerticalRendering';
    construct(scheduler) {
        super.construct(scheduler);
        this.verticalLayout = new ProVerticalLayout({ scheduler });
    }
    /**
     * Populates render data with buffer data rendering.
     * @param {HorizontalRenderData} renderData
     * @returns {Boolean}
     * @private
     */
    fillInnerSpanVerticalPosition(renderData) {
        const
            me                  = this,
            { scheduler }       = me,
            { eventRecord }     = renderData,
            { isBatchUpdating } = eventRecord,
            startDate           = isBatchUpdating ? eventRecord.get('startDate') : eventRecord.startDate,
            endDate             = isBatchUpdating ? eventRecord.get('endDate') : eventRecord.endDate,
            top                 = scheduler.getCoordinateFromDate(startDate),
            innerStartMS        = startDate.getTime(),
            innerEndMS          = endDate.getTime(),
            innerDurationMS     = innerEndMS - innerStartMS;
        let bottom = scheduler.getCoordinateFromDate(endDate),
            height = bottom - top;
        // Below, estimate height
        if (bottom === -1) {
            height = Math.round(innerDurationMS * scheduler.timeAxisViewModel.getSingleUnitInPixels('millisecond'));
            bottom = top + height;
        }
        Object.assign(renderData, {
            innerStartMS,
            innerEndMS,
            innerDurationMS,
            bufferBeforeWidth : top - renderData.top,
            bufferAfterWidth  : renderData.top + renderData.height - top - height
        });
        return true;
    }
    getTimeSpanRenderData(timeSpan, rowRecord, includeOutside = false) {
        const data = super.getTimeSpanRenderData(timeSpan, rowRecord, includeOutside);
        if (data?.useEventBuffer) {
            if (!this.fillInnerSpanVerticalPosition(data)) {
                return null;
            }
        }
        return data;
    }
    renderResourceCustomLayout(syncConfigs) {
        const
            me                        = this,
            { scheduler }             = me,
            { resourceColumns }       = me.scheduler,
            { allResourceRecords }    = me,
            { first : firstResource } = me.resourceRange,
            firstResourceEnd          = firstResource === 0 ? 0 : allResourceRecords[firstResource].instanceMeta(scheduler).insetStart,
            viewportWidth             = scheduler.timeAxisSubGridElement.clientWidth;
        let
            resourceIndex    = firstResource,
            start            = 0,
            continueToRender = true;
        // When using custom layout, it needs to render all resources starting from `firstResource`,
        // until the sum of their `columnWidth` is equal to the viewPort width.
        while (continueToRender) {
            const resource = allResourceRecords[resourceIndex];
            syncConfigs.push.apply(syncConfigs, me.renderResource(resource));
            start += resource.instanceMeta(scheduler).columnWidth;
            if (start > (viewportWidth + allResourceRecords[firstResource].instanceMeta(scheduler).columnWidth) || resourceIndex === allResourceRecords.length - 1) {
                continueToRender = false;
            }
            else {
                resourceIndex++;
                // It's important to update the insetStart of next resource because
                // ProVerticalLayout.js will sum it to to the left property of the events to render.
                // It's less expensive then calling updateWidthCache() in this loop.
                allResourceRecords[resourceIndex].instanceMeta(scheduler).insetStart = start + firstResourceEnd;
            }
        }
        // When using custom layout, it needs to refresh resource column header because the width of each resource could be changed after the layout is applied.
        resourceColumns.visibleResources = { firstResource, lastResource : resourceIndex };
        resourceColumns.updateWidthCache();
        resourceColumns.refresh();
    }
}
ProVerticalRendering._$name = 'ProVerticalRendering';