import Exporter from './Exporter.js';
import { Orientation, PaperFormat, RowsRange } from '../Utils.js';
/**
 * @module Grid/feature/export/exporter/MultiPageVerticalExporter
 */
/**
 * A vertical multiple page exporter. Used by the {@link Grid.feature.export.PdfExport} feature to export to multiple
 * pages. Content will be scaled in a horizontal direction to fit the page.
 *
 * You do not need to use this class directly.
 *
 * ### Extending exporter
 *
 * ```javascript
 * class MyMultiPageVerticalExporter extends MultiPageVerticalExporter {
 *     // type is required for exporter
 *     static get type() {
 *         return 'mymultipageverticalexporter';
 *     }
 *
 *     get stylesheets() {
 *         const stylesheets = super.stylesheets;
 *
 *         stylesheets.forEach(styleNodeOrLinkTag => doSmth(styleNodeOrLinkTag))
 *
 *         return stylesheets;
 *     }
 * }
 *
 * const grid = new Grid({
 *     features : {
 *         pdfExport : {
 *             // this export feature is configured with only one exporter
 *             exporters : [MyMultiPageVerticalExporter]
 *         }
 *     }
 * });
 *
 * // run export with the new exporter
 * grid.features.pdfExport.export({ exporter : 'mymultipageverticalexporter' });
 * ```
 *
 * @classtype multipagevertical
 * @extends Grid/feature/export/exporter/Exporter
 */
export default class MultiPageVerticalExporter extends Exporter {
    static get $name() {
        return 'MultiPageVerticalExporter';
    }
    static get type() {
        return 'multipagevertical';
    }
    static get title() {
        // In case locale is missing exporter is still distinguishable
        return this.L('L{multipagevertical}');
    }
    static get exportingPageText() {
        return 'L{exportingPage}';
    }
    //region State management
    async stateNextPage() {
        const
            { exportMeta } = this,
            {
                totalRows,
                processedRows,
                totalPages
            }              = exportMeta;
        ++exportMeta.currentPage;
        ++exportMeta.verticalPosition;
        // With variable row heights it is possible that initial pages estimation is wrong. If we're out but there are
        // more rows to process - continue exporting
        if (exportMeta.currentPage === totalPages && processedRows.size !== totalRows) {
            ++exportMeta.totalPages;
            ++exportMeta.verticalPages;
        }
    }
    //endregion
    estimateTotalPages(config) {
        const
            me             = this,
            { exportMeta } = me,
            {
                client,
                headerTpl,
                footerTpl,
                alignRows,
                rowsRange,
                repeatHeader
            }              = config,
            {
                pageWidth,
                pageHeight,
                totalWidth
            }              = exportMeta,
            scale          = me.getScaleValue(pageWidth, totalWidth);
        // To estimate amount of pages correctly we need to know height of the header/footer on every page
        let
            // bodyHeight does not always report correct value, read it from the DOM element instead, we don't care
            // about forced reflow at this stage
            totalHeight   = client.headerHeight + client.footerHeight + client.scrollable.scrollHeight,
            // We will be scaling content horizontally, need to adjust content height accordingly
            contentHeight = pageHeight / scale,
            totalRows     = client.store.count,
            rowsHeight    = totalHeight,
            verticalPages;
        if (headerTpl) {
            contentHeight -= me.measureElement(headerTpl({
                totalWidth,
                totalPages  : -1,
                currentPage : -1
            }), 'b-export-header');
        }
        if (footerTpl) {
            contentHeight -= me.measureElement(footerTpl({
                totalWidth,
                totalPages  : -1,
                currentPage : -1
            }), 'b-export-footer');
        }
        // If we are repeating header on every page we have smaller contentHeight
        if (repeatHeader) {
            contentHeight -= client.headerHeight + client.footerHeight;
            totalHeight -= client.headerHeight + client.footerHeight;
        }
        if (rowsRange === RowsRange.visible) {
            const
                rowManager = client.rowManager,
                firstRow   = rowManager.firstVisibleRow,
                lastRow    = rowManager.lastVisibleRow;
            totalRows = me.getVisibleRowsCount(client);
            totalHeight = client.headerHeight + client.footerHeight + lastRow.bottom - firstRow.top;
            rowsHeight = lastRow.bottom - firstRow.top;
            exportMeta.lastRowIndex = firstRow.dataIndex;
            exportMeta.finishRowIndex = lastRow.dataIndex;
        }
        else {
            exportMeta.finishRowIndex = client.store.count - 1;
        }
        // alignRows config specifies if rows should be always fully visible. E.g. if row doesn't fit on the page, it goes
        // to the top of the next page
        if (alignRows && !repeatHeader && rowsRange !== RowsRange.visible) {
            // we need to estimate amount of vertical pages for case when we only put row on the page if it fits
            // first we need to know how many rows would fit one page, keeping in mind first page also contains header
            // This estimation is loose, because row height might differ much between pages
            const
                rowHeight       = client.rowManager.rowOffsetHeight,
                rowsOnFirstPage = Math.floor((contentHeight - client.headerHeight) / rowHeight),
                rowsPerPage     = Math.floor(contentHeight / rowHeight),
                remainingRows   = totalRows - rowsOnFirstPage;
            verticalPages = 1 + Math.ceil(remainingRows / rowsPerPage);
        }
        else {
            verticalPages = Math.ceil(rowsHeight / contentHeight);
        }
        Object.assign(exportMeta, {
            scale,
            contentHeight,
            totalRows,
            totalHeight,
            verticalPages,
            initialScroll   : 0,
            horizontalPages : 1,
            totalPages      : verticalPages
        });
    }
    async prepareComponent(config) {
        await super.prepareComponent(config);
        const
            me              = this,
            { exportMeta }  = me,
            paperFormat     = PaperFormat[config.paperFormat],
            isPortrait      = config.orientation === Orientation.portrait,
            paperWidth      = me.getPaperWidth(paperFormat, isPortrait),
            paperHeight     = me.getPaperHeight(paperFormat, isPortrait),
            pageWidth       = me.inchToPx(paperWidth),
            pageHeight      = me.inchToPx(paperHeight),
            horizontalPages = 1;
        Object.assign(exportMeta, {
            paperWidth,
            paperHeight,
            realPaperWidth     : me.getPaperWidth(paperFormat, isPortrait),
            realPaperHeight    : me.getPaperHeight(paperFormat, isPortrait),
            pageWidth,
            pageHeight,
            horizontalPages,
            currentPage        : 0,
            verticalPosition   : 0,
            horizontalPosition : 0,
            lastTop            : 0,
            lastRowIndex       : 0,
            processedRows      : new Set()
        });
        me.estimateTotalPages(config);
    }
    async renderRows(config) {
        const
            me                 = this,
            { exportMeta }     = me,
            {
                client,
                alignRows,
                repeatHeader
            }                  = config,
            {
                verticalPosition,
                totalRows,
                contentHeight,
                lastRowIndex,
                finishRowIndex,
                fakeRow
            }                  = exportMeta,
            rows               = [],
            // If we are repeating header we've already taken header height into account when setting content height
            clientHeaderHeight = repeatHeader ? 0 : client.headerHeight,
            { store }          = client,
            hasMergeCells      = client.hasActiveFeature('mergeCells'),
            onlyVisibleRows    = config.rowsRange === RowsRange.visible;
        let index           = lastRowIndex,
            remainingHeight = contentHeight,
            lastTop         = 0,
            // data index of the last collected row
            lastDataIndex, nextPageTop;
        // first exported page container header
        if (verticalPosition === 0) {
            remainingHeight -= clientHeaderHeight;
        }
        while (remainingHeight > 0) {
            fakeRow.render(index, store.getAt(index), true, false, true);
            if (alignRows && remainingHeight < fakeRow.offsetHeight) {
                break;
            }
            else {
                nextPageTop = lastTop;
                lastDataIndex = index;
                lastTop = fakeRow.translate(lastTop);
                remainingHeight -= fakeRow.offsetHeight;
                me.collectRow(fakeRow);
                // Push an object with data required to build merged cell
                rows.push({
                    top          : fakeRow.top,
                    bottom       : fakeRow.bottom,
                    offsetHeight : fakeRow.offsetHeight,
                    dataIndex    : fakeRow.dataIndex
                });
                // only mark row as processed if it fitted without overflow
                if (remainingHeight > 0) {
                    // We cannot use simple counter here because some rows appear on 2 pages. Need to track unique identifier
                    exportMeta.processedRows.add(index);
                }
                // Last row is processed, still need to fill the view
                if (index === finishRowIndex || (++index - lastRowIndex) === totalRows && onlyVisibleRows) {
                    remainingHeight = 0;
                }
            }
        }
        if (hasMergeCells) {
            me.renderMergedCells(config, lastRowIndex, index, rows);
        }
        exportMeta.currentPageFirstRowIndex = lastRowIndex;
        // lastDataIndex is index of the last exported record.
        exportMeta.lastRowIndex = lastDataIndex + (alignRows ? 1 : 0);
        exportMeta.lastTop = nextPageTop;
        // Calculate exact grid height according to the last exported row to constrain column lines to the last row
        exportMeta.exactGridHeight = fakeRow.bottom + client.footerContainer.offsetHeight + ((verticalPosition === 0 || repeatHeader) ? client.headerContainer.offsetHeight : 0);
        await me.onRowsCollected(rows, config);
    }
    async buildPage(config) {
        const
            me             = this,
            { exportMeta } = me,
            {
                headerTpl,
                footerTpl
            }              = config,
            {
                totalWidth,
                totalPages,
                currentPage,
                subGrids
            }              = exportMeta;
        // Rows are stored in shared state object, need to clean it before exporting next page
        Object.values(subGrids).forEach(subGrid => subGrid.rows = []);
        let header, footer;
        // Measure header and footer height
        if (headerTpl) {
            header = me.prepareHTML(headerTpl({
                totalWidth,
                totalPages,
                currentPage
            }));
        }
        if (footerTpl) {
            footer = me.prepareHTML(footerTpl({
                totalWidth,
                totalPages,
                currentPage
            }));
        }
        await me.renderRows(config);
        const html = me.buildPageHtml(config);
        return { html, header, footer };
    }
    async onRowsCollected() {
    }
    buildPageHtml() {
        const
            me           = this,
            { subGrids } = me.exportMeta;
        // Now when rows are collected, we need to add them to exported grid
        let html = me.prepareExportElement();
        Object.values(subGrids).forEach(({ placeHolder, rows, mergedCellsHtml }) => {
            const placeHolderText = placeHolder.outerHTML;
            let contentHtml = rows.reduce((result, row) => {
                result += row[0];
                return result;
            }, '');
            if (mergedCellsHtml?.length) {
                contentHtml += `<div class="b-grid-merged-cells-container">${mergedCellsHtml.join('')}</div>`;
            }
            html = html.replace(placeHolderText, contentHtml);
        });
        return html;
    }
}
// HACK: terser/obfuscator doesn't yet support async generators, when processing code it converts async generator to regular async
// function.
MultiPageVerticalExporter.prototype.pagesExtractor = async function * pagesExtractor(config) {
    const
        me      = this,
        {
            exportMeta,
            stylesheets
        }       = me,
        {
            totalWidth,
            paperWidth,
            paperHeight,
            realPaperWidth,
            realPaperHeight,
            scale
        }       = exportMeta,
        isPrint = config.useBrowserPrint;
    let
        { totalPages } = exportMeta,
        currentPage, style;
    while ((currentPage = exportMeta.currentPage) < totalPages) {
        me.trigger('exportStep', {
            text     : me.L(MultiPageVerticalExporter.exportingPageText, { currentPage, totalPages }),
            progress : Math.round(((currentPage + 1) / totalPages) * 90)
        });
        const { html, header, footer } = await me.buildPage(config);
        style = `
            ${
            isPrint
                ? `
                        .b-page-wrap {
                            width: ${realPaperWidth}in;
                            height: ${realPaperHeight}in;
                        }
                        .b-print:not(.b-firefox) .b-export-content {
                            zoom: ${scale};
                            height: 100%;
                        }
                        .b-print.b-firefox .b-export-content {
                            transform: scale(${scale});
                            transform-origin: top left;
                            height: ${100 / scale}%;
                            width: ${100 / scale}%;
                        }
                    `
                : `
                        .b-export .b-page-${currentPage}.b-export-content {
                            transform: scale(${scale});
                            transform-origin: top left;
                            height: ${100 / scale}%;
                            width: ${100 / scale}%;
                        }
                    `
        }
        `;
        if (config.repeatHeader) {
            style = `
                ${style}
                .b-page-${currentPage} #${config.client.id} {
                    height: ${exportMeta.exactGridHeight}px !important;
                    width: ${totalWidth}px !important;
                }
                .b-export-body {
                    height: 100%;
                    display: flex;
                }
                .b-export-viewport {
                    height: 100%;
                }
            `;
        }
        else {
            style = `
                ${style}
                .b-page-${currentPage} #${config.client.id} {
                    height: ${exportMeta.exactGridHeight}px !important;
                    width: ${totalWidth}px !important;
                }
                ${currentPage === 0 ? '' : `.b-page-${currentPage} header.b-grid-header-container {
                    display: none;
                }`}
                .b-export-body {
                    overflow: hidden;
                }
            `;
        }
        // TotalHeight might change in case of variable row heights
        // Move exported content in the visible frame
        const styles = [
            ...stylesheets,
            `<style>${style}</style>`
        ];
        await me.stateNextPage();
        ({ totalPages } = exportMeta);
        yield {
            html : me.pageTpl({
                html,
                header,
                footer,
                styles,
                paperWidth,
                paperHeight,
                realPaperWidth,
                realPaperHeight,
                currentPage,
                isPrint
            })
        };
    }
};
MultiPageVerticalExporter._$name = 'MultiPageVerticalExporter';