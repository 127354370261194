import ColumnStore from '../../Grid/data/ColumnStore.js';
import ActionColumn from '../../Grid/column/ActionColumn.js';
/**
 * @module Gantt/column/InfoColumn
 */
/**
 * A calculated column which contains various information about the task.
 *
 * Currently, the only information shown is a presence of the unresolved, "postponed" conflict, which can be created
 * by using the {@link Gantt.model.ProjectModel#field-allowPostponedConflicts} and {@link Gantt.model.ProjectModel#field-autoPostponedConflicts}
 * configuration options.
 *
 * For task with such conflict, a warning icon will be rendered, clicking on which, will initiate the resolution of conflict.
 *
 * @extends Grid/column/Column
 * @classtype info
 * @column
 */
export default class InfoColumn extends ActionColumn {
    static get $name() {
        return 'InfoColumn';
    }
    static get type() {
        return 'info';
    }
    static get isGanttColumn() {
        return true;
    }
    static get defaults() {
        return {
            field   : 'hasPostponedOwnConstraintConflict',
            align   : 'center',
            text    : 'Info',
            width   : 50,
            editor  : null,
            actions : [
                {
                    cls     : 'b-postponed-conflict b-icon b-icon-warning',
                    visible : ({ record }) => record.hasPostponedOwnConstraintConflict,
                    tooltip : () => `There is an unresolved conflict with this event. Click to resolve it.`,
                    onClick : ({ record }) => record.resolvePostponedConflict()
                }
            ]
        };
    }
    canFillValue = () => false;
}
ColumnStore.registerColumnType(InfoColumn);
InfoColumn._$name = 'InfoColumn';