import GridRowResize from '../../Grid/feature/RowResize.js';
import GridFeatureManager from '../../Grid/feature/GridFeatureManager.js';
/**
 * @module Scheduler/feature/RowResize
 */
/**
 * Enables user to change row height by dragging the bottom row border. After a resize operation, the {@link Grid/data/GridRowModel#field-rowHeight}
 * field of the record is updated (when {@link #config-applyToAllRows} is `false`) and the {@link Scheduler/model/ResourceModel#field-eventLayout} of the dragged resource
 * row will be switched to `pack` as a result (only applies when `stack` is used by default).
 * `barMargin` and `resourceMargin` will also be shrunk as you reduce the height of a row.
 *
 * Try hovering the bottom row border in the scheduler below and use drag-drop to change the row height.
 *
 * {@inlineexample Scheduler/feature/RowResize.js}
 *
 * This feature is **disabled** by default.
 * For info on enabling it, see {@link Grid/view/mixin/GridFeatures}.
 *
 * @extends Grid/feature/RowResize
 * @classtype rowResize
 * @feature
 * @demo Scheduler/rowheight
 * @typings Grid.feature.RowResize -> Grid.feature.GridRowResize
 */
export default class RowResize extends GridRowResize {
    static $name = 'RowResize';
    onResizeStart({ context }) {
        super.onResizeStart(...arguments);
        const
            { client } = this,
            { record } = context;
        if (client.isTimelineBase) {
            client.suspendAnimations();
            client.crudManager?.suspendAutoSync?.();
            if (client.features.eventDragCreate) {
                this._oldDragCreateDisabled = client.features.eventDragCreate.disabled;
                client.features.eventDragCreate.disabled = true;
            }
        }
        if (client.isSchedulerBase) {
            if (client.getEventLayout?.(record).type === 'stack') {
                context.oldEventLayout = record.eventLayout;
                record.eventLayout     = 'pack';
                record.rowHeight       = context.elementHeight;
            }
            context.originalBarMargin = client.getBarMargin(record);
            context.originalResourceMarginObject = client.getResourceMarginObject(record);
            context.originalRecordResourceMargin = record.resourceMargin;
        }
    }
    onResizing({ context }) {
        const { record, newHeight, elementHeight, originalBarMargin, originalResourceMarginObject } = context;
        super.onResizing(...arguments);
        if (!this.applyToAllRows && !this.client.fixedRowHeight && context.valid) {
            record.beginBatch();
            record.rowHeight = newHeight;
            // Reduce barMargin + resourceMargin when shrinking
            if (newHeight < elementHeight) {
                const percentage = newHeight / elementHeight;
                // Shrink barMargin + resourceMargin as well
                record.barMargin = originalBarMargin * percentage;
                if (originalResourceMarginObject.start === originalResourceMarginObject.end) {
                    record.resourceMargin = originalResourceMarginObject.start * percentage;
                }
            }
            record.endBatch();
        }
    }
    onCancel({ context }) {
        context.record.eventLayout = context.oldEventLayout;
        context.record.resourceMargin = context.originalRecordResourceMargin;
        context.record.barMargin = context.originalBarMargin;
        super.onCancel(...arguments);
    }
    cleanup() {
        const { client } = this;
        if (client.isTimelineBase) {
            client.resumeAnimations();
            client.crudManager?.resumeAutoSync?.();
            if (client.features.eventDragCreate) {
                client.features.eventDragCreate.disabled = this._oldDragCreateDisabled;
            }
        }
        super.cleanup();
    }
}
RowResize._$name = 'RowResize'; GridFeatureManager.registerFeature(RowResize, false);
