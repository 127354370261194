/**
 * @module Core/data/stm/RevisionException
 */
/**
 * Exception class that is thrown when a revision problem is detected.
 * @internal
 */
export default class RevisionException extends Error {
    constructor(...args) {
        super(...args);
        this.name = 'RevisionException';
    }
};
RevisionException._$name = 'RevisionException';