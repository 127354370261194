import DayButtons from '../../../widget/DayButtons.js';
/**
 * @module Scheduler/view/recurrence/field/RecurrenceDaysButtonGroup
 */
/**
 * A segmented button field allowing you to pick days for the "Weekly" mode in the
 * {@link Scheduler.view.recurrence.RecurrenceEditor recurrence dialog}.
 *
 * {@inlineexample Scheduler/view/DayButtons.js}
 *
 * @extends Scheduler/widget/DayButtons
 * @deprecated In favor of Core/widget/DayButtons
 */
export default class RecurrenceDaysButtonGroup extends DayButtons {
    static $name = 'RecurrenceDaysButtonGroup';
    // Factoryable type name
    static type = 'recurrencedaysbuttongroup';
};
// Register this widget type with its Factory
RecurrenceDaysButtonGroup.initClass();
RecurrenceDaysButtonGroup._$name = 'RecurrenceDaysButtonGroup';