import WalkHelper from '../../helper/WalkHelper.js';
const
    countChildren = parent => parent.children?.reduce((sum, child) => sum + (child.remoteChildCount ?? 0) + 1, 0) ?? 0,
    isFullyLoaded = parent => countChildren(parent) >= parent.remoteChildCount;
export default Target => class TreeStoreLazyLoadPlugin extends Target {
    static $name = 'TreeStoreLazyLoadPlugin';
    static get pluginConfig() {
        const config = {};
        for (const prop in super.pluginConfig) {
            config[prop] = [...super.pluginConfig[prop]];
        }
        config.override = config.override ?? [];
        config.override.push('loadChildren', 'getAt');
        // lazyGetAt has no practical functionality for these stores
        const lazyGetAt = config.assign?.indexOf('lazyGetAt');
        if (lazyGetAt >= 0) {
            config.assign.splice(lazyGetAt, 1);
        }
        return config;
    };
    static configurable = {
        chunkSize : 50
    };
    constructor() {
        super(...arguments);
        this.totalCount = 0; // Tells RowManager not to render placeholders
    }
    // Need to overwrite default load, because it expects a promise
    load() {
        const
            me           = this,
            { rootNode } = me.client,
            queue        = me.loadQueue[rootNode.id];
        // Initial load
        if (!queue) {
            return me.doLazyLoad({ parent : rootNode, startIndex : 0 });
        }
        // Load call after initial load, reload
        else if (queue === true) {
            me.clearLoaded();
            return me.load();
        }
        // Load call during initial load
        else {
            return queue;
        }
    }
    // Updates a parent's remoteChildCount by adding the specified amount
    // Also updates the parent's parents the whole way up to the rootNode
    changeRemoteChildCount(records, amount, affectedParents) {
        if (records?.length) {
            const parents = records.map(r => r.parent ?? this.client.getById(r.parentId ?? r.originalData?.parentId));
            while (parents.length) {
                const parent = parents.shift();
                if (parent) {
                    if (!parent.isRoot) {
                        parents.push(parent.parent);
                    }
                    parent.remoteChildCount = (parent.remoteChildCount ? parent.remoteChildCount + amount : parent.children.length) ?? 0;
                    affectedParents.add(parent);
                }
            }
        }
    }
    // When a record is added or removed
    internalOnCommit({ changes }) {
        super.internalOnCommit();
        const affectedParents = new Set();
        // Increase remoteChildCount for all changed record's parents (and their parents etc.)
        this.changeRemoteChildCount(changes.added, 1, affectedParents);
        this.changeRemoteChildCount(changes.removed, -1, affectedParents);
        // Reset the loadQueue for each affected parent
        affectedParents.forEach(parent => {
            this.loadQueue[parent.id] = isFullyLoaded(parent);
        });
    }
    // Implementing the load on demand function
    loadChildren(parent) {
        return this.doLazyLoad({ parent, startIndex : 0 });
    }
    // Overrides store default getAt
    getAt(index) {
        const
            me                    = this,
            { loadQueue, client } = me,
            record                = client.records[index];
        if (record) {
            const { parent }  = record;
            // Autoload children of requested expanded parents
            if (record.expanded && record.remoteChildCount > 0 && !record.children?.length && !loadQueue[record.id]) {
                me.doLazyLoad({ parent : record, startIndex : 0 });
            }
            // Autoload more children if lastChild is requested and parent is not fully loaded
            if (record === parent.lastChild && !loadQueue[parent.id]) {
                me.doLazyLoad({ startIndex : record.parentIndex + 1, parent });
            }
        }
        return record;
    }
    doLazyLoad({ parent, ...params }) {
        const
            me                    = this,
            { loadQueue, client } = me,
            { id }                = parent;
        parent.instanceMeta(client).isLoadingChildren = true;
        me.triggerLazyLoadStart(arguments[0]);
        // Save the promise in the queue
        return loadQueue[id] = client.requestData({
            ...params,
            parentId : parent.isRoot ? 'root' : id,
            count    : me.chunkSize
        }).then(response => {
            const {
                [me.totalCountProperty] : totalCount,
                [me.dataProperty]       : data
            } = response;
            if (parent.isRoot && totalCount >= 0) {
                // Set totalCount on the rootNode
                parent.remoteChildCount = totalCount;
            }
            me.addData(data, parent);
            me.totalCount = client.count;
            // Set parent queue entry to `true` if fully loaded, else false
            loadQueue[id] = isFullyLoaded(parent);
            if (!me.isLoading) {
                client.trigger?.('refresh', { action : 'lazyload' });
            }
            me.triggerLazyLoadEnd(arguments[0], data);
            parent.instanceMeta(client).isLoadingChildren = false;
        });
    }
    addData(data, parent) {
        const { client, loadQueue } = this;
        WalkHelper.preWalk({ children : data }, ({ children }) => children?.length ? children : null, record => {
            const { id, children } = record;
            // Setting children = true will make the parent lazyLoad its children when it is expanded
            if (id && record.remoteChildCount > 0) {
                if (record.expanded === false && !children?.length) {
                    record.children = true;
                }
                // If server provided children to the requested nodes, check if the nodes are fully loaded
                else if (children?.length && !loadQueue[id] && isFullyLoaded(record)) {
                    // If so, mark them as that in the queue
                    loadQueue[id] = true;
                }
                // Makes the record pass as a parent
                else if (!children) {
                    record.children = [];
                }
            }
        });
        if (!client.modelClass.fieldMap.remoteChildCount) {
            // Add a remoteChildCount field to the model class
            client.modelClass.addField('remoteChildCount');
        }
        parent.appendChild(data, true);
        // Added and should not contain anything as we block new loads when we have uncommitted changes
        // So it should be safe to clear all add changes
        client.added.clear();
    }
    // getCollapsedChildCount(toIndex) {
    //     return this.client.getRange(0, toIndex).filter(r => !r.isExpanded(this.client)).reduce((sum, cur) => sum += cur.childCount || 0, 0);
    // }
    //
    // translateIndex(index, reverse) {
    //     return index + this.getCollapsedChildCount(index) * (reverse ? -1 : 1);
    // }
    //
    // // When estimating a total count, we need to add the collapsed records
    // setEstimatedTotalCount(count) {
    //     this.totalCount = this.translateIndex(count);
    // }
    //
    // // When fetching from storage with a translated index, we need to translate it back first
    // getFromStorage(index) {
    //     return super.getFromStorage(this.translateIndex(index, true));
    // }
};
